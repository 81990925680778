import React from "react";
import "../../styles/Article.css";
import { Carousel } from "react-responsive-carousel";
// import article1 from "../../assets/article1.jpg";
import "react-responsive-carousel/lib/styles/carousel.min.css";

// Images

// Article Images of 2019

import April12019 from "../../assets/Articles/2019April1.jpg"
import April22019 from "../../assets/Articles/2019April2.JPG"
import April32019 from "../../assets/Articles/2019April3.JPG"
import April42019 from "../../assets/Articles/2019April4.jpg"
import April52019 from "../../assets/Articles/2019April5.JPG"

import August12019 from "../../assets/Articles/2019August1.jpg"
import August22019 from "../../assets/Articles/2019August2.JPG"
import August32019 from "../../assets/Articles/2019August3.JPG"
import August42019 from "../../assets/Articles/2019August4.jpg"

import Dec12019 from "../../assets/Articles/2019Dec1.jpeg"
import Dec22019 from "../../assets/Articles/2019Dec2.jpg"
import Dec32019 from "../../assets/Articles/2019Dec3.jpg"
import Dec42019 from "../../assets/Articles/2019Dec4.jpg"
import Dec52019 from "../../assets/Articles/2019Dec5.JPG"

import Feb12019 from "../../assets/Articles/2019Feb1.JPG"
import Feb22019 from "../../assets/Articles/2019Feb2.JPG"
import Feb32019 from "../../assets/Articles/2019Feb3.JPG"
import Feb42019 from "../../assets/Articles/2019Feb4.JPG"
import Feb52019 from "../../assets/Articles/2019Feb5.jpg"

import July12019 from "../../assets/Articles/2019July1.jpg"
import July22019 from "../../assets/Articles/2019July2.jpg"
import July32019 from "../../assets/Articles/2019July3.JPG"
import July42019 from "../../assets/Articles/2019July4.jpg"
import July52019 from "../../assets/Articles/2019July5.jpg"

import June12019 from "../../assets/Articles/2019June1.jpg"
import June22019 from "../../assets/Articles/2019June2.jpg"
import June32019 from "../../assets/Articles/2019June3.jpg"
import June42019 from "../../assets/Articles/2019June4.jpg"

import March12019 from '../../assets/Articles/2019March1.jpg'
import March22019 from '../../assets/Articles/2019March2.jpg'
import March32019 from '../../assets/Articles/2019March3.JPG'
import March42019 from '../../assets/Articles/2019March4.JPG'
import March52019 from '../../assets/Articles/2019March5.jpeg'

import May12019 from "../../assets/Articles/2019May1.JPG"
import May22019 from "../../assets/Articles/2019May2.JPG"
import May32019 from "../../assets/Articles/2019May3.JPG"
import May42019 from "../../assets/Articles/2019May4.jpg"

import Nov12019 from "../../assets/Articles/2019Nov1.jpg"
import Nov22019 from "../../assets/Articles/2019Nov2.JPG"
import Nov32019 from "../../assets/Articles/2019Nov3.jpg"

import Oct12019 from "../../assets/Articles/2019Oct1.jpg"
import Oct22019 from "../../assets/Articles/2019Oct2.jpg"
import Oct32019 from "../../assets/Articles/2019Oct3.jpg"

import Sept12019 from "../../assets/Articles/2019Sept1.jpg"
import Sept22019 from "../../assets/Articles/2019Sept2.JPG"
import Sept32019 from "../../assets/Articles/2019Sept3.jpg"
import Sept42019 from "../../assets/Articles/2019Sept4.jpg"

// Article Images of 2020

import April12020 from "../../assets/Articles/April1.jpg"
import April22020 from "../../assets/Articles/April2.jpg"

import Feb12020 from "../../assets/Articles/Feb1.JPG"
import Feb22020 from "../../assets/Articles/Feb2.JPG"

import Jan12020 from "../../assets/Articles/Jan1.jpg"
import Jan22020 from "../../assets/Articles/Jan2.jpg"
import Jan32020 from "../../assets/Articles/Jan3.JPG"

import July12020 from "../../assets/Articles/July1.jpg"
import July22020 from "../../assets/Articles/July2.jpg"
import July32020 from "../../assets/Articles/July3.jpg"
import July42020 from "../../assets/Articles/July4.jpg"

import June12020 from "../../assets/Articles/June1.jpg"
import June22020 from "../../assets/Articles/June2.jpg"
import June32020 from "../../assets/Articles/June3.jpg"
import June42020 from "../../assets/Articles/June4.jpg"

import March12020 from "../../assets/Articles/March1.JPG"
import March22020 from "../../assets/Articles/March2.JPG"
import March32020 from "../../assets/Articles/March3.jpg"

import May12020 from "../../assets/Articles/May1.jpg"
import May22020 from "../../assets/Articles/May2.jpg"

function Article() {
  return (
    <div className="article__div1">
      <div className="article__div2">
        <div className="article__div3">
          <div className="article__div4">
            <p>Our Articles of July 2020</p>
          </div>
          <div className="article__div5">
          <Carousel showArrows={true} className="article__img1">
            <div>
              <img src={July12020} alt="" />
            </div>
            <div>
              <img src={July22020} alt="" />
            </div>
            <div>
              <img src={July32020} alt="" />
            </div>
            <div>
              <img src={July42020} alt="" />
            </div>
          </Carousel>
          </div>
        </div>
        <div className="article__div3">
          <div className="article__div4">
            <p>Our Articles of June 2020</p>
          </div>
          <div className="article__div5">
          <Carousel showArrows={true} className="article__img1">
            <div>
              <img src={June12020} alt="" />
            </div>
            <div className="">
              <img src={June22020} alt="" />
            </div>
            <div className="">
              <img src={June32020} alt="" />
            </div>
            <div className="">
              <img src={June42020} alt="" />
            </div>
          </Carousel>
          </div>
        </div>
        <div className="article__div3">
          <div className="article__div4">
            <p>Our Articles of May 2020</p>
          </div>
          <div className="article__div5">
          <Carousel showArrows={true} className="article__img1">
            <div>
              <img src={May12020} alt="" />
            </div>
            <div className="">
              <img src={May22020} alt="" />
            </div>
          </Carousel>
          </div>
        </div>
        <div className="article__div3">
          <div className="article__div4">
            <p>Our Articles of April 2020</p>
          </div>
          <div className="article__div5">
          <Carousel showArrows={true} className="article__img1">
            <div>
              <img src={April12020} alt="" />
            </div>
            <div className="">
              <img src={April22020} alt="" />
            </div>
          </Carousel>
          </div>
        </div>
        <div className="article__div3">
          <div className="article__div4">
            <p>Our Articles of March 2020</p>
          </div>
          <div className="article__div5">
          <Carousel showArrows={true} className="article__img1">
            <div>
              <img src={March12020} alt="" />
            </div>
            <div className="">
              <img src={March22020} alt="" />
            </div>
            <div className="">
              <img src={March32020} alt="" />
            </div>
          </Carousel>
          </div>
        </div>
        <div className="article__div3">
          <div className="article__div4">
            <p>Our Articles of February 2020</p>
          </div>
          <div className="article__div5">
          <Carousel showArrows={true} className="article__img1">
            <div>
              <img src={Feb12020} alt="" />
            </div>
            <div className="">
              <img src={Feb22020} alt="" />
            </div>
          </Carousel>
          </div>
        </div>
        <div className="article__div3">
          <div className="article__div4">
            <p>Our Articles of January 2020</p>
          </div>
          <div className="article__div5">
          <Carousel showArrows={true} className="article__img1">
            <div>
              <img src={Jan12020} alt="" />
            </div>
            <div className="">
              <img src={Jan22020} alt="" />
            </div>
            <div className="">
              <img src={Jan32020} alt="" />
            </div>
          </Carousel>
          </div>
        </div>
        <div className="article__div3">
          <div className="article__div4">
            <p>Our Articles of December 2019</p>
          </div>
          <div className="article__div5">
          <Carousel showArrows={true} className="article__img1">
            <div>
              <img src={Dec12019} alt="" />
            </div>
            <div className="">
              <img src={Dec22019} alt="" />
            </div>
            <div className="">
              <img src={Dec32019} alt="" />
            </div>
            <div className="">
              <img src={Dec42019} alt="" />
            </div>
            <div className="">
              <img src={Dec52019} alt="" />
            </div>
          </Carousel>
          </div>
        </div>
        <div className="article__div3">
          <div className="article__div4">
            <p>Our Articles of November 2019</p>
          </div>
          <div className="article__div5">
          <Carousel showArrows={true} className="article__img1">
            <div>
              <img src={Nov12019} alt="" />
            </div>
            <div className="">
              <img src={Nov22019} alt="" />
            </div>
            <div className="">
              <img src={Nov32019} alt="" />
            </div>
          </Carousel>
          </div>
        </div>
        <div className="article__div3">
          <div className="article__div4">
            <p>Our Articles of October 2019</p>
          </div>
          <div className="article__div5">
          <Carousel showArrows={true} className="article__img1">
            <div>
              <img src={Oct12019} alt="" />
            </div>
            <div className="">
              <img src={Oct22019} alt="" />
            </div>
            <div className="">
              <img src={Oct32019} alt="" />
            </div>
          </Carousel>
          </div>
        </div>
        <div className="article__div3">
          <div className="article__div4">
            <p>Our Articles of September 2019</p>
          </div>
          <div className="article__div5">
          <Carousel showArrows={true} className="article__img1">
            <div>
              <img src={Sept12019} alt="" />
            </div>
            <div className="">
              <img src={Sept22019} alt="" />
            </div>
            <div className="">
              <img src={Sept32019} alt="" />
            </div>
            <div className="">
              <img src={Sept42019} alt="" />
            </div>
          </Carousel>
          </div>
        </div>
        <div className="article__div3">
          <div className="article__div4">
            <p>Our Articles of August 2019</p>
          </div>
          <div className="article__div5">
          <Carousel showArrows={true} className="article__img1">
            <div>
              <img src={August12019} alt="" />
            </div>
            <div className="">
              <img src={August22019} alt="" />
            </div>
            <div className="">
              <img src={August32019} alt="" />
            </div>
            <div className="">
              <img src={August42019} alt="" />
            </div>
          </Carousel>
          </div>
        </div>
        <div className="article__div3">
          <div className="article__div4">
            <p>Our Articles of July 2019</p>
          </div>
          <div className="article__div5">
          <Carousel showArrows={true} className="article__img1">
            <div>
              <img src={July12019} alt="" />
            </div>
            <div className="">
              <img src={July22019} alt="" />
            </div>
            <div className="">
              <img src={July32019} alt="" />
            </div>
            <div className="">
              <img src={July42019} alt="" />
            </div>
            <div className="">
              <img src={July52019} alt="" />
            </div>
          </Carousel>
          </div>
        </div>
        <div className="article__div3">
          <div className="article__div4">
            <p>Our Articles of June 2019</p>
          </div>
          <div className="article__div5">
          <Carousel showArrows={true} className="article__img1">
            <div>
              <img src={June12019} alt="" />
            </div>
            <div className="">
              <img src={June22019} alt="" />
            </div>
            <div className="">
              <img src={June32019} alt="" />
            </div>
            <div className="">
              <img src={June42019} alt="" />
            </div>
          </Carousel>
          </div>
        </div>
        <div className="article__div3">
          <div className="article__div4">
            <p>Our Articles of May 2019</p>
          </div>
          <div className="article__div5">
          <Carousel showArrows={true} className="article__img1">
            <div>
              <img src={May12019} alt="" />
            </div>
            <div className="">
              <img src={May22019} alt="" />
            </div>
            <div className="">
              <img src={May32019} alt="" />
            </div>
            <div className="">
              <img src={May42019} alt="" />
            </div>
          </Carousel>
          </div>
        </div>
        <div className="article__div3">
          <div className="article__div4">
            <p>Our Articles of April 2019</p>
          </div>
          <div className="article__div5">
          <Carousel showArrows={true} className="article__img1">
            <div>
              <img src={April12019} alt="" />
            </div>
            <div className="">
              <img src={April22019} alt="" />
            </div>
            <div className="">
              <img src={April32019} alt="" />
            </div>
            <div className="">
              <img src={April42019} alt="" />
            </div>
            <div className="">
              <img src={April52019} alt="" />
            </div>
          </Carousel>
          </div>
        </div>
        <div className="article__div3">
          <div className="article__div4">
            <p>Our Articles of March 2019</p>
          </div>
          <div className="article__div5">
          <Carousel showArrows={true} className="article__img1">
            <div>
              <img src={March12019} alt="" />
            </div>
            <div className="">
              <img src={March22019} alt="" />
            </div>
            <div className="">
              <img src={March32019} alt="" />
            </div>
            <div className="">
              <img src={March42019} alt="" />
            </div>
            <div className="">
              <img src={March52019} alt="" />
            </div>
          </Carousel>
          </div>
        </div>
        <div className="article__div3">
          <div className="article__div4">
            <p>Our Articles of February 2019</p>
          </div>
          <div className="article__div5">
          <Carousel showArrows={true} className="article__img1">
            <div>
              <img src={Feb12019} alt="" />
            </div>
            <div className="">
              <img src={Feb22019} alt="" />
            </div>
            <div className="">
              <img src={Feb32019} alt="" />
            </div>
            <div className="">
              <img src={Feb42019} alt="" />
            </div>
            <div className="">
              <img src={Feb52019} alt="" />
            </div>
          </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Article;
