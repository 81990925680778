import React from 'react'
import airbnb from "../../../assets/Blog/blog23.jpeg"

function TwentyThird() {
  return (
    <div className="first__div1">
        <div className="first__div2">
            <div className="first__div3">
                <img src={airbnb} className="first__img1" alt="" />
            </div>
            <div className="first__div4">
                <p><b>Real Estate scams to be cautious of</b></p>
            </div>
            <div className="first__div5">
                <p>While buying a property with our lifetime savings, the cost of the property involves large financial commitment over a long tenure. And you don’t buy Real Estate Property every day. Hence necessary caution has to be taken while entering into such transactions. Although the Indian real estate industry now has customer-friendly rules and regulations, it is important for a buyer to be watchful, so that you don't end up becoming the victim of a fraud. Below, we have outlined the primary real estate tricks that are on-going in India which a buyer needs to be cautious of.</p>
                <p>There are millions of people in India, who are dealing with legal issues today because the land they bought is disputed, the required sanctions were not taken and many other issues. There is no shame in asking about  legal documents. Do not hurry in doing the agreement. It is advised to hire a legal advisor and discuss all property-related formalities before signing an agreement with the seller. This will cost you a little, but safeguard your interests in the long run. </p>
                <p>While buying a property from developer, verifying the property's title deed is imperative if you want to ascertain whether the property is in the developer's name and also cross-check whether the right to sell lies with him. Also, it is better to check the original deed rather than relying on photocopies provided by the seller.</p>
                <p>A good way to filter a good project from a bad project is to choose a bank approved project, as banks conduct thorough due-diligence before becoming a finance partner in the project. This substantially decreases the chances of any fraudulent activity by the builder.</p>
                <p>Don’t get mesmerized by Sample flat or Fancy brochure as they  are created to exploit the human imagination and sell you dreams. What you actually get in reality will not be exactly like sample flats you see. Brochures are important. But sometimes it becomes challenging for buyers to take action as builders mention in the contract that layouts and images are for visual presentation only and are subject to change.</p>
                <p>Apart from the legalities of the project, you also need to inquire in detail about the surrounding area and what all development is coming up in future. Don’t get over obsessed with the “upcoming infrastructure”. Always ask regarding the basic amenities. Such as Is some flyover planned? From where will the water connection come? What about electricity connection? While you might not be able to verify a lot of things, but at least you can know about the basic amenities. A thing like water is really a cause of concern. Don’t get obsessed with the big things told to you. There will be lots of Promises made to you that can be real or fake. You will listen about the lots of development projects, the new wide proposed TP road or many much things, some might be true some not. It is always advisable to check first.</p>
                <p>Many Project promoters lure potential buyers into purchasing a property by citing the availability of last few flats. Many times this illusion of limited stock is to create fake hype and to push sales. This rush makes a potential buyer take irrational decisions and a lot of technicalities go unnoticed. These circumstances do not allow homebuyers to do in-depth research. However, this does not mean that you delay things beyond limit, thinking they will come back to you all the times. If there are other leads who are ready to buy the property, you will not be contacted beyond the point. So keep a balance.</p>
                <p>Then, there are guaranteed rental schemes under which the developer either pays you rent for a fixed period during construction or for a certain period after possession. Generally for properties which are outside city limits, the developer offers rental income after possession. But the fine print is that there is no guarantee.</p>
                <p>Hence, it is always recommended that you do an extensive verification of the builder. You could verify its past or current projects. Make sure you do a thorough background check on the builder’s credibility. Use digital mediums and customer testimonies to form a perspective. Do research on their completed projects and check details of an on-going project on the RERA website.</p>
                <p>Real estate is a complicated investment and a lot of factors decide if you are making a right investment or a wrong one. As its one of your life biggest financial decisions, make sure you do it in a subtle way. </p>
            </div>
        </div>
    </div>
  )
}

export default TwentyThird