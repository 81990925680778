import React from 'react'
import "../../../styles/First.css"
import airbnb from "../../../assets/Fake Listing.jpeg"

function Third() {
  return (
    <div className="first__div1">
        <div className="first__div2">
            <div className="first__div3">
                <img src={airbnb} className="first__img1" alt="" />
            </div>
            <div className="first__div4">
                <p><b>Fake listings on online real estate portals</b></p>
            </div>
            <div className="first__div5">
                <p>We live in a world with remarkable technological innovations. Gone are the days when you had to trek between several apartments and sites to shortlist your desired property. All this used to take weeks or months in the bygone days. Now, all the information is at your fingertips. Online real estate portals offer a plethora of options and services to choose from. Plus the Smart filters to customize your search based on your budget, location and your choice of amenities. 44% of all home buyers and 99% of Millennials go to the Internet first when finding properties for sale.</p>
                <p>These online real estate portals are also platforms of competition & marketing gimmicks. At first glance, the real estate brokerage industry, with its low entry barriers and many participants, appears competitive. Indeed, relative to the volume of home sales activity, the number of real estate agents has increased significantly over the past several decades. The competition among agents for prospective sellers and buyers is much more intense today than it was two decades ago. "Everyone is selling".  Yet, other features of the industry suggest it is not competitive, the most important is offered price.</p>
                <p>The success of real estate business depends on how well your online marketing funnel attracts new home buyers. There is an old saying that “buyers buy houses and sellers buy marketing” which is really true! The potential listing only wants two things, to sell fast for the most money possible. To do this, oftentimes brokers use fake listings.  Online real estate portals have a lot of fake listings posted. Fake listings are integral to how online real estate business works. Let me try to explain this to you.</p>
                <p>Fake Listing (Usually underpriced) will attract lots of buyers contact, assuming great/ cheaper deaI.  While contacting he will say “Oh! sorry, that property just got taken yesterday, but I have other good options” (Usually overpriced than shown). Putting a fake listings increases chances of broker to get in touch with a valuable customer looking out for a property. Once the broker or agent has got your contact number, they would most try to get on call with you and lure you with fancy proposals. In any such case the customer would want to most probably see the options available to him and most probably rent it or buy it. So eventually you end up looking at options which do not meet your existing requirement criteria. Brokers will be showing you options what they have and not what you want.</p>
                <p>These are the tricks to get clients. but now the question is, but why don’t 99Acres, Magic Bricks stop this? The answer is, these broker listings are huge amount of their revenue. It’s a simple business for them. Also putting a fake listing on any website increases the site visibility on search engines like Google, yahoo, etc. Portals get commission out of the listing posted on their website.</p>
                <p>There is another gimmick on online real estate portals is "verified listing" said to cut down the fake listings. Putting a verified fake listing helps to increase chances of renting or buying. Portals take commission for placing a verified listing on their websites. The charge for verified listing in portal is more than normal listings. Web-portals get advantage of staying among the top searched websites for buying or renting. This is not only in Indian online Real Estate; this is across many other countries. Fake listings are a necessary evil for the online Real Estate. </p>
                <p>This also increases buyers’ expectation for lower price offers from developers/owners. Which developers aren’t able to offer. And This increase the fear of being cheated in buyers, due to the same reasons good numbers of transactions in the markets get Held/cancelled. It is understandable that it is hard to put track on such activities. However developers should take some actions against such activities. They should track listing of their properties and take necessary actions against fake listings.</p>
                <p>So, when next time you see a great deal on a property rent/sale on online portals, don’t get too excites. Probably its just a bait</p>
                {/* <p>However as per experts, in the next 1 to 2 years all co-working spaces are going to grapple with the situation. In the long run, the co-working sector will be among the few industries that will see a V-shape recovery after the COVID-19 threat is over.</p> */}
            </div>
        </div>
    </div>
  )
}

export default Third