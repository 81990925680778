import React from "react";
import "../../styles/About.css";
import Marquee from "react-fast-marquee";
import pyramid from "../../assets/Pyramid.png"
import bgBlurBuild from "../../assets/pic-1.png"
import banner from "../../assets/aboutusbanner.jpg"

// Iamges
import about1 from "../../assets/about1.jpg"
import about2 from "../../assets/Aboutimage.png"
import about3 from "../../assets/about3.jpg"
import about4 from "../../assets/about4.jpg"
import about5 from "../../assets/about5.jpg"
import about6 from "../../assets/legacy of excellence.jpeg"

function About() {
  return (
    <div className="about__div1">
      <div className="about__div2">
        <div className="about__div3">
          <img
            src={banner}
            className="about__img1"
            alt=""
          />
          <div className="about__div4">
            <p>About Us</p>
            <p>
            Intrigrity and trust.
            </p>
          </div>
        </div>
        <div className="about__div5">
          <div className="about__div6">
            <div className="about__div06">
              {/* <img className="about__img2" src={about4} alt="" /> */}
              <img className="about__img2" src={about6} alt="" />
            </div>
            <div className="about__div7">
              <div className="about__div8">
                <p>
                  {/* <span>Ground-Up Expertise To </span>Top-Down Research. */}
                  {/* Ground-Up Expertise To Top-Down Research. */}
                  A Legacy Of Excellence
                </p>
              </div>
              <div className="about__div9">
                <p>
                {/* Our generational knowledge combined with our operating expertise helps you stay ahead in the complex demands of the real estate sector. We make sure that your property decisions are based on accuracy, competitive rates and decades of real estate expertise.  */}
                After four decades of spectacular growth, Parikh Real Estate & Parikh Infracon is today among the leading Realty Investment Advisory Firms in India. Starting from a humble beginning by Mr Hashmukh Parikh in 1981, the company has led the business blooming into almost all spheres. Our mission statement, "Building a brighter future," applies to all market trends and client demographics.
                </p>
              </div>
              {/* <div className="about__div10">
                <p>Get Started</p>
                <div className="about__div11"></div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="about__div12">
          <div className="about__div13">
            <div className="about__div14">
              <div className="about__div15">
                {/* <p>Our Awards And Accomplishments</p> */}
                <p>Ahmedabad’s Most Trusted Realtor</p>
              </div>
              <div className="about__div16">
                <p>
                {/* Parikh Real Estate & Parikh Infracon has acquired 1000+ acres of Land in Gujarat. Out of all of our accomplishments, we take great pride in the calibre of our work, the opportunities we provide for our employees personal growth, and the contributions we make to our society. With 175+ pieces published in The Economic Times, we are honored and grateful to get recognition for our achievements from reliable organizations.  */}
                The mission of Parikh Real Estate & Parikh Infracon is to strive for a better tomorrow and to enrich life continuously. Since the company's inception, our team has constantly worked to achieve benchmark quality, a customer-centric approach, robust engineering, in-house research, unwavering business ethics, timeless values, and transparency in all areas of business conduct. These have helped make it a leading real estate brand in Ahmedabad and India.
                </p>
              </div>
            </div>
            <div className="about__div20">
            {/* <img className="about__img3" src={about3} alt="" />  */}
            <img className="about__img3" src={about1} alt="" />
            </div>
          </div>
        </div>
        <div className="about__div21">
          <div className="about__div22">
            <div className="about__div23">
              <img
                src={pyramid}
                className="about__img5"
                alt=""
              />
            </div>
            <div className="about__div24">
              <img
                src={about5}
                className="about__img6"
                alt=""
              />
              <img
                src={about2}
                className="about__img7"
                alt=""
              />
              {/* <img
                src="https://victorstone.co.uk/wp-content/themes/victorstone/src/assets/images/light-emblem.svg"
                className="about__img8"
                alt=""
              /> */}
            </div>
            <div className="about__div25">
              <p>
              We aim to continually deliver client engagement, experience, and satisfaction because we believe that satisfied customers are the key to a successful business. Our mission statement, "Building a brighter future," applies to all topographies, market trends, and client demographics. We have a solid commitment to excellence, ethics, and teamwork.
              </p>
            </div>
          </div>
        </div>
        <div className="about__div012">
          <div className="about__div13">
            <div className="about__div014">
              <div className="about__div015">
                <p>Our Awards And Accomplishments</p>
              </div>
              <div className="about__div16">
                <p>
                Parikh Real Estate & Parikh Infracon has acquired 1000+ acres of Land in Gujarat. Out of all of our accomplishments, we take great pride in the calibre of our work, the opportunities we provide for our employees personal growth, and the contributions we make to our society. With 175+ pieces published in The Economic Times, we are honored and grateful to get recognition for our achievements from reliable organizations.
                </p>
              </div>
            </div>
            <div className="about__div20">
            <img className="about__img3" src={about3} alt="" />
            </div>
          </div>
        </div>
        <div className="about__div05">
          <div className="about__div6">
            <div className="about__div06">
            <img className="about__img2" src={about4} alt="" />
            </div>
            <div className="about__div07">
              <div className="about__div08">
                <p>Ground-Up Expertise To Top-Down Research.</p>
              </div>
              <div className="about__div9">
                <p>
                Our generational knowledge combined with our operating expertise helps you stay ahead in the complex demands of the real estate sector. We make sure that your property decisions are based on accuracy, competitive rates and decades of real estate expertise.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="about__div26">
          <div className="about__div27">
          <img
              src={bgBlurBuild}
              className="blurBg"
              alt=""
            />
          </div>
          <div className="about__div28">
            <img
              src="https://victorstone.co.uk/wp-content/themes/victorstone/src/assets/images/achievement-pattern.svg"
              className="about__img10"
              alt=""
            />
          </div>
          <div className="about__div29">
            <p>
            One of Gujarat's best advisory & transaction firm, we believe in converting business deals/businesses into relationships. The foundation of a partnership with Parikh Real Estate & Parikh Infracon is inscribed in the trust and transparency we extend.
            </p>
          </div>
        </div>
        <div className="home__div48">
            {/* <img
              src="https://643113.smushcdn.com/2786300/wp-content/themes/victorstone/src/assets/images/operations-bg.png?lossy=0&strip=0&webp=1"
              className="home__img9"
              alt=""
            /> */}
            <div className="about__div49">
              <div className="home__div50">
                <p>Featured in</p>
                <div className="home__div51">
                  <Marquee speed={80} gradient={false}>
                    <p> Times of India |</p>
                    <p> Confederation of Indian Industry |</p>
                    <p> broker ki pathshala |</p>
                    <p> VTV News |</p>
                    <p> Divya Bhaskar |</p>
                    <p> Saurashtra Uday News |</p>
                    <p> Corporate Connections |</p>
                    <p> bakstage |</p>
                  </Marquee>
                </div>
              </div>
            </div>
          </div>
        <div className="about__div30">
          {/* <div className="about__div31">
            <div className="about__div32">
              <div className="about__div33">
                <img
                  src="https://643113.smushcdn.com/2786300/wp-content/uploads/2022/06/company-meet-the-team-2.jpg?lossy=0&strip=0&webp=1"
                  className="about__img11"
                  alt=""
                />
                <div className="about__div34">
                  <p>Meet the team</p>
                  <p>
                    Get to know the great minds behind our real estate agency.
                    Including the executive, sales, client relations and
                    administration teams.
                  </p>
                  <div className="about__div35">
                    <p>Discover</p>
                    <svg
                      className="about__svg1"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="16"
                        cy="16"
                        r="15"
                        transform="rotate(-90 16 16)"
                        stroke="white"
                        stroke-opacity="0.3"
                        stroke-width="2"
                      ></circle>
                      <path
                        d="M12 15.8333L20 15.8333M20 15.8333L16 11.6667M20 15.8333L16 20"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="about__div33">
                <img
                  src="https://643113.smushcdn.com/2786300/wp-content/uploads/2022/06/company-careers-1.jpg?lossy=0&strip=0&webp=1"
                  className="about__img11"
                  alt=""
                />
                <div className="about__div34">
                  <p>Carrers</p>
                  <p>
                    Start or progress your property career with us. Join our
                    team of driven and hard-working professionals who’ve helped
                    us become the award-winning real estate agency, we are
                    today.
                  </p>
                  <div className="about__div35">
                    <p>Discover</p>
                    <svg
                      className="about__svg1"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="16"
                        cy="16"
                        r="15"
                        transform="rotate(-90 16 16)"
                        stroke="white"
                        stroke-opacity="0.3"
                        stroke-width="2"
                      ></circle>
                      <path
                        d="M12 15.8333L20 15.8333M20 15.8333L16 11.6667M20 15.8333L16 20"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="about__div33">
                <img
                  src="https://643113.smushcdn.com/2786300/wp-content/uploads/2022/06/company-awards.jpg?lossy=0&strip=0&webp=1"
                  className="about__img11"
                  alt=""
                />
                <div className="about__div34">
                  <p>Awards</p>
                  <p>
                    Victorstone has been recognised by some of the most
                    respected awards schemes in the industry. Take a look at
                    some of them here.
                  </p>
                  <div className="about__div35">
                    <p>Discover</p>
                    <svg
                      className="about__svg1"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="16"
                        cy="16"
                        r="15"
                        transform="rotate(-90 16 16)"
                        stroke="white"
                        stroke-opacity="0.3"
                        stroke-width="2"
                      ></circle>
                      <path
                        d="M12 15.8333L20 15.8333M20 15.8333L16 11.6667M20 15.8333L16 20"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div className="about__div033" style={{display: "none"}}>
              <img
                src="https://643113.smushcdn.com/2786300/wp-content/uploads/2022/06/company-awards.jpg?lossy=0&strip=0&webp=1"
                className="about__img11"
                alt=""
              />
              <div className="about__div034">
                <p>Charity & Corporate and Social Responsibility</p>
                <p>
                  At Victorstone, we believe that effective interventions can
                  break the cycle for the world’s neediest people – and we
                  firmly believe in the importance and benefits of being
                  charitable.
                </p>
                <div className="about__div035">
                  <p>Discover</p>
                  <svg
                    className="about__svg1"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="16"
                      cy="16"
                      r="15"
                      transform="rotate(-90 16 16)"
                      stroke="white"
                      stroke-opacity="0.3"
                      stroke-width="2"
                    ></circle>
                    <path
                      d="M12 15.8333L20 15.8333M20 15.8333L16 11.6667M20 15.8333L16 20"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div className="about__div40" style={{display: "none"}}>
        <div className="home__div34">
          <div className="home__div035">
            <div className="home__div36">
              <img
                src="https://643113.smushcdn.com/2786300/wp-content/themes/victorstone/src/assets/images/help-dark-bg.png?lossy=0&strip=0&webp=1"
                className="home__img07"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="home__div37">
          <div className="home__div38">
            <div className="home__div39">
              <div className="home__div40">
                <div className="home__div41">
                  <p>Property services</p>
                  <p>Rent property</p>
                  <p>Buy property</p>
                  <p>Sell property</p>
                  <div className="home__div42"></div>
                </div>
                <div className="home__div41">
                  <p>Commercial services</p>
                  <p>Commercial services</p>
                  <p>Tax Planing</p>
                  <p>Property furnising</p>
                  <div className="home__div42"></div>
                </div>
              </div>
              <div className="home__div40">
                <div className="home__div41">
                  <p>Landlords</p>
                  <p>Tax Planing</p>
                  <p>Property furnising</p>
                  <p>Fees</p>
                  <div className="home__div42"></div>
                </div>
                <div className="home__div41">
                  <p>Company</p>
                  <p>About</p>
                  <p>Meet the team</p>
                  <p>Carrers</p>
                  <div className="home__div42"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="home__div43">
            <div className="home__div44">
              <div className="home__div45">
                <p>Need help with something else?</p>
                <div className="home__div46">
                  <p>Help Centre</p>
                  <svg
                    className="home__svg3"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="16"
                      cy="16"
                      r="15"
                      transform="rotate(-90 16 16)"
                      stroke="white"
                      stroke-opacity="0.3"
                      stroke-width="2"
                    ></circle>
                    <path
                      d="M12 15.8333L20 15.8333M20 15.8333L16 11.6667M20 15.8333L16 20"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
            <img
              src="https://643113.smushcdn.com/2786300/wp-content/uploads/2022/06/services-section-image.jpg?lossy=0&strip=0&webp=1"
              className="home__img8"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
