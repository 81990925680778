import React from 'react'
import airbnb from "../../../assets/Blog/blog25.jpeg"

function TwentyFifth() {
  return (
    <div className="first__div1">
        <div className="first__div2">
            <div className="first__div3">
                <img src={airbnb} className="first__img1" alt="" />
            </div>
            <div className="first__div4">
                <p><b>Safety measures during pandemic</b></p>
            </div>
            <div className="first__div5">
                <p>Considering the suggestion from developers, providing relief to real estate sector the government has allowed resumption of construction activity in non-Covid hotspots  from April 20 while following strict social distancing and safety measures.</p>
                <p>The move is expected to offer some relief to stalled construction activities in areas that have so far not witnessed major outbreak of Coronavirus. This will also help in engaging and holding the migrant workers back at the project sites and avoid any strain further on human-economic crisis caused by the Covid19. Through the revised guidelines the government has permitted “continuation of works in construction projects within the limits of municipal corporations and municipalities, where workers are available on site, and no workers are required to be brought in from outside.”</p>
                <p>Construction sites operating during the COVID-19 pandemic need to ensure they are protecting their workforce and minimising the risk of spread of infection. The health and safety requirements of any construction activity must also not be compromised at this time. below are the Site Operating Procedures can be followed by developers/contractors on site.</p>
                <ul>
                    <li>Make a list of all contractors and their labour with Name/Address/Phone/Aadhar card details.</li>
                    <li>Give staggered reporting timings to all contractors to avoid gathering at site entry gate. Ask all labour to stand 6 feet apart in line while making entry.</li>
                    <li>Make entry of each day in a register of entry / exit of worker and make column of temperature checked.</li>
                    <li>Check temperature with thermometer and make sure everyone is wearing masks. Temperature has to be less than 37.4 degrees celcius. </li>
                    <li>Ask workers if they have any health issues like cough/cold/fever/breathing issues.  Report immediately in case if anyone is sick.</li>
                    <li>Ask workers to wash their hands with Soap during each break.</li>
                    <li>Ensure hygiene. </li>
                    <li>Maintain social distancing at site - All workers to work at 6' distance.</li>
                    <li>Avoid Lunch Gatherings, ask all to go for lunch in staggered manner (as per entry).</li>
                    <li>Same to be followed for all members available on site. </li>
                    <li>Make sure you create confidence in workers to motivate them and not scare them.</li>
                    <li>People who are not considered essential for business shall not be permitted to enter and stay inside the site/office.</li>
                    <li>Also avoid meetings by other staff members, meet via digital platforms.</li>
                </ul>
                <p>For now due to the country wide lockdown the physically property buying & selling activities are on hold. Though, many real estate transactions are moving forward via use of digital platform.  Many sellers are still listing their homes, and buyers are still buying, too. There are people who need to buy or sell home for specific reasons. Also with low home loan rates are making it a good time to take advantage of this low-interest environment. The lockdown in India is supposed to end on May 3, it can end or extend in all/ few parts of country that we will see. But the safety measures are needs to be followed even after the lockdown.</p>
                <p>Buyers can request for videos of the Property. Options like virtual tours and digital walk-throughs are becoming more prevalent. Doing a virtual tour reduces the number of in-person visits, you can only visit in which you are really interested. In Case of re-sale properties ask if your real estate agents can offer this service. But, before finalizing It’s still best to schedule a private inspection and make sure to adhere to the government’s rules on social distancing. </p>
                <p>Here are some precautions recommend during property visits:</p>
                <p>For Buyers</p>
                <ul>
                    <li>Only tour the property if you feel healthy & Maintain an appropriate distance. </li>
                    <li>Be considerate of the seller’s home and wash or sanitize your hands before entry, touching as little as necessary. While many sellers will likely provide it, bring your own hand sanitizer and use it before and after you tour the home. You might also consider wearing disposable gloves for further safety.</li>
                    <li>confirm with the seller  that they have not recently been sick or in contact with someone suspected of having COVID-19.</li>
                    <li>Sellers often ask you to take off your shoes when you tour their home or wear protective booties that have been provided. Consider bringing your own booties and throwing them away when you’ve finished touring.</li>
                    <li>Be mindful of how much you touch things in the home and minimize contact with doors and hand railings.</li>
                    <li>Reduce the amount of time spent with other people in the same room. This “social distancing” practice can curb person-to-person spread.</li>
                </ul>
                <p>For Sellers</p>
                <ul>
                    <li>Offer booties at the door. Ask all visitors to cover their shoes with booties to avoid contaminating the property. </li>
                    <li>All internal doors must be open & Leave all lights on in the house for the entire showing. To Avoid Visitors from touching the door knobs, light switches and possibly contaminating them. </li>
                    <li>Set up cleaning stations. Have alcohol-based hand sanitizer available throughout the house, and keep soap, paper towels, and cleaning wipes on the countertop for everyone's use. </li>
                    <li>Clean common surfaces between each Visitor. Wipe down the doorknobs, cabinet handles, countertops, faucet handles, and other areas the visitors touched, do so during all visits & end of the visit.</li>
                </ul>
                <p>Taking these measures in consideration could help both you and the community at large stay healthier in the long run.</p>
            </div>
        </div>
    </div>
  )
}

export default TwentyFifth