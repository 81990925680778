import React from 'react'
import airbnb from "../../../assets/Blog/blog11.jpeg"

function Eleventh() {
  return (
    <div className="first__div1">
        <div className="first__div2">
            <div className="first__div3">
                <img src={airbnb} className="first__img1" alt="" />
            </div>
            <div className="first__div4">
                <p><b>Co-working & Co-living Market Pre & Post Lockdown</b></p>
            </div>
            <div className="first__div5">
                <p>The past few years has seen a rise in the use of shared office spaces, with providers such as WeWork experiencing exponential growth.  The co-working industry grew substantially along with the protracted bull market, and it’s well documented that co-working was a primary driver of new office absorption across large cities, especially over the last 3-5 years.</p>
                <p>Coworking players take office space on lease from real estate developers and other landlords and then subleases that to corporates, freelancers and startups. Gujarat co-working market was around 3-3.5 Lac sqft in2019.</p>
                <p>Whereas, Co-working operators in India leased 10.8 million sq.ft. office space during 2019. Coworking grew on average 26% per year from 2010-2018, and growth in 2019 was estimated to be 35% year over year before WeWork’s failed IPO.</p>
                <p>The global coworking spaces market is expected to decline from $9.27 billion in 2019 and to $8.24 billion in 2020 at a compound annual growth rate (CAGR) of -12.9%. The decline is mainly due to economic slowdown across countries owing to the COVID-19 outbreak and the measures to contain it. </p>
                <p>Coworking market has been badly impacted because of lockdown to control coronavirus disease. For instance, in March 2020, many companies working from home owing to COVID-19 pandemic, operators of coworking spaces have seen an almost 50% decline in footfalls. companies/employees getting into the habit of 'work from home' concept, office space is being considered as an additional overhead cost which not many are willing to invest, atleast for coming two-three quarters.</p>
                <p>Coworking players and other landlords invested huge to setup coworking spaces. management of vacant spaces in Current situation is putting financial presser on them.  with operators getting requests for rental waivers as well as cancellation of lease agreements from their clients, especially start-ups, freelancers and small enterprises. Many organizations that use these co-working firms to house their staff have started asking for flexible packages, limited to a few hours of usage, as opposed to monthly and annual plans. Some operators may be forced to renegotiate their leases or seek outside investment or debt to sustain their businesses, while others may be forced to close entirely.</p>
                <p>Even as clients renegotiate rents, a few serve notices and demand for new office space falls, large firms such as Awfis Space Solutions, WeWork, CoWrks and Smartworks are not only incorporating changes to floor space designs but also on-boarding some clients using short term contracts to deal with business losses. Earlier, a typical leasing tenure was between 2.5 and three years of the lock-in period. </p>
                <p>Like all businesses, coworking operators are using this time to think strategically to plan for the post COVID-19 environment. Operations will need to be modified, and space plans will need to be revisited for maintain distance in seating.</p>
                <p>However as per experts, in the next 1 to 2 years all co-working spaces are going to grapple with the situation. In the long run, the co-working sector will be among the few industries that will see a V-shape recovery after the COVID-19 threat is over.</p>
                <p>Same way, coliving market is currently under fall. Coliving  were earlier an ideal setup for several students and millenials. But As we continue to battle the COVID-19 pandemic and adapt to the enforcement of social distancing and stay-at-home mandates. the number of residents drastically dropped with the break of the COVID-19 pandemic, leaving many properties nearly empty. </p>
                <p>Health becoming prime necessity, it is expected that coliving spaces, PGs & hostels will see a drop for next 6-12 months. Now more People will choose to rent out private accommodations instead of sharing. Therefore It is expected to see Increase in affordable housing rentals. </p>
            </div>
        </div>
    </div>
  )
}

export default Eleventh