import React from 'react'
import airbnb from "../../../assets/Blog/blog10.jpeg"

function Tenth() {
  return (
    <div className="first__div1">
        <div className="first__div2">
            <div className="first__div3">
                <img src={airbnb} className="first__img1" alt="" />
            </div>
            <div className="first__div4">
                <p><b>Post covid relief measures, Housing made more affordable.</b></p>
            </div>
            <div className="first__div5">
                <p>Prime Minister Narendra Modi's announcement of a Rs 20 lakh crore stimulus package was the "need of the hour" as it will pave the way for post pandemic recovery, unleash the next wave of economic growth and to build a self-reliant india. The new package, which is equivalent to around 10% of India’s GDP, including the previously released package back in March, and central bank liquidity measures.</p>
                <p>Industry bodies said the Make In India flagship will emerge as a key catalyst for attracting new investment, driven by much needed bold reforms in the areas of land, labour and liquidity. the Prime Minister spoke about the areas of land, labour, liquidity and simplification of laws which are the key challenges of the economy. Reforms in these four areas will truly unleash the next wave of economic growth within this crisis situation.</p>
                <p>At Rs 20 lakh crore, the overall response will go a long way toward stabilising the Indian economy and positioning it for post-pandemic recovery. It will also support industry's efforts to put tens of millions of Indians back to work safely and minimize the spread of the novel coronavirus.</p>
                <p>Here are some key highlights from the package announcement that will help revive real estate sectors.</p>
                <p><strong>Relief on TDS</strong></p>
                <p>To increase liquidity in the hands of the common man and battle covid-induced financial distress, the government announced various relief measures on May 13, 2020. One was the tax deduction at source (TDS) for non-salaried specified payments made to residents and rates of tax collection at source to be reduced by 25% for 2020-21. This will release liquidity of Rs 50,000 crore. The reduction in TDS rates will be applicable to payment of contract, professional fees, interest, rent, dividend, commission, brokerage, etc.</p>
                <p>This means Rate of TDS on Property purchased and sold by resident individuals has been reduced from 1% to 0.75% for transactions taken place between 14/05/2020 to 31/03/2021. 
                The reduction will benefit existing homebuyers as it will leave additional money in their hands
                </p>
                <p><strong>Special liquidity scheme </strong></p>
                <p>The government has intended to resurrect the economy by reviving sentiment. The Rs.30,000 crore infusion in NBFC’s, HFC’s, and micro-finance companies with the help of debt papers and additional 20% emergency credit line to businesses will impact the real estate sector and bring in much-required liquidity for construction and project completion. This will benefit the real estate sector significantly, given that NBFCs and HFCs are major lenders to it. This would de-stress developers and ensure on-time completion of projects.</p>
                <p><strong>Extension of deadline for completion of real estate projects</strong></p>
                <p>By Inclusion of COVID in the definition of ‘Force Majeure’ or Act of God. Providing a significant relief to real estate developers, the government has extended the timeline for project completion and registration by six months, is a boost for the already stressed real estate sector. This will not only provide more time to complete the project without any liability for delay in completion of the project but will also prevent them from facing the legal cases on account of delay limited to this 6 months’ extension window.</p>
                <p>Relaxation in project timelines under RERA Act will bring in sigh of relief to the developers and safeguard the interest of homebuyers with the revised new timelines for their dream home deliverables. This ensures homebuyers trust in the project and grants breather to the developer’s fraternity for coping up with backlogged work due to natural disaster delays.</p>
                <p><strong>Affordable Housing</strong></p>
                <p>The extension of this CLSS scheme coupled with attractive mortgage rates will improve consumer sentiments and boost demand for affordable and mid segment housing. In the last couple of years, the subsidy of up to Rs 2.67 lakh under CLSS has been one of the biggest catalysts for home buyers, particularly for affordable housing in tier II & III cities."  With property prices remaining stable, the extension will prompt more people particularly to buy homes.</p>
                <p>The extension of CLSS up to March 2021 and expected investment of Rs.70, 000 crores is an action-oriented reform for the revival of the affordable housing segment. This will ensure job creation and give a stimulus to demand affordable housing. Allied industries related to the construction sector will benefit from this as it will give a boost to the supply chain.</p>
                <p>Through CLSS total of 3.3 lakh families have already been benefited from the scheme so far. And now this move will benefit 2.5 lakh more middle-income families in FY 20 alone. </p>
                <p>Finance Minister also announced the launch of the Affordable Rental Housing Scheme for migrant laborers and urban poor. the government will launch a scheme to convert government-funded housing in the cities into the Affordable Rental Housing Complexes (ARHC) under the PPP model. This will open new business opportunities for builders, according to property developers and consultants. Along with create demand for construction material and will provide jobs.</p>
                <p>This measure comes as a major relief for businesses to maintain a stable workforce and gives boost the sectors that have been adversely affected by the pandemic. we are hopeful that country will slowly and steadily move to the path of recovery.</p>
            </div>
        </div>
    </div>
  )
}

export default Tenth