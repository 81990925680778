import React from "react";
import { Link } from "react-router-dom";

import pyramid from "../../assets/Pyramid.png"
import serviceEnding from "../../assets/serviceEnding.jpg"
import helpCenter from "../../assets/homepage help center.jpg"

import banner from "../../assets/CommercialBanner.jpeg"

function Sell() {
  return (
    <div className="sell__div1">
      <div className="sell__div2">
        <div className="about__div3">
          <img
            src={banner}
            className="buy__img1"
            alt=""
          />
          <div className="buy__div04">
            <p>Industrial Services</p>
            <p>
            Helping Clients make Informed Decisions In The Evolving Industrial Sector.
            </p>
            {/* <div className="buy__div3">
              <p>Instant valuation</p>
              <div className="buy__div4"></div>
            </div> */}
          </div>
        </div>
        <div className="res__div3">
          <div className="res__div4">
            <div className="res__div5">
              <div className="res__div6">
                <p>Industrial and Warehouse Services</p>
                <p>
                Our real estate advisory expertise has played a pivotal role in helping several clients stay ahead in Gujarat's diverse and fast-evolving Industrial sector.
                </p>
              </div>
              <div className="res__div7">
                <img
                  src={pyramid}
                  className="res__img1"
                  id="commercial_pyramid"
                  alt=""
                />
              </div>
            </div>
            <div className="sub_services">
              <div className="sub_service_div">
              <div className="serviceBox">
                <img src="https://images.pexels.com/photos/636342/pexels-photo-636342.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
                <h1>Land Acquisition & Conversion</h1>
                <p>Land acquisition & conversion is a complex process. With 1000+ acres of land acquired  in Gujarat, we have extensive expertise in land acquisition services. Our knowledge of local laws, rules, and regulations, combined with access to the appropriate government agencies, assists our clients in obtaining timely approvals.
                </p>
              </div>
              <div className="serviceBox">
                <img src="https://images.unsplash.com/photo-1583699998579-5872a2117151?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80" alt="" />
                <h1>63AA/ 65KH </h1>
                <p>Permission and licenses are a continuous cycle in industrial real estate. 63AA approval is required when a non-farmer person intends to purchase land for the establishment of an industry in agricultural land. Likewise, 65KH approval is required to use certain lands for bonafide industrial purposes. 
We are equipped with the required knowledge and access to the appropriate POCs to obtain these approvals for you.

                </p>
              </div>
              <div className="serviceBox">
                <img src="https://images.unsplash.com/photo-1572797796609-96ac0e3d7b9c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80" alt="" />
                <h1>Built–To–Suit Solution</h1>
                <p>BTS is the best option for organizations planning to establish up manufacturing facilities. Using a well-designed structure, we customize the industrial space to your needs before renting it from the developer.
                </p>
              </div>
              </div>
              <div className="sub_service_div">
              <div className="serviceBox">
                <img src="https://images.unsplash.com/photo-1588600878108-578307a3cc9d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1176&q=80" alt="" />
                <h1>Feasibility Studies</h1>
                <p>We can help assess the viability of an industrial project before the formal planning process. We have extensive experience and profound exposure to various industry sectors like Shipping, Logistics, Healthcare, Energy, and more. It helps us find a strategic location for your needs, keeping connectivity, accessibility, and budget in mind.
                </p>
              </div>
              <div className="serviceBox">
                <img src="https://images.unsplash.com/photo-1590844173468-50ea17dfed6d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=876&q=80" alt="" />
                <h1>Buy v/s Lease Solutions</h1>
                <p>At Parikh Real Estate, we consistently help businesses with their demands for industrial real estate. We can assist with leasing or buying an industrial building, manufacturing unit, warehouse, and more. 
                </p>
              </div>
              <div className="serviceBox">
                <img src="https://images.unsplash.com/photo-1542361345-89e58247f2d5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80" alt="" />
                <h1>Consent to Establish</h1>
                <p>Consent has to be obtained before establishing any industry, plant, or facility. Our team strives to help you obtain all the primary clearances before starting your venture. From making an application to submitting the fees to getting the NOCs, we cover the end-to-end process for you.

                </p>
              </div>
              </div>
            </div>
          </div>
        </div>
        <div className="res__div9">
          <div className="about__div12">
            <div className="about__div13">
              <div className="about__div14">
                <div className="res__div015">
                  <p>
                  Offering Pioneer Research <span>Insights In The Industrial Sector</span>
                  </p>
                </div>
                <div className="about__div16">
                  <p>
                  With a comprehensive suite of services for investors, developers, and occupiers, Parikh Real Estate stands as the most dependable industrial real estate advisory platform. Our compelling insights and advisory help you make informed decisions about development and investments in Gujarat's diverse and fast-evolving Industrial sector. 
                  </p>
                </div>
                <div className="about__div17">
                  <p>
                  We provide experience in manufacturing, assembly, research and development, distribution and warehouse facilities, land assignments, and logistics for supply chains.
                  </p>
                </div>
                {/* <div className="about__div18">
                  <p>Learn More</p>
                  <div className="about__div19"></div>
                </div> */}
              </div>
              <div className="about__div20">
                <img src={serviceEnding} alt="" className="serviceImg"/>
                {/* <video
                  className="about__img3"
                  playsinline=""
                  autoplay=""
                  loop=""
                  muted=""
                  src=""
                  poster="https://victorstone.co.uk/wp-content/uploads/2022/09/residential-page-prime-property-finding-service-image.jpg"
                ></video> */}
              </div>
            </div>
          </div>
        </div>
        <div className="res__div10">
          <div className="about__div40" style={{marginTop: "5vw"}}>
          <div className="home__div34">
          <div className="home__div35" id="ser__div35">
            <div className="home__div36">
              <img
                src="https://victorstone.co.uk/wp-content/themes/victorstone/src/assets/images/featured-pattern.svg"
                className="home__img7"
                id="sesrvice_Img"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="home__div37">
          <div className="home__div38">
          <div className="home__div39">
              <div className="home__div40">
                <div className="home__div41">
                  <Link
                    to="/Our-Services/commercial"
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    <p>Commercial Services</p>
                  </Link>
                  <p>Feasibility Studies</p>
                  <p>Market Dynamics</p>
                  <p>Premises Acquisitions & Disposal</p>
                  <p>Buy v/s Lease Solutions</p>
                  <p>Leasing</p>
                  <p>BTS Solutions</p>
                  <div className="home__div42"></div>
                </div>
                <div className="home__div41">
                  <Link
                      to="/Our-Services/residential"
                      style={{ textDecoration: "none", color: "#fff" }}
                    >
                    <p>Residential Services</p>
                  </Link>
                  <p>Property Marketing</p>
                  <p>Exclusive Project Marketing</p>
                  <p>Location Selection</p>
                  <p>Budget Costing Advice</p>
                  <p>Competition Benchmarking</p>
                  <div className="home__div42"></div>
                </div>
              </div>
              <div className="home__div40">
                <div className="home__div41">
                <Link
                      to="/Our-Services/key-industrial"
                      style={{ textDecoration: "none", color: "#fff" }}
                    >
                  <p>Industrial Services</p>
                  </Link>
                  <p>Land Acquisition & Conversion</p>
                  <p>63 AA/ 65KH </p>
                  <p>Built – To – Suit (B.T.S) Solution</p>
                  <p>Feasibility Studies</p>
                  <p>Buy v/s Lease Solutions</p>
                  <p>Consent to Establish </p>
                  <div className="home__div42"></div>
                </div>
                <div className="home__div41">
                <Link
                      to="/Our-Services/capital-market"
                      style={{ textDecoration: "none", color: "#fff" }}
                    >
                  <p>Capital Markets</p>
                  </Link>
                  <p>Investment Strategy</p>
                  <p>Private Equity</p>
                  <p>Fundraising For Developers</p>
                  <p>Developer Land</p>
                  <p>Joint Venture</p>
                  <p>Joint Development</p>
                  <div className="home__div42"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="home__div43">
            <div className="home__div44">
              <div className="home__div45">
                <p>Need help with something else?</p>
                <Link
                to="/contact"
                style={{ textDecoration: "none", color: "#fff" }}
              >
                <div className="home__div46">
                  <p>Contact Us</p>
                  <svg
                    className="home__svg3"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="16"
                      cy="16"
                      r="15"
                      transform="rotate(-90 16 16)"
                      stroke="white"
                      stroke-opacity="0.3"
                      stroke-width="2"
                    ></circle>
                    <path
                      d="M12 15.8333L20 15.8333M20 15.8333L16 11.6667M20 15.8333L16 20"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>
                </Link>
              </div>
            </div>
            <img
              src={helpCenter}
              className="home__img8"
              alt=""
            />
          </div>
        </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sell;
