import React from "react";
import "../../styles/Buy.css";

import { Link } from "react-router-dom";

import pyramid from "../../assets/Pyramid.png"
import serviceEnding from "../../assets/serviceEnding.jpg"
import helpCenter from "../../assets/homepage help center.jpg"

function Buy() {
  return (
    <div className="buy__div1">
      <div className="buy__div2">
        <div className="about__div3">
          <img
            src="https://images.unsplash.com/photo-1629652320041-c2c555e68101?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1332&q=80"
            className="buy__img1"
            alt=""
          />
          <div className="buy__div04">
            <p>Commercial Services</p>
            <p>
            Delivering Insights And Strategies To Achieve Your Business Goals
            </p>
            {/* <div className="buy__div3">
              <p>Search Property</p>
              <div className="buy__div4"></div>
            </div> */}
          </div>
        </div>
        <div className="res__div3">
          <div className="res__div4">
            <div className="res__div5">
              <div className="res__div6">
                <p>Commercial Property Consultancy</p>
                <p>
                Our commercial advisory team collaborates with you to comprehend your company's strategic real estate objectives.
                </p>
              </div>
              <div className="res__div7">
                <img
                  src={pyramid}
                  className="res__img1"
                  id="commercial_pyramid"
                  alt=""
                />
              </div>
            </div>
            {/* <div className="res__div8">
              <div className="res__div030">
                <div className="about__div31">
                  <div className="about__div32">
                    <div>
                      <div className="about__div33">
                        <img
                          src={service1}
                          className="about__img11"
                          alt=""
                        />
                        <div className="about__div34">
                          <p>Commercial Service</p>
                          <p>
                          Our commercial advisory team collaborates with you to comprehend your company's strategic real estate objectives. Once improvement opportunities have been found and evaluated, they offer guidance and develop action plans to help you achieve your short- and long-term goals.
                          </p>
                          <a
                  style={{ textDecoration: "none", color: "#fff" }}
                  href="/Our-Services/commercial"
                >
                          <div className="about__div35">
                            <p>Discover</p>
                            <svg
                              className="about__svg1"
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="16"
                                cy="16"
                                r="15"
                                transform="rotate(-90 16 16)"
                                stroke="white"
                                stroke-opacity="0.3"
                                stroke-width="2"
                              ></circle>
                              <path
                                d="M12 15.8333L20 15.8333M20 15.8333L16 11.6667M20 15.8333L16 20"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </svg>
                          </div>
                          </a>
                        </div>
                      </div>
                      <div className="about__div33" style={{marginTop: "5vw"}}>
                        <img
                          src={service2}
                          className="about__img11"
                          alt=""
                        />
                        <div className="about__div34">
                          <p>Residential Service</p>
                          <p>
                          Selling, Buying, and Renting a residential property is easy and hassle-free with Parikh Real Estate & Parikh Real Estate. We provide our clients with solutions to their transactional obstacles by leveraging our market data and real estate competence, ensuring execution and improving return profiles. 
                          </p>
                          <a
                  style={{ textDecoration: "none", color: "#fff" }}
                  href="/Our-Services/residential"
                >
                          <div className="about__div35">
                            <p>Discover</p>
                            <svg
                              className="about__svg1"
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="16"
                                cy="16"
                                r="15"
                                transform="rotate(-90 16 16)"
                                stroke="white"
                                stroke-opacity="0.3"
                                stroke-width="2"
                              ></circle>
                              <path
                                d="M12 15.8333L20 15.8333M20 15.8333L16 11.6667M20 15.8333L16 20"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </svg>
                          </div>
                          </a>
                        </div>
                      </div>
                      </div>
                    <div>
                      <div className="about__div33">
                        <img
                          src={service3}
                          className="about__img11"
                          alt=""
                        />
                        <div className="about__div34">
                          <p>Industrial Service</p>
                          <p>
                          Our real estate advisory expertise has played a pivotal role in helping several clients stay ahead in Gujarat's diverse and fast-evolving Industrial sector. Our advanced research ensures that all market variables are maximized to offer our clients success opportunities.
                          </p>
                          <a
                            style={{ textDecoration: "none", color: "#fff" }}
                            href="/Our-Services/key-industrial"
                          >
                          <div className="about__div35">
                            <p>Discover</p>
                            <svg
                              className="about__svg1"
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="16"
                                cy="16"
                                r="15"
                                transform="rotate(-90 16 16)"
                                stroke="white"
                                stroke-opacity="0.3"
                                stroke-width="2"
                              ></circle>
                              <path
                                d="M12 15.8333L20 15.8333M20 15.8333L16 11.6667M20 15.8333L16 20"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </svg>
                          </div>
                          </a>
                        </div>
                      </div>
                      <div className="about__div33" style={{marginTop: "5vw"}}>
                        <img
                          src={service4}
                          className="about__img11"
                          alt=""
                        />
                        <div className="about__div34">
                          <p>Capital Market</p>
                          <p>
                          With our experts working in multiple property sectors, they help you recognize the finest investment opportunities. Our 40+ years of experience, financing expertise, renowned property insight, and top-notch research capabilities enable us to provide our clients with a world of opportunity.  
                          </p>
                          <a
                            style={{ textDecoration: "none", color: "#fff" }}
                            href="/Our-Services/capital-market"
                          >
                            <div className="about__div35">
                              <p>Discover</p>
                              <svg
                                className="about__svg1"
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="16"
                                  cy="16"
                                  r="15"
                                  transform="rotate(-90 16 16)"
                                  stroke="white"
                                  stroke-opacity="0.3"
                                  stroke-width="2"
                                ></circle>
                                <path
                                  d="M12 15.8333L20 15.8333M20 15.8333L16 11.6667M20 15.8333L16 20"
                                  stroke="white"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                              </svg>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="sub_services">
              <div className="sub_service_div">
              <div className="serviceBox">
                <img src="https://images.unsplash.com/photo-1588600878108-578307a3cc9d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1176&q=80" alt="" />
                <h1>Feasibility Studies </h1>
                <p>To ascertain the possibility of a given project's success, our in-house team of researchers conducts a thorough feasibility analysis. We take into account all of the essential factors like location, region, surrounding market demand, pricing, costs, and profit margin. It enables us to get keen insights into any given market and assess a project's return on investment.
                </p>
              </div>
              <div className="serviceBox">
                <img src="https://images.pexels.com/photos/3183153/pexels-photo-3183153.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
                <h1>Market Dynamics</h1>
                <p>The real estate industry is continuously evolving and the competition is increasing. We help you stay updated on the latest demand for particular real estate project. We also guide developers on the current appropriate pricing and what products can be sold in the current scenario. 
                </p>
              </div>
              <div className="serviceBox">
                <img src="https://images.unsplash.com/photo-1524758631624-e2822e304c36?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80" alt="" />
                <h1>Premises Acquisitions & Disposal</h1>
                <p>Understanding our client's needs allow us to assist in on- and off-market acquisitions, handling all components such as inspection, survey, lease term negotiation, or purchase through final transaction closure. We provide you with information needed to make well-informed decisions and choose the correct entry and exit strategies for your deals.
                </p>
              </div>
              </div>
              <div className="sub_service_div">
              <div className="serviceBox">
                <img src="https://images.unsplash.com/photo-1580123500477-c576fecd0732?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80" alt="" />
                <h1>Buy v/s Lease Solutions</h1>
                <p>Take advantage of our assistance as you decide whether renting or buying real estate is best for your business. Our Buy vs Leave assessment employs a proprietary approach tailored to your needs.
                </p>
              </div>
              <div className="serviceBox">
                <img src="https://images.pexels.com/photos/636342/pexels-photo-636342.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
                <h1>Leasing</h1>
                <p>Work with us to implement tailored leasing and marketing plans to add value to all types of commercial real estate assets.
                </p>
              </div>
              <div className="serviceBox">
                <img src="https://images.unsplash.com/photo-1572797796609-96ac0e3d7b9c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1887&q=80" alt="" />
                <h1>BTS Solutions </h1>
                <p>Our built-to-suit solutions are meant to meet your unique needs from day one. 
We aim to cater to your operational and corporate requirements and fast-forward your business with our already planned and designed built-to-suit solutions.  
                </p>
              </div>
              </div>
            </div>
          </div>
        </div>
        <div className="res__div9">
          <div className="about__div12">
            <div className="about__div13">
              <div className="about__div14">
                <div className="res__div015">
                  <p>
                  Futuristic Real Estate <span>Insights and Solutions</span>
                  </p>
                </div>
                <div className="about__div16">
                  <p>
                  Parikh Real Estate is one of Gujarat's leading and prominent real estate consultants. We provide our clients with a full range of professional services in the area of residential, commercial, and industrial real estate and impart knowledge and experience for project implementation and a boost in business efficiency and profitability across a wide range of investment opportunities.
                  </p>
                </div>
                <div className="about__div17">
                  <p>
                  Our goal is to achieve global service standards with local experience while offering a wide range of strategic, operational, and consulting services.
                  </p>
                </div>
                {/* <div className="about__div18">
                  <p>Learn More</p>
                  <div className="about__div19"></div>
                </div> */}
              </div>
              <div className="about__div20">
                <img src={serviceEnding} alt="" className="serviceImg"/>
                {/* <video
                  className="about__img3"
                  playsinline=""
                  autoplay=""
                  loop=""
                  muted=""
                  src=""
                  poster="https://victorstone.co.uk/wp-content/uploads/2022/09/residential-page-prime-property-finding-service-image.jpg"
                ></video> */}
              </div>
            </div>
          </div>
        </div>
        <div className="res__div10">
          <div className="about__div40" style={{marginTop: "5vw"}}>
          <div className="home__div34">
          <div className="home__div35" id="ser__div35">
            <div className="home__div36">
              <img
                src="https://victorstone.co.uk/wp-content/themes/victorstone/src/assets/images/featured-pattern.svg"
                className="home__img7"
                id="sesrvice_Img"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="home__div37">
          <div className="home__div38">
          <div className="home__div39">
              <div className="home__div40">
                <div className="home__div41">
                  <Link
                    to="/Our-Services/commercial"
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    <p>Commercial Services</p>
                  </Link>
                  <p>Feasibility Studies</p>
                  <p>Market Dynamics</p>
                  <p>Premises Acquisitions & Disposal</p>
                  <p>Buy v/s Lease Solutions</p>
                  <p>Leasing</p>
                  <p>BTS Solutions</p>
                  <div className="home__div42"></div>
                </div>
                <div className="home__div41">
                  <Link
                      to="/Our-Services/residential"
                      style={{ textDecoration: "none", color: "#fff" }}
                    >
                    <p>Residential Services</p>
                  </Link>
                  <p>Property Marketing</p>
                  <p>Exclusive Project Marketing</p>
                  <p>Location Selection</p>
                  <p>Budget Costing Advice</p>
                  <p>Competition Benchmarking</p>
                  <div className="home__div42"></div>
                </div>
              </div>
              <div className="home__div40">
                <div className="home__div41">
                <Link
                      to="/Our-Services/key-industrial"
                      style={{ textDecoration: "none", color: "#fff" }}
                    >
                  <p>Industrial Services</p>
                  </Link>
                  <p>Land Acquisition & Conversion</p>
                  <p>63 AA/ 65KH </p>
                  <p>Built – To – Suit (B.T.S) Solution</p>
                  <p>Feasibility Studies</p>
                  <p>Buy v/s Lease Solutions</p>
                  <p>Consent to Establish </p>
                  <div className="home__div42"></div>
                </div>
                <div className="home__div41">
                <Link
                      to="/Our-Services/capital-market"
                      style={{ textDecoration: "none", color: "#fff" }}
                    >
                  <p>Capital Markets</p>
                  </Link>
                  <p>Investment Strategy</p>
                  <p>Private Equity</p>
                  <p>Fundraising For Developers</p>
                  <p>Developer Land</p>
                  <p>Joint Venture</p>
                  <p>Joint Development</p>
                  <div className="home__div42"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="home__div43">
            <div className="home__div44">
              <div className="home__div45">
                <p>Need help with something else?</p>
                <Link
                to="/contact"
                style={{ textDecoration: "none", color: "#fff" }}
              >
                <div className="home__div46">
                  <p>Contact Us</p>
                  <svg
                    className="home__svg3"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="16"
                      cy="16"
                      r="15"
                      transform="rotate(-90 16 16)"
                      stroke="white"
                      stroke-opacity="0.3"
                      stroke-width="2"
                    ></circle>
                    <path
                      d="M12 15.8333L20 15.8333M20 15.8333L16 11.6667M20 15.8333L16 20"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>
                </Link>
              </div>
            </div>
            <img
              src={helpCenter}
              className="home__img8"
              alt=""
            />
          </div>
        </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Buy;
