import React from 'react'
import airbnb from "../../../assets/Blog/blog26.jpeg"

function TwentySixth() {
  return (
    <div className="first__div1">
        <div className="first__div2">
            <div className="first__div3">
                <img src={airbnb} className="first__img1" alt="" />
            </div>
            <div className="first__div4">
                <p><b>Self-Redevelopment of old Societies</b></p>
            </div>
            <div className="first__div5">
                <p>Many of the buildings in city have outlived their useful life and pose a risk to the lives of the residents. The government also has been promoting and regulating the redevelopment of existing properties to cater to the demand for housing and to replace the old buildings.</p>
                <p>Now, many societies are approaching developers for redevelopment of their society. Builders find redevelopment projects attractive as there is a huge potential for profits from selling the extra inventory, left after allocating units to original members. The builder gets to pocket the entire gains from the saleable area. </p>
                <p>Where thousand off building waiting for their turn for redevelopment, society redevelopment seems non-viable proposition. in case If the society is located in an internal road or having a smaller plot size, members might not get a good deal, as the builder might not be able to use the extra FSI, owing to certain restrictions. This reduces the chances for the builder to make profit on the deal. Therefore, when the society decides to opt for redevelopment, it should be cautious in striking a deal with a developer.</p>
                <p>We have the old-style practice, is for redevelopment to be done through a developer. Where as currently in cities like mumbai such societies are opting for self-redevelopment project. That means the redevelopment work is undertaken by the society itself, with the supervision of its members, it is known as self-redevelopment. Here, the residents keep builders out of the equation and instead appoint a contractor and project management consultant to help execute the project. though it is not easy task but there is no other choice.</p>
                <p>Also, The major advantage under a self-redevelopment project is that any surplus accrued from the sale of additional inventory is entirely retained by the society, to be distributed equally among its members. </p>
                <p>To execute a self-redevelopment project, the housing society must have conveyance in its own name—the society must be the owner of the land. To get the project off the ground, the housing society first needs to get consent from its members. Once the members have consented, the society can hire a project management consultant for one-point contact through whole construction process. The consultant can manage the entire process for the society— get the feasibility study done, hire and coordinate with contractors and engineers, get the needed approvals, secure loans to arrange for the sale of unused flats and ensure timely execution of the project. Incase the society members doesn’t want to hire a consultant, the society members will have to initiate tenders to hire a contractor & an architect.</p>
                <p>Besides this under self-redevelopment project the society also retains control over all aspects of the redevelopment. members can mutually decide the unit/Society plan, the type of amenities—parking lot, gym, pool, children’s play area, etc.—to be included in the project as well as reserve the choice of new members for the saleable area. While the builder takes an irrevocable power of attorney (POA) from the members, whereby the land rights are vested in his hands, all property rights remain with the society in the case of self-redevelopment.</p>
                <p>However, Securing consent and cooperation of all members, Getting clearances and permissions & finding secure funding resources can be exhausting. Government of Maharashtra Govt. have policy for self-redevelopment of the building, which we doesn’t have here in Gujarat. It is expected from the Gujarat govt. to encourage such move in near future.</p>
            </div>
        </div>
    </div>
  )
}

export default TwentySixth