import React from 'react'
import airbnb from "../../../assets/Blog/blog13.jpeg"

function Thirteenth() {
  return (
    <div className="first__div1">
        <div className="first__div2">
            <div className="first__div3">
                <img src={airbnb} className="first__img1" alt="" />
            </div>
            <div className="first__div4">
                <p><b>Impact of lockdown on construction subsidiary industries</b></p>
            </div>
            <div className="first__div5">
                <p>The construction industry in India is the second largest employer after agriculture, and it is therefore critical to the country’s economic stability. With an industry size of INR 10.5 trillion, it accounts for around 8 per cent of the nation’s GDP and employs close to 57.5 million people. Also, being a core sector, there are numerous industries that are dependent on the construction activity in the country.</p>
                <p>After the lockdown, the real estate sector, like all other economic activities, was came to a standstill. There were less to no property transactions, and registration of properties stopped. Which is now slowly on recovering stage. However, most buyers are adopting a wait and watch approach & expecting cheaper properties.  Meanwhile, developers contend with the fact that costlier construction material will restrict steep price corrections. Prices of construction materials like cement, steel, gravel, etc have increased by 30% to 50%. Some of these will have to be passed on to the home buyer. This cycle goes on & at the same time, for developers, constraints on project completion will mount.</p>
                <p>There is a severe liquidity crunch in the market. Most projects are construction linked wherein payment to the builder is made on the level of construction completion. With construction stopping and no new projects coming their way, builders are struggling with low capital inflows. Some construction projects have been delayed, and some cancelled, as a result this will impact the entire construction material supply chain cycle. Coronavirus-related curbs in production and the movement of people have halted activity across industries, drying up purchases from key sectors. Production is expected to fall in the year that started April due to lower sales and higher inventories. While material markets like steel, cement, glass, textile, ceramic industries etc has also been severely affected.</p>
                <p>For Instance, Steel output and demand in India, the world’s second-biggest producer, slumped after lockdown. Muted demand and oversupply is likely to create a loop leading to suppressed prices until either there is a substantial uplift in demand or a substantial volume goes out of the market. The key challenges is the manpower availability and ensuring finished inventory to normal levels. Domestic steel demand in FY21 is likely to drop by around 12-15 per cent year-on-year with end-use industries being closed down and limited demand growth expected over the near term. Demand from sectors including construction and automobiles and consumer durables declined during the lockdown period, leading to a 4.7% month-on-month increase in stockpiles of finished steel to 13.85 million tons at the end of April.</p>
                <p>With addition of, Cement market across the country is facing sluggish sales. The volume is expected to shrink 10-30% in FY2021. the slow construction by major players, while individual home builders would delay new construction due to gloomy business outlook, fear of income loss, labour shortage, and uncertainty with respect to resumption of normalcy. </p>
                <p>Also, Currently, hiring labour for the realty and construction industries is challenging. Since millions of workers have migrated to their hometowns due to lack of work during lockdown, employers are dreading a nightmare scenario. Even after the lockdown is lifted, kick-starting operations is become extremely difficult for almost all sectors. For a labour-intensive industry as real estate, the reverse migration is worsening the situation. </p>
                <p>This parallels the time when the introduction of minimum assured wages via MNREGA made many village labourers unwilling to travel far from their homes. Accordingly, even if restrictions are lifted, workers may not return soon to their employers. For developers, this could cause delays stretching a few months or more. If these are prolonged, realty will be hard hit. Prices will then stay depressed until normalcy returns in expected time period of 3-4 Quarters. Presently, homebuyers are reluctant to book in under-development projects, preferring ready-to-move-in flats since it greatly minimises risks. </p>
            </div>
        </div>
    </div>
  )
}

export default Thirteenth