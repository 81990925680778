import React from 'react'
import airbnb from "../../../assets/Blog/blog27.jpeg"

function TwentySeventh() {
  return (
    <div className="first__div1">
        <div className="first__div2">
            <div className="first__div3">
                <img src={airbnb} className="first__img1" alt="" />
            </div>
            <div className="first__div4">
                <p><b>What Gujarat Real Estate has achieved so far: Flashback 2019 & Expectations from 2020</b></p>
            </div>
            <div className="first__div5">
                <p>Happy New Year to Everyone! This is the first article of year 2020. Let’s see what real estate sector has achieved so far and what can be expected from coming year.</p>
                <p>After growing at a blistering speed in the past, the price rally in some of India’s prime property markets halted in the wake of a prolonged period of slump. However, it won’t be completely right to say that property prices fell sharply in any of these markets. Rates largely remained unchanged in these markets in the last five years. However, prices in some markets continued to grow despite the many hiccups. Lower than expected growth rate in the sector demanded new initiatives to facilitate a resurgence.</p>
                <p>At a time when signs of slowdown made themselves visible in 2013, the government sharpened its efforts to unveil regulations in order to rid real estate of its hurdles. Since 2014 the sector got one surprise after Govt. launched the RERA in 2014, GST, demonetisation, the act against benami property and the insolvency code.</p>
                <p>No doubt this regulations hurdled the growth at some point but later at long term, these structural reforms have tightened the regulatory framework in a previously fragmented and unorganized sector, making way for a market that is much more consolidated, mature, and therefore capable of attracting sustainable growth and investment. RERA has helped boost housing demand by empowering buyers, ensuring that serious players are better placed because of track of project completions and hence better customer confidence. RERA, it injects some much-needed transparency and accountability into the sector with a simplified tax structure and greater compliance. while demonetisation ensured only genuine buyers remained in the market. Consequently, there has been no unreasonable rise in property rates.</p>
                <p>The slowdown in sales and tightening of liquidity prompted developers to realign their strategies. Developers are started focusing on completion of the existing projects given that the current demand is primarily from the end user. Later in October 2019, government announced realty fund to cater stalled/unfinished inventories. However, the declared fund was not enough.</p>
                <p>Given the way the economy is moving forward, some trends are becoming prominent in the Indian real estate industry and are being embraced with the spirit of endurance. It is important to customise the offering according to the changing dynamics of the market.</p>
                <p>There has been a strong push by the government for the affordable housing segment. In April 2019, Govt. announced GST rate cut to help boost housing demand. The GST Council has also done away with the Input Tax Credit system, which helped to revive buyer sentiment. As a result, this segment accounts for around 50 per cent of the total residential housing sales. While a large number of developers are getting into this segment, the government may have to announce some strong measures to further propel demand.</p>
                <p>Now, developers are experimenting with new sectors and offerings, etc. Housing demand has moved to user segment as against investors. developers started constructing & offering thoughtfully, provision of amenities in affordable housing that typically luxury projects offered, Improved quality of construction, adaptation of green building. Development of Age specific Housing (Senior Living , Child Friendly housing) , Co-working, Co-living spaces etc.</p>
                <p>In the days ahead, one can expect a flurry of changes in the way these offerings are structured across markets and segments. The future of housing and real estate is “sustainable living”. The concept of sustainability is being the motto and goals of various countries, but the practice of the concept is very limited. The builders and real estate agents are working towards creating buildings with the sustainable infrastructure, green housing concepts and better concept to manage resources and waste. To encourage this movement government initiatives are expected. </p>
                <p>The coming year will see the industry grow in terms of newer policies aimed at improving buyer sentiments, the sale of affordable housing and adaptation of Sustainable buildings.</p>
                <p>As we head to 2020, there are interesting trends that have emerged in the last few years that will be guiding the outlook for the sector. The residential segment will also see more traction from the buyers with increased demand for co-living and affordable spaces. On the commercial front, co-working space, data centers and warehousing space would continue to be in demand. </p>
                <p>Looking forward at 2020, the real estate industry in india is expected to grow at a significant pace. Let’s expect 2020 to be a good year for construction and real estate industry.</p>
            </div>
        </div>
    </div>
  )
}

export default TwentySeventh