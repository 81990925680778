import React from "react";
import "../../styles/blog.css";

// Images
import { Link } from "react-router-dom";
import air from "../../assets/Airbnb.jpeg";
import coworking from "../../assets/CoWorking.jpeg";
import fake from "../../assets/Fake Listing.jpeg";
import covid from "../../assets/Covid 19.jpeg";

import blog7 from "../../assets/Blog/blog7.jpeg"
import blog8 from "../../assets/Blog/blog8.png"
import blog9 from "../../assets/Blog/blog9.jpeg"
import blog10 from "../../assets/Blog/blog10.jpeg"
import blog11 from "../../assets/Blog/blog11.jpeg"
import blog12 from "../../assets/Blog/blog12.jpeg"
import blog13 from "../../assets/Blog/blog13.jpeg"
import blog14 from "../../assets/Blog/blog14.jpeg"
import blog15 from "../../assets/Blog/blog15.jpeg"
import blog16 from "../../assets/Blog/blog16.jpeg"
import blog17 from "../../assets/Blog/blog17.jpeg"
import blog1819 from "../../assets/Blog/blog18-19.jpeg"
import blog20 from "../../assets/Blog/blog20.jpeg"
import blog21 from "../../assets/Blog/blog21.jpeg"
import blog22 from "../../assets/Blog/blog22.jpeg"
import blog23 from "../../assets/Blog/blog23.jpeg"
import blog24 from "../../assets/Blog/blog24.jpeg"
import blog25 from "../../assets/Blog/blog25.jpeg"
import blog26 from "../../assets/Blog/blog26.jpeg"
import blog27 from "../../assets/Blog/blog27.jpeg"

function Blog() {

  const scrollTop = () => {
    window.scrollTo(0,0);
  }

  return (
    <div className="blog__div1">
      <div className="Blog" id="Blog">
        <Link
          to="/blog/first"
          style={{ textDecoration: "none", color: "#000" }}
          onClick={scrollTop}
        >
          <div>
            <div>
              <img src={air} alt="" />
              <h1>Concept of Airbnb & Its Adaptation in India</h1>
              <p>
                The concept of sharing has become widely acceptable weathers its
                “co-working” “co-living” or traveling. India is one of the
                fastest growing economies. Increasingly, more Indians want to
                travel on a frequent basis which is a millennial trend, and with
                410 million millennials—, travel is at the top of things they
                want to do...
              </p>
            </div>
            <a
              href="http://"
              style={{ textDecoration: "underline", color: "#000" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Read More
            </a>
          </div>
        </Link>
        <Link
          to="/blog/second"
          style={{ textDecoration: "none", color: "#000" }}
          onClick={scrollTop}
        >
          <div>
            <div>
              <img src={coworking} alt="" />
              <h1>Co-working & Co-living Market Pre & Post Lockdown</h1>
              <p>
                The past few years has seen a rise in the use of shared office
                spaces, with providers such as WeWork experiencing exponential
                growth. The co-working industry grew substantially along with
                the protracted bull market, and it’s well documented that
                co-working was a primary driver of new office absorption across
                large cities, especially over the last 3-5 years.
              </p>
            </div>
            <a
              href="http://"
              style={{ textDecoration: "underline", color: "#000" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Read More
            </a>
          </div>
        </Link>
        <Link
          to="/blog/third"
          style={{ textDecoration: "none", color: "#000" }}
          onClick={scrollTop}
        >
          <div>
            <div>
              <img src={fake} alt="" />
              <h1>Fake listings on online real estate portals</h1>
              <p>
                We live in a world with remarkable technological innovations.
                Gone are the days when you had to trek between several
                apartments and sites to shortlist your desired property. All
                this used to take weeks or months in the bygone days. Now, all
                the information is at your fingertips. Online real estate
                portals offer a plethora of options and services to choose from.
              </p>
            </div>
            <a
              href="http://"
              style={{ textDecoration: "underline", color: "#000" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Read More
            </a>
          </div>
        </Link>
      </div>
      <div className="Blog">
        <Link
          to="/blog/fourth"
          style={{ textDecoration: "none", color: "#000" }}
          onClick={scrollTop}
        >
          <div>
            <div>
              <img src={covid} alt="" />
              <h1>Impact of Covid-19 on Indian Hospitality Industry</h1>
              <p>
                The Pandemic is a challenge to both our lives and livelihoods.
                The crisis is unprecedented and moving quickly, yet still deeply
                uncertain. In line with the nationwide call to "reduce
                unnecessary travel", the number of tourists over has decreased
                sharply. nearly all the hospitality markets around have been
                affected badly.
              </p>
            </div>
            <a
              href="http://"
              style={{ textDecoration: "underline", color: "#000" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Read More
            </a>
          </div>
        </Link>
        <Link
          to="/blog/fifth"
          style={{ textDecoration: "none", color: "#000" }}
          onClick={scrollTop}
        >
          <div>
            <div>
              <img src="https://images.pexels.com/photos/7054415/pexels-photo-7054415.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
              <h1>Budget 2020-21 for Real Estate Sector India</h1>
              <p>
              With the economy in midst of a sharp slowdown, the General Budget for 2020-21 was being awaited with high expectations to act as a growth booster. India's real estate sector has been witnessing a prolonged period of weak sales, resulting in a sharp decline in its contribution to the country's GDP. 
              </p>
            </div>
            <a
              href="http://"
              style={{ textDecoration: "underline", color: "#000" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Read More
            </a>
          </div>
        </Link>
        <Link
          to="/blog/sixth"
          style={{ textDecoration: "none", color: "#000" }}
          onClick={scrollTop}
        >
          <div>
            <div>
              <img src="https://images.unsplash.com/photo-1582407947304-fd86f028f716?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=996&q=80" alt="" />
              <h1>Indian Real Estate –Keeping up with the trends</h1>
              <p>
              Over the past decade, real estate industry in India has seen a dramatic change in terms of approach, marketing strategy and Construction methods. 
Real Estate projects are highly priced products which cannot be sold efficiently without a well strategized marketing campaign so as to reach out to the exact targeted market. 
              </p>
            </div>
            <a
              href="http://"
              style={{ textDecoration: "underline", color: "#000" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Read More
            </a>
          </div>
        </Link>
      </div>
      <div className="Blog">
      <Link
          to="/blog/seventh"
          style={{ textDecoration: "none", color: "#000" }}
          onClick={scrollTop}
        >
          <div>
            <div>
              <img src={blog27} alt="" />
              <h1>What Gujarat Real Estate has achieved so far: Flashback 2019 & Expectations from 2020</h1>
              <p>
              Happy New Year to Everyone! This is the first article of year 2020. Let’s see what real estate sector has achieved so far and what can be expected from coming year. After growing at a blistering speed in the past, the price rally in some of India’s prime property markets halted in the wake of a prolonged period of slump. However, it won’t be completely right to say that property prices fell sharply in any of these markets.
              </p>
            </div>
            <a
              href="http://"
              style={{ textDecoration: "underline", color: "#000" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Read More
            </a>
          </div>
        </Link>
        <Link
          to="/blog/eighth"
          style={{ textDecoration: "none", color: "#000" }}
          onClick={scrollTop}
        >
          <div>
            <div>
              <img src={blog8} alt="" />
              <h1>Your next site visit, just a few clicks away</h1>
              <p>
              Gone are the days when you could just hop in your car and drive from one to another property showing. Today's buying experience has gone completely virtual. the COVID-19 lockdown has accelerated technology-led home buying in India, making it possible to inspect properties online as well as negotiate and finalise deals. but you'll have to be comfortable with doing it without the in-person visits.
              </p>
            </div>
            <a
              href="http://"
              style={{ textDecoration: "underline", color: "#000" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Read More
            </a>
          </div>
        </Link>
        <Link
          to="/blog/ninth"
          style={{ textDecoration: "none", color: "#000" }}
          onClick={scrollTop}
        >
          <div>
            <div>
              <img src={blog9} alt="" />
              <h1>FAQs</h1>
              <p>
              I am planning to buy a 3BHK apartment in West Ahmedabad. I heard there will be drop in pricing. Currently which builders or projects are offering great deals & offers to homebuyers?
              </p>
            </div>
            <a
              href="http://"
              style={{ textDecoration: "underline", color: "#000" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Read More
            </a>
          </div>
        </Link>
      </div>
      <div className="Blog">
      <Link
          to="/blog/tenth"
          style={{ textDecoration: "none", color: "#000" }}
          onClick={scrollTop}
        >
          <div>
            <div>
              <img src={blog10} alt="" />
              <h1>Post covid relief measures, Housing made more affordable.</h1>
              <p>
              Prime Minister Narendra Modi's announcement of a Rs 20 lakh crore stimulus package was the "need of the hour" as it will pave the way for post pandemic recovery, unleash the next wave of economic growth and to build a self-reliant india. The new package, which is equivalent to around 10% of India’s GDP, including the previously released package back in March, and central bank liquidity measures.
              </p>
            </div>
            <a
              href="http://"
              style={{ textDecoration: "underline", color: "#000" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Read More
            </a>
          </div>
        </Link>
        <Link
          to="/blog/eleventh"
          style={{ textDecoration: "none", color: "#000" }}
          onClick={scrollTop}
        >
          <div>
            <div>
              <img src={blog11} alt="" />
              <h1>Co-working & Co-living Market Pre & Post Lockdown</h1>
              <p>
              The past few years has seen a rise in the use of shared office spaces, with providers such as WeWork experiencing exponential growth.  The co-working industry grew substantially along with the protracted bull market, and it’s well documented that co-working was a primary driver of new office absorption across large cities, especially over the last 3-5 years.
              </p>
            </div>
            <a
              href="http://"
              style={{ textDecoration: "underline", color: "#000" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Read More
            </a>
          </div>
        </Link>
        <Link
          to="/blog/twelfth"
          style={{ textDecoration: "none", color: "#000" }}
          onClick={scrollTop}
        >
          <div>
            <div>
              <img src={blog12} alt="" />
              <h1>Still confused? Ask us away.</h1>
              <p>
              I Have received a lot of queries over a past few weeks. So, in this article I would like to answer few. How Many Properties an NRI Can Purchase in India, also what are the payment options for property Purchase? 
              </p>
            </div>
            <a
              href="http://"
              style={{ textDecoration: "underline", color: "#000" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Read More
            </a>
          </div>
        </Link>
      </div>
      <div className="Blog">
      <Link
          to="/blog/thirteenth"
          style={{ textDecoration: "none", color: "#000" }}
          onClick={scrollTop}
        >
          <div>
            <div>
              <img src={blog13} alt="" />
              <h1>Impact of lockdown on construction subsidiary industries</h1>
              <p>
              The construction industry in India is the second largest employer after agriculture, and it is therefore critical to the country’s economic stability. With an industry size of INR 10.5 trillion, it accounts for around 8 per cent of the nation’s GDP and employs close to 57.5 million people. Also, being a core sector, there are numerous industries that are dependent on the construction activity in the country.
              </p>
            </div>
            <a
              href="http://"
              style={{ textDecoration: "underline", color: "#000" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Read More
            </a>
          </div>
        </Link>
        <Link
          to="/blog/fourteenth"
          style={{ textDecoration: "none", color: "#000" }}
          onClick={scrollTop}
        >
          <div>
            <div>
              <img src={blog14} alt="" />
              <h1>RBI to provide moratorium on loans and EMIs</h1>
              <p>
              The Reserve Bank of India (RBI) announced an extension of the moratorium on term loan EMIs by another three months, i.e. till August 31, 2020 in a press conference dated May 22, 2020. The earlier three-month moratorium on the loan EMIs was ending on May 31, 2020. This makes it a total of six months of moratorium on loan EMIs (equated monthly instalment) starting from March 1, 2020 to August 31, 2020.
              </p>
            </div>
            <a
              href="http://"
              style={{ textDecoration: "underline", color: "#000" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Read More
            </a>
          </div>
        </Link>
        <Link
          to="/blog/fifteenth"
          style={{ textDecoration: "none", color: "#000" }}
          onClick={scrollTop}
        >
          <div>
            <div>
              <img src={blog15} alt="" />
              <h1>Force Majeure and its impact on landlords and tenants.</h1>
              <p>
              We are witnessing an unprecedented situation, which not only has disrupted all our economic and organisational norms, but also has deeply affected our personal and social relationships.  lockdown and economic activities virtually came to a standstill, tenants have started waiving rents.
              </p>
            </div>
            <a
              href="http://"
              style={{ textDecoration: "underline", color: "#000" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Read More
            </a>
          </div>
        </Link>
      </div>
      <div className="Blog">
      <Link
          to="/blog/sixteenth"
          style={{ textDecoration: "none", color: "#000" }}
          onClick={scrollTop}
        >
          <div>
            <div>
              <img src={blog16} alt="" />
              <h1>Combating Covid-19</h1>
              <p>
              We have heard restructuring of loans for Airline business or huge brands/companies, while they are in need. But one may have never heard about loan restructuring for real estate sector. Restructuring is a practice that allows banks to modify the terms of the loan when the borrower is facing financial stress.
              </p>
            </div>
            <a
              href="http://"
              style={{ textDecoration: "underline", color: "#000" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Read More
            </a>
          </div>
        </Link>
        <Link
          to="/blog/seventeenth"
          style={{ textDecoration: "none", color: "#000" }}
          onClick={scrollTop}
        >
          <div>
            <div>
              <img src={blog17} alt="" />
              <h1>Expected Positive Impact of Covid-19</h1>
              <p>
              The Novel Coronavirus or Covid-19 has infected more than 16 Lac people worldwide and economy has been majorly impacted all over the world. Though the impact is uncertainty, many experts have different views on the Post Covid-19 crisis. I have previously shared some of the negative impact of the pandemic on the real estate market, now here I’m sharing some of the believed positive impacts by real estate experts.
              </p>
            </div>
            <a
              href="http://"
              style={{ textDecoration: "underline", color: "#000" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Read More
            </a>
          </div>
        </Link>
        <Link
          to="/blog/eighteenth"
          style={{ textDecoration: "none", color: "#000" }}
          onClick={scrollTop}
        >
          <div>
            <div>
              <img src={blog1819} alt="" />
              <h1>How COVID-19 will impact real estate industry in India</h1>
              <p>
              Usually, the festive season are considered to be auspicious occasions for launching new projects or buying new properties, but this year it’s not the same. Even as the Indian economy strives to recover from a slowdown, the Coronavirus outbreak has further hit the possibility of revival given the negative impact on the various sectors of the economy. 
              </p>
            </div>
            <a
              href="http://"
              style={{ textDecoration: "underline", color: "#000" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Read More
            </a>
          </div>
        </Link>
      </div>
      <div className="Blog">
      <Link
          to="/blog/nineteenth"
          style={{ textDecoration: "none", color: "#000" }}
          onClick={scrollTop}
        >
          <div>
            <div>
              <img src={blog1819} alt="" />
              <h1>How COVID-19  impact real estate industry in India - II</h1>
              <p>
              The current outbreak of the novel Coronavirus, a virus that has wreaked havoc globally, is the most crucial time witnessed by the world lately, in global history.  On 25th March 2020, India Was officially ordered to countrywide lockdown for 21 days to contain Covid-19 spread.
              </p>
            </div>
            <a
              href="http://"
              style={{ textDecoration: "underline", color: "#000" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Read More
            </a>
          </div>
        </Link>
        <Link
          to="/blog/twentieth"
          style={{ textDecoration: "none", color: "#000" }}
          onClick={scrollTop}
        >
          <div>
            <div>
              <img src={blog20} alt="" />
              <h1>Investing in Commercial Real Estate</h1>
              <p>
              Although the idea of investing, in general, has been a popular trend among the majority of individuals lately, one specific sector has become increasingly popular for a number of reasons; commercial real estate. Commercial properties are different from residential subdivision properties, as the term commercial refers to land for sale or buildings intended to generate a profit for an investor or owner.
              </p>
            </div>
            <a
              href="http://"
              style={{ textDecoration: "underline", color: "#000" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Read More
            </a>
          </div>
        </Link>
        <Link
          to="/blog/twenty-first"
          style={{ textDecoration: "none", color: "#000" }}
          onClick={scrollTop}
        >
          <div>
            <div>
              <img src={blog21} alt="" />
              <h1>Is it a good time to buy a house?</h1>
              <p>
              Real estate demand may have declined in past few weeks as the COVID-19 outbreak worsened, but that doesn’t mean it’s gone entirely. Despite the list of evolving challenges, one thing that is still true this buying season is that there’s a lot of supressed demand. Although some of the conventional thinking on what makes now a good time to buy still remains true, there are new factors for home shoppers to consider.
              </p>
            </div>
            <a
              href="http://"
              style={{ textDecoration: "underline", color: "#000" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Read More
            </a>
          </div>
        </Link>
      </div>
      <div className="Blog">
      <Link
          to="/blog/twenty-second"
          style={{ textDecoration: "none", color: "#000" }}
          onClick={scrollTop}
        >
          <div>
            <div>
              <img src={blog22} alt="" />
              <h1>Pros & Cons of self-redevelopment</h1>
              <p>
              Self-redevelopment looks a little different. It doesn’t mean waiting for a third-party developer to come calling, making an offer, sitting through endless rounds of negotiation and acceding to the additional floors the developer will construct for returns on his investment.
              </p>
            </div>
            <a
              href="http://"
              style={{ textDecoration: "underline", color: "#000" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Read More
            </a>
          </div>
        </Link>
        <Link
          to="/blog/twenty-third"
          style={{ textDecoration: "none", color: "#000" }}
          onClick={scrollTop}
        >
          <div>
            <div>
              <img src={blog23} alt="" />
              <h1>Real Estate scams to be cautious of</h1>
              <p>
              While buying a property with our lifetime savings, the cost of the property involves large financial commitment over a long tenure. And you don’t buy Real Estate Property every day. Hence necessary caution has to be taken while entering into such transactions. Although the Indian real estate industry now has customer-friendly rules and regulations, it is important for a buyer to be watchful, so that you don't end up becoming the victim of a fraud. Below, we have outlined the primary real estate tricks that are on-going in India which a buyer needs to be cautious of.
              </p>
            </div>
            <a
              href="http://"
              style={{ textDecoration: "underline", color: "#000" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Read More
            </a>
          </div>
        </Link>
        <Link
          to="/blog/twenty-fourth"
          style={{ textDecoration: "none", color: "#000" }}
          onClick={scrollTop}
        >
          <div>
            <div>
              <img src={blog24} alt="" />
              <h1>Rental Housing in India</h1>
              <p>
              Housing is one of the basic necessities of life. the demand of housing and the existing housing lot is either insufficient or is not affordable due to many factors. People go for rental housing because  low-income & immigrants are not ready to build their own house. In spite of government’s prime consideration to affordable housing, many poor households live in congested conditions, which indicates that housing is unaffordable for a large section of population, be it ownership or rental.
              </p>
            </div>
            <a
              href="http://"
              style={{ textDecoration: "underline", color: "#000" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Read More
            </a>
          </div>
        </Link>
      </div>
      <div className="Blog">
      <Link
          to="/blog/twenty-fifth"
          style={{ textDecoration: "none", color: "#000" }}
          onClick={scrollTop}
        >
          <div>
            <div>
              <img src={blog25} alt="" />
              <h1>Safety measures during pandemic</h1>
              <p>
              Considering the suggestion from developers, providing relief to real estate sector the government has allowed resumption of construction activity in non-Covid hotspots  from April 20 while following strict social distancing and safety measures.
              </p>
            </div>
            <a
              href="http://"
              style={{ textDecoration: "underline", color: "#000" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Read More
            </a>
          </div>
        </Link>
        <Link
          to="/blog/twenty-sixth"
          style={{ textDecoration: "none", color: "#000" }}
          onClick={scrollTop}
        >
          <div>
            <div>
              <img src={blog26} alt="" />
              <h1>Self-Redevelopment of old Societies</h1>
              <p>
              Many of the buildings in city have outlived their useful life and pose a risk to the lives of the residents. The government also has been promoting and regulating the redevelopment of existing properties to cater to the demand for housing and to replace the old buildings.
              </p>
            </div>
            <a
              href="http://"
              style={{ textDecoration: "underline", color: "#000" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Read More
            </a>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default Blog;
