import React from 'react'
import airbnb from "../../../assets/Blog/blog21.jpeg"

function TwentyFirst() {
  return (
    <div className="first__div1">
        <div className="first__div2">
            <div className="first__div3">
                <img src={airbnb} className="first__img1" alt="" />
            </div>
            <div className="first__div4">
                <p><b>Is it a good time to buy a house?</b></p>
            </div>
            <div className="first__div5">
                <p>Real estate demand may have declined in past few weeks as the COVID-19 outbreak worsened, but that doesn’t mean it’s gone entirely.</p>
                <p>Despite the list of evolving challenges, one thing that is still true this buying season is that there’s a lot of supressed demand. Although some of the conventional thinking on what makes now a good time to buy still remains true, there are new factors for home shoppers to consider.</p>
                <p>If you are fortunate enough to be employed in an industry that hasn’t been devastated by the coronavirus, have the necessary resources, and decide you want to strike while the iron is hot, there are a few things you can do to take advantage of the low interest rates. Now is a good time to start browsing online and get yourself familiar with the market.</p>
                <p>The people who have been on the sidelines trying to buy a house forever actually have an opportunity to do that. Potential buyers know that interest rates are lower than interest rates of 7.15 to 7.8 per cent on home loans currently will give more benefit. </p>
                <p>With new home loan rates one can save from 4-5 Lacs on Interest rates. along with this The Finance Minister has announced the extension of the interest subsidy scheme for middle-income home buyers. Meaning If you earn less than Rs 18 lakh per year, you can take the subsidy on interest. the subsidy of up to Rs 2.67 lakh under CLSS. That adds to the savings. </p>
                <p>The extension of this CLSS scheme coupled with attractive mortgage rates will improve consumer sentiments and boost demand for affordable and mid segment housing. </p>
                <p>And not only this, it has been said that one man’s crisis is another’s opportunity. For those who are eager enough—and willing to break traditional customs in favor of a virtual approach—there are deals to be had. Because of the instable current market, developers are forced to sell with many offers. </p>
                <p>Real estate prices are down and developers are trying hard to sell their inventory. Desperate to sell homes, builders are offering innovative schemes to lure customers. The buyer is expected to watch a video of the property, completed or under-construction. Payment terms are explained and the customer is expected to pay a token amount as an expression of interest. Instead of reducing property prices, developers are comfortable offering relaxed payment plans to attract buyers. Some builders ask buyers to pay just 10 percent of the property price at the time of booking and the rest on possession.</p>
                <p>If you have money ready and are the end user, you are better off negotiating with the builder and getting a better price. If you have made up your mind to buy a house for self-consumption, then try to negotiate. Developers will offer deals if you are a serious buyer – with down payment ready and a home loan sanction letter from a bank. </p>
                <p>Look through your finances and prepare for the time ahead when you will be out and ready to buy. What if you’re already farther along in the process and have found a home you love? Put in your offer and lock your rate in. It’s definitely a good time to shop around and get a good rate.</p>
                <p>Investors should avoid investing in commercial real estate such as shops. If the situation worsens, you may get better deals in residential real estate. Those looking to buy a home and hold onto it in the long term, particularly as a rental property, won’t face as much risk. </p>
                <p>For people still on the fence, the one thing that’s important to understand right now is that “now is the time for confidence” Make strong, well-informed choices and execute them.</p>
            </div>
        </div>
    </div>
  )
}

export default TwentyFirst