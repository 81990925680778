import React from 'react'
import "../../../styles/First.css"
import airbnb from "../../../assets/Airbnb.jpeg"

function First() {
  return (
    <div className="first__div1">
        <div className="first__div2">
            <div className="first__div3">
                <img src={airbnb} className="first__img1" alt="" />
            </div>
            <div className="first__div4">
                <p><b>Concept of Airbnb & Its Adaptation in India</b></p>
            </div>
            <div className="first__div5">
                <p>The concept of sharing has become widely acceptable weathers its “co-working” “co-living” or traveling. India is one of the fastest growing economies. Increasingly, more Indians want to travel on a frequent basis which is a millennial trend, and with 410 million millennials—, travel is at the top of things they want to do.</p>
                <p>Those of who loves to travels knows about “Airbnb”. While many of us don’t clearly know its concept. As travel has become increasingly commoditised, we understand that people want to break away from standard itineraries and discover new places on their own terms. Travellers want to live like locals do and immerse themselves in local culture. The beauty of being able to live in a home and have a host is that the possibility of a new local experience opens up. Whether staying in a private room in a city apartment authentic local experiences are created by the host and the manner in which they welcome guests into their home.</p>
                <p>Airbnb is an online marketplace that connects people who want to rent out their homes with people who are looking for accommodations in that locale. Any new concept takes more time for indian heads to understand and get comfortable with than American or European. Airbnb is picking up really well in India and is penetrating deep in not only tier cities but also in tier 2 cities as well.</p>
                <p>Airbnb takes a unique approach toward accommodation. Part of the “sharing economy,” Airbnb offers you someone’s home as a place to stay instead of a hotel. Throughout the application process, hosts and guests can find reviews and social media connections to build trust among users in the marketplace. While valuable, this approach is not unique to Airbnb. However, previous guests often post comments about their experiences, which can provide a more objective view.</p>
                <p>Also, if you want to rent out extra space in your own home, you can host through Airbnb and make money for allowing a guest to stay the night.  Airbnb has different hosting options — you can rent out the extra space in your home, you can rent out your entire home, or you can host experiences in your area. Airbnb does not own properties. It acts as an intermediary between those who want to rent out space and those who are looking for space to rent. Creating an account on Airbnb is free & Simple. The price of a room on Airbnb depends on a variety of factors, including location, the quality of the listing, and the amenities. </p>
                <p>Home sharing has thrived because it creates economic opportunity and turns your greatest expense of your homes into your greatest asset. Many say that their Airbnb income allows them to afford to stay in their home, while others use it as a means to meet new people, or use the income to pursue their personal Needs.</p>
                <p>Airbnb has recently announced the introduction of Plus Homes in India on Friday. In which the they visit the premise in person and Verify against a 100+ point inspection checklist to ensure greater comfort and quality. </p>
                <p>There are the clear economic benefits for local economies that stand to gain from the increase in tourists supported with a wider variety of affordable and available holiday listings. Homeowners and landlords also benefit, as turning their rooms and properties into short-term lets can offer an alternative and lucrative source of revenue.  Also The market of “Airbnb” can boost the rental housing market & fuel the current sluggish real estate market.</p>
                <p>However looking at impact in UK, As per a report 2.7% of the U.K.’s 1.5-million strong landlord population have already made the switch from long-term rental properties in the private rented sector to short-term lets, equating to 50,000 homes made unavailable to long-term tenants.  And, around 10% of U.K. landlords surveyed responded that they are considering moving their private rented properties to the short-term market. And that would stretch already strained housing supply. If more stock is moved into the short-term lettings market through platforms like Airbnb it could have collateral impacts on the wider market. As Low supply and increasing rents in the U.K. are a major concern.</p>
                <p>As Travel to emerging economies is expected to increase at twice the rate of travel to advanced economies from now until 2030, the impact of this new market hopefully positive will be seen in near future. </p>
            </div>
        </div>
    </div>
  )
}

export default First