import React from 'react'
import airbnb from "../../../assets/Blog/blog18-19.jpeg"

function Ninteenth() {
  return (
    <div className="first__div1">
        <div className="first__div2">
            <div className="first__div3">
                <img src={airbnb} className="first__img1" alt="" />
            </div>
            <div className="first__div4">
                <p><b>How COVID-19  impact real estate industry in India - II</b></p>
            </div>
            <div className="first__div5">
                <p>The current outbreak of the novel Coronavirus, a virus that has wreaked havoc globally, is the most crucial time witnessed by the world lately, in global history.  On 25th March 2020, India Was officially ordered to countrywide lockdown for 21 days to contain Covid-19 spread.</p>
                <p><strong>Construction Activity </strong></p>
                <p>COVID-19 has aggravated the persisting liquidity crunch in the real estate sector. The various restrictions imposed by the Government to curb the pandemic has left the developers stranded, consequently facing multiple issues, including stalling of construction work, shortage or non-availability of man-power and raw materials due to lock-downs across the nation coupled with uncertainty haunting potential commercial transactions and business operations. Many developers are skeptical and have their reservations around the launch of new projects considering the current situation. New project launches are expected to see a dip this festive season; however, there will be a few exceptions of course such as projects which are already in the soft-launch stage and have already seen a fair amount of marketing spend. </p>
                <p>Also the demand risks for the housing sector are likely to increase, given the rising apprehensions on overall economic growth and contagion-related fears leading to reduced walk-ins and inabilities to carry out site visits, thus resulting in some decline in new sales and the associated collections. Committed collections receivable from already booked sales may also get impacted to some extent, given that milestone-based payments may get deferred and some buyers may delay payments on account of economic uncertainties arising from the looming possibility of job cuts and pay cuts as the crisis extends.</p>
                <p>The three-month moratorium on term loan instalments announced by the RBI also provides comfort on overall developer cash flows during this period. Once the crisis is under control, the government will have to dole out various economic measures to bail out severely-hit sectors, reconsider its fiscal deficit targets and start spending.  All impacted real estate projects, will have to be excused from construction delay penalties under the respective states’ Real Estate Regulatory Authority.</p>
                <p><strong>Housing Prices</strong></p>
                <p>While all economies are shaken at the moment , investors and businesses are adopting a wait-and-watch approach. However For some, these current scenario might just prove to be the best time to invest in properties, if at all there is a fall in prices, instead of waiting for the festive season later when there is definitely going to be a spike in rates. For NRIs, opportunities are even more appealing with the fall of the Indian rupee. As the equity markets continue to vacillate, NRI investors had become wary of the stock markets and fell back on gold, real estate and bank deposits because of the relative security of these products.</p>
                <p>Looking at the current scenario, The fear of contagion has dampened business activities across the globe. The impact on real estate will be significant, especially in the immediate short term. Affordable and mid-income housing that has been a silver lining for the sector, over the past several years, will also come under pressure primarily because its success depends on maximum distribution and volume sales. However, we see this impact to be a temporary one, keeping in mind the measures being taken by governments across the world. The situation is bound to recover sooner or later, once the impact is absorbed.</p>
                <p><strong>Commercial & Industrial Market</strong></p>
                <p>There is a price correction expected in line with the global asset price crash. However, the commercial leasing will still look un-impacted due to a healthy long-term investment by many international companies in the positive India growth story. But for now  Commercial investments are expected to get delayed in time of uncertainty.</p>
                <p>Whereas, we have a golden opportunity be optimistic that the industrial and logistics sector is expected to grow. As the other countries have lost their trust in China. Also Imports from China have slowed down across the world, creating an opportunity for India. The future depends on how India reacts to the global epidemic and how effectively we contain it from spreading further. </p>
                <p>At this stage, it would be early to estimate the loss or impact to the real estate industry. The COVID-19 situation remains unpredictable, and uncertainty still looms on the possible economic impact of the outbreak. , it depends upon how long it takes for things to return to some semblance of normality and What remains to be seen is how the industry responds to the aftermath of deadly COVID-19. But till than what we know is that the world is bound to recover sooner than later. Every sphere of the economy, including the real estate sector, will face constraints such as reduced demand and delayed business decisions, but it will recover eventually. So till than let’s all stay at home & help our country to recover fast. </p>
            </div>
        </div>
    </div>
  )
}

export default Ninteenth