import React from 'react'
import airbnb from "../../../assets/Blog/blog14.jpeg"

function Fourteenth() {
  return (
    <div className="first__div1">
        <div className="first__div2">
            <div className="first__div3">
                <img src={airbnb} className="first__img1" alt="" />
            </div>
            <div className="first__div4">
                <p><b>RBI to provide moratorium on loans and EMIs</b></p>
            </div>
            <div className="first__div5">
                <p>The Reserve Bank of India (RBI) announced an extension of the moratorium on term loan EMIs by another three months, i.e. till August 31, 2020 in a press conference dated May 22, 2020. The earlier three-month moratorium on the loan EMIs was ending on May 31, 2020. This makes it a total of six months of moratorium on loan EMIs (equated monthly instalment) starting from March 1, 2020 to August 31, 2020. if you are a home loan borrower who opts to avail of the moratorium extension by 3 months and thereby take a total moratorium of six months.</p>
                <p>Extension in moratorium on term loan instalments has provided a major relief to many sectors borrowers and companies facing a cash crunch due to the nationwide lockdown and its associated adverse financial impact on the economy. The extension of the three-month moratorium on repayment of term loans means that borrowers will not have to pay their loan EMI instalments during such period as prescribed by the RBI. And their loan accounts continue to remain standard and their credit score is not adversely affected. </p>
                <p>The Reserve Bank of India’s this decision is expected to help lower the burden of interest payments of many sectors including realty developers and housing loan borrowers until August end. The move expected to help realty developers avoid default in payments and any subsequent downgrades. The extension will provide relief to many, especially those who are self-employed, as they would have found it difficult to service their loans like car loans, home loans etc. </p>
                <p>Borrowers availing moratorium are also allowed to spread their interest payments through the financial year end and to convert the accumulated interest for the moratorium period into a term loan. It will also provide some relief as the borrower will not have to immediately repay the accumulated interest on the loan after the moratorium ends. </p>
                <p>But The impact of availing loan extension varies widely across borrowers. For someone in the initial years of loan tenure and availing moratorium, his interest payout to the bank and tenure of the loan will extend significantly, as the additional interest for six months has to be paid on a higher base. For a borrower whose loan was taken some years ago and has been repaid significantly, the extra interest payment will be relatively smaller, resulting in a lower extension of the EMI schedule.</p>
                <p>During the life of a loan, the amount of interest decreases while the principal component increases as the loan amortisation progresses. One thing to always keep in mind is moratorium is not a loan waiver, it does not provide any monetary relief or concession in interest costs. Interest will continue to accrue on the outstanding portion of the loan during the moratorium period. For someone not facing any cash flow issues, moratorium is of no benefit.</p>
                <p>This move of RBI no doubt will help many. However, looking at the current increasing Crude Oil Rates it is hard to expect economy recovery in neat 6 months. A lot of companies depend on healthy crude oil prices. This includes tyre, lubricants, footwear, refining and airline companies, Transportation & Logistics. Since oil price increase will impact energy products like coal, natural gas, etc, this will negatively impact almost all manufacturing companies. The profitability of these companies is adversely affected due to higher input costs. This could negatively impact stock prices in the near term. There is no doubt, oil exploration companies in the country could benefit from a rise in oil prices. </p>
                <p>However, the major impact on manufacturing and transportation & Logistics services it will increase the end product prices. At this point while our whole country’s economy is trying to survive this can negatively impact the economy. </p>
                <p>Definitely the extended moratorium period will help many but this may require to further extension of 6 more months, while many are looking for new jobs & Many companies are trying to meet two ended. </p>
            </div>
        </div>
    </div>
  )
}

export default Fourteenth