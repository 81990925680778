import React from 'react'
import airbnb from "../../../assets/Blog/blog17.jpeg"

function Seventeenth() {
  return (
    <div className="first__div1">
        <div className="first__div2">
            <div className="first__div3">
                <img src={airbnb} className="first__img1" alt="" />
            </div>
            <div className="first__div4">
                <p><b>Expected Positive Impact of Covid-19</b></p>
            </div>
            <div className="first__div5">
                <p>The Novel Coronavirus or Covid-19 has infected more than 16 Lac people worldwide and economy has been majorly impacted all over the world. Though the impact is uncertainty, many experts have different views on the Post Covid-19 crisis. I have previously shared some of the negative impact of the pandemic on the real estate market, now here I’m sharing some of the believed positive impacts by real estate experts. </p>
                <p>According to the new analysis from United Nations Conference on Trade and Development (UNCTAD), the UN trade and development body titled 'The COVID-19 Shock to Developing Countries: Towards a 'whatever it takes' programme for the two-thirds of the world's population being left behind', commodity-rich exporting countries will face a $2 trillion to $3 trillion drop in investments from overseas in the next two years. Even so, the world economy will go into recession this year with a predicted loss of global income in trillions of dollars. This will spell serious trouble for developing countries, with the likely exception of India and China. </p>
                <p>The report, however, did not give a detailed explanation as to why and how India and China will be the exceptions as the world faces a recession and loss in global income that will impact developing countries. However, As per Experts The economic fallout from the shock is ongoing and increasingly difficult to predict, but there are clear indications that things will get much worsebefore they get better. </p>
                <p>Experts believe that developed markets will recover faster than the Indian markets. India will witness a slowdown in the current and coming quarter, however, it stands to benefit post recovery. Once things normalize, the production activities will need to be fast-tracked to relieve the stagnation in the next quarters. </p>
                <p>As the whole world seems to have lost control, India is doing far better and has managed to contain the pandemic so far. Also the whole world seems to have lost its faith and trust in China leads to changing mentality of people towards china.  it is expected that usage of any product/service connected to China will eventually decrease. And India providing most skilled manpower at the lowest cost will be suitable option for the world. India. India is likely to become the Manufacturing and Export hub in next 5 yrs. Which would create a huge Jobs.</p>
                <p>Also the impact of Pandamic is expected to lead to more localization of supply chains, especially of essentials and for sectors that are seen as strategically important. And Make in India, Startup India etc will give an extra push. Also Government and Banks will Infuse large amount of Money for economic revival. Commercial Spaces will eventually see a spike in demand. .</p>
                <p>A cloud of uncertainty is hovering over the market and the next two to three weeks will be critical, not only to understand how it will impact the market, but also in market sentiment. There is a silver lining, among the clouds, and members believe the real estate market will eventually self-correct in the period following the pandemic in 2021.  </p>
                <p>As per the experts, there still will be demand in the real estate market. There will be need to study the market more carefully to understand supply and demand coming into the market. Same for the developers new projects may take a while to launch but before that rework/ update of the entire project will be required to match the changed market demand. Also it has been expected that Lockdown will leave everyone with a desire to have bigger living space, therefore, most of the Occupants would look for better more spacious residential space. This un-paralleled demand in Real Estate is foreseen by many experts and it obviously makes it a lucrative deal for Investors as well as end user.</p>
                <p>Also, While we are now focusing in india on securing the population from health hazards and on providing relief, especially to the poor, we also need to think long-term to ensure the health of the economy , the viability of businesses, and the livelihoods of people. Apart from providing robust safety nets for the vulnerable, ensuring job continuity and job creation is key and there is an urgent need to mobilize resources to stimulate the economy.</p>
                <p>Meanwhile, for all small business owners’ work on ideas to make business more efficient and work on how to reduce unnecessary cost. Be Productive. Keep in contact with clients, vendor &, staff Power of communication at this time is immense. Include virtual communications with staff & Team members, even if not work share good thought. Do digital marketing / share awareness on social platforms. Something is better than nothing likewise Slow working is better than complete stoppage. Calculate all your expenditure, less debt is important to survive. Economy is down but will eventually rise and we all need to survive to live for next year. Don’t loose patience, we shall succeed.</p>
            </div>
        </div>
    </div>
  )
}

export default Seventeenth