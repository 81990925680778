import React from 'react'
import airbnb from "../../../assets/Blog/blog24.jpeg"

function TwentyFourth() {
  return (
    <div className="first__div1">
        <div className="first__div2">
            <div className="first__div3">
                <img src={airbnb} className="first__img1" alt="" />
            </div>
            <div className="first__div4">
                <p><b>Rental Housing in India</b></p>
            </div>
            <div className="first__div5">
                <p>Housing is one of the basic necessities of life. the demand of housing and the existing housing lot is either insufficient or is not affordable due to many factors. People go for rental housing because  low-income & immigrants are not ready to build their own house. In spite of government’s prime consideration to affordable housing, many poor households live in congested conditions, which indicates that housing is unaffordable for a large section of population, be it ownership or rental.</p>
                <p>However, it has been known that rental housing is of particular importance to the immigrants. It has been theorized as the first entry point for a migrant in a city. Until an immigrant find a stable job and save earnings to invest in a ownership housing, rental housing can provide them with numerous options of shelters. Migrant and informal sector workers are often unable to buy houses as they are bound to change jobs frequently. Due to lack of social renting, they are compelled to live in slums. Along with the provision of affordable housing, provision of rental housing is important to prevent creation of slums. A healthy rental stock is also required to house students and working population. However, The problem is that the housing policies do not pay attention to what the rental housing needs.</p>
                <p>The current shortage of housing in India stand out to be around 18 million houses which cannot be only catered with an approach of providing housing on the basis of ownership basis. </p>
                <p>But the question rises is rental housing really helpful to beat housing shortage. The answer is yes. Countries such as Singapore dealt with a housing crisis in early 1960’s wherein they formulated the housing development board which initially focused on creation of housing stock at large number rather than quality at that too on rental basis and following the path currently 80% of Singapore population live in their own houses. There are many other success stories across the world where in rental housing has been the key driver for solving the problem of housing. Also, while there has been an overbearing emphasis on ownership of houses in our policies, countries such as Austria, Hong Kong, the Netherlands and the UK also focus on social renting programmes. In fact, the Netherlands and Hong Kong have more than 30 per cent of their total housing under social renting.</p>
                <p>Though in India there exists a large share of rental housing in the market but it stands informal and there is no regulatory body to look into it. the other issues that hinder the growth of this segment in India are the lack of a regulatory framework, a low-rental yield, lack of clarity on house repossession and an inability to raise rates according to prevalent market prices.</p>
                <p>If the country's rental housing market is regulated better, it will promote the real estate sector because the demand from investors, who are inclined to rent out the purchased property, would increase. This would also encourage developers to undertake renting-based housing projects.</p>
                <p>At present, nearly every state has its own law governing matters relating to rental housing in their jurisdiction in the name of Rent Control Laws. However, these rent control laws are not adequate to satisfy the need for rental housing in true sense. Because, issues, such as lack of affordable housing, lack of investment in rental housing etc., are still present in the country. The current rental law is archaic and it hurts the interests of lessor, as well as lessees. The Govt. has announced that several reforms have been proposed under the Model Tenancy Law, to replace the current rental law. This move is expected to boost rental housing and increase the interest of investors, who are looking for rental income. Rental housing is a critical piece for the ‘Housing for All’ objective. </p>
                <p>The growth of rental housing, can boost the segment and play a big role in achieving the vision of ‘Housing for All by 2022’. Also with this to meet the urban housing requirements, the government should consider promoting the development of an institutional rental market, by providing incentives. The proposed Model Tenancy Law is a step in the right direction. While progress on the draft guidelines is expected, the government also needs to align with changing consumption patterns, as trends like co-living are already gaining ground.</p>
                <p>
As we move towards a more flexible work environment globally, there is a need for the Indian real estate sector too to envisage itself as a service, rather than a product. As without rental housing the shortage of housing cannot be meet. Rental housing can be delivered in many forms, co-living, student housing, and senior-housing segments & developers should be incentivized by Govt. for the same to boost rental housing.
</p>
                <p>In recent times rental housing or built-to-rent housing market in India has been growing exponentially. So, having clarity on the Rental Housing Policy and the framework is the need of the hour.</p>
                <p>It has been said that the Draft Model Tenancy Act, 2019 when implemented will ensure the growth of institutional rental housing in India. The rental policy is unlikely to be formalised any time soon.  Laying down a roadmap for Rental Housing is a step in the right direction to align with global trends of co-living spaces & Student Housing, given the changing demographics in the country. </p>
            </div>
        </div>
    </div>
  )
}

export default TwentyFourth