import React from 'react'

function Sixth() {
  return (
    <div className="first__div1">
        <div className="first__div2">
            <div className="first__div3">
                <img src="https://images.unsplash.com/photo-1582407947304-fd86f028f716?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=996&q=80" className="first__img1" alt="" />
            </div>
            <div className="first__div4">
                <p><b>Indian Real Estate –Keeping up with the trends</b></p>
            </div>
            <div className="first__div5">
                <p>Over the past decade, real estate industry in India has seen a dramatic change in terms of approach, marketing strategy and Construction methods. </p>
                <p>Real Estate projects are highly priced products which cannot be sold efficiently without a well strategized marketing campaign so as to reach out to the exact targeted market. In the earlier times, majority of the marketing were through offline & very less through online media. Traditional marketing methods of bill boards, newspaper ads, radio jingles, word-of-mouth promotions, Hoardings, Leaflets, Print, SMS etc. are on their way out. </p>
                <p>In the current scenario, a generation led by millennials digital platform has become the key to market. Today, clients need to be wooed across a much wider spectrum. The organizations are competing on a global scale and newer and innovative strategies are introduced in the field of marketing to reach out to the potential buyers. Real Estate developers are now changing the rules of Marketing and creating strategies to cater to evolving consumer of the country. </p>
                <p>Today, approximately 243 million Indians spend a significant part of their lives online, and use the Internet to access and receive information of every kind with the advent of e-papers, news portals and blogs. Technology has increased and needs to be adopted; to stay ahead of the curve becomes important.</p>
                <p>You cannot ignore the power of digital marketing, especially if you are a real estate developer. And with changing dynamics of these factors are setting new levels expectations that need customised & targeted approach. With potential home owners using the Internet to research their future purchase, a strong and powerful digital marketing strategy is what will help real estate developers beat the competition. the consumer these days are well aware, well-read, want value for money and aspire a certain lifestyle. While there are seemingly countless tactics and tools to choose from, a few of them have helped the sector to be more aggressive in the market. </p>
                <p>Not to put too fine a point to it, online presence has become vital part of the marketing. Neither long-standing reputation nor excellent track record will help if these elements are not reflected online across multiple channels. Platforms like Facebook, Linkedin and Twitter may have started off as mere social networking media, but today the power they wield in the world of business is beyond dispute. unlike before now all Companies of every stripe and description are investing massively into making their presence felt on these and other online platforms. It is literally a battle to stay relevant in a world that does not acknowledge the existence of anything anymore if it cannot be found online.</p>
                <p>Increasing use of technology most of the modern consumers being tech-savvy, real estate in India has moved online. Exclusive real estate portals and convenient online interaction have ensured that property buyers go online for gathering information, choose their preferred property, and arrive at their purchase decision. Use of technology to prepare 3D drawings or Virtual tours makes it possible for anyone to examine properties and project while sitting in the comfort of their homes. And with this presence on every online media becomes important. Thus, real estate sector is now using multiple marketing permutations and combinations to enhance its methods  to reaching out to customers.</p>
                <p>Now developers are adapting several new methods for marketing. association with like-minded brands, participation at real estate forums and media platforms to Making people aware about the brand, starting off with a website and social media pages to keeping the audience engage and updated about the new and existing offers by the developer are more than essential these days. Many are now using their personal brand apps showcasing their all the projects at one platform with basic important information & also online service like property on hold or booking of property. </p>
                <p>Also marketing activities require specialized know-how and specifically trained and qualified manpower. Many developers are now incentivising their employees with sponsored foreign trips to keep them motivated & to do better work.  Many set up channel partner incentives program to  inspire people to do better work. </p>
                <p>Along with this faster and better construction techniques such as prefabrication and 3-D ensure that developers complete the construction activities under reduced time. Precise and fast construction leads to an increased number of projects being completed successfully. Real estate in India has been transforming, thanks to sophisticated approaches and techniques.</p>
                <p>Also now Private equity funds have relieved investors from the hassles of identifying the location and going through several steps to acquire a property for investment purposes. The fund manager’s expertise helps investors to make moderately safe investments in the real estate market.</p>
                <p>Thus, During Past few years indian real estate has seen several unprecedented changes and advancements in India. Coming Years of Real estate in India are expected to be tech-driven & future-looking. Staying up to date with the new trends in real estate marketing is important.</p>
            </div>
        </div>
    </div>
  )
}

export default Sixth