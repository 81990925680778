import React, { useState, useRef, useEffect } from "react";
import "../../styles/Slider.css"
import { TweenLite, Power3 } from "gsap";
import founder1 from "../../assets/Pramit.jpeg"
import founder2 from "../../assets/Monil.jpeg"
import founder3 from "../../assets/Hasmukh.jpeg"
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const testimonials = [
  {
    name: " - Mr. Hasmukh Parikh",
    title: "Founder and Chairman",
    image: founder3,
    quote:
      "Starting the company way back in 1981, my mission was to offer holistic real estate advisory services in the most ethical and process-oriented manner. My sons' hard work and commitment have firmly established Parikh Real Estate as one of the leading consultancies in Ahemdabad. "
  },
  {
    name: " - Mr. Pramit Parikh",
    title: "Director",
    image: founder1,
    quote:
      "We are passionate about results aligned with our client's success and help them with all their real estate needs. Apart from providing professional guidance and executing several deals, we help our clients identify exit opportunities for excellent financial performance. "
  },
  {
    name: " - Mr. Monil Parikh",
    title: "Director",
    image: founder2,
    quote:
      "Our extensive offerings extend beyond real estate consulting. As part of our efforts to steer our company toward more prosperous milestones, we also offer development, industrial liaison & transaction, and private equity. Our services help local and international companies find profitable real estate investments based on their business requirements and growth verticals."
  }
];

function Slider() {
  let imageList = useRef(null);
  let testimonialList = useRef(null);
  const imageWidth = 340;

  const [state, setState] = useState({
    isActive1: true,
    isActive2: false,
    isActive3: false
  });

  useEffect(() => {
    TweenLite.to(testimonialList.children[0], 0, {
      opacity: 1
    });
  }, []);

  //Image transition
  const slideLeft = (index, duration, multiplied = 1) => {
    TweenLite.to(imageList.children[index], duration, {
      x: -imageWidth * multiplied,
      ease: Power3.easeOut
    });
  };

  const slideRight = (index, duration, multiplied = 1) => {
    TweenLite.to(imageList.children[index], duration, {
      x: imageWidth * multiplied,
      ease: Power3.easeOut
    });
  };

  const scale = (index, duration) => {
    TweenLite.from(imageList.children[index], duration, {
      scale: 1.2,
      ease: Power3.easeOut
    });
  };

  //Content transition

  const fadeOut = (index, duration) => {
    TweenLite.to(testimonialList.children[index], duration, {
      opacity: 0,
      delay: 0.2
    });
  };

  const fadeIn = (index, duration) => {
    TweenLite.to(testimonialList.children[index], duration, {
      opacity: 1,
      delay: 0.2
    });
  };

  const nextSlide = () => {
    if (imageList.children[0].classList.contains("active")) {
      setState({ isActive1: false, isActive2: true });
      //Image transition
      slideLeft(0, 1);
      slideLeft(1, 1);
      scale(1, 1);
      slideLeft(2, 1);
      slideLeft(2, 0);
      fadeOut(0, 1);
      fadeIn(1, 1);
    } else if (imageList.children[1].classList.contains("active")) {
      setState({ isActive2: false, isActive3: true });
      //Image transition
      slideRight(0, 1);
      slideLeft(1, 1, 2);
      slideLeft(2, 1, 2);
      scale(2, 1);
      //content transition
      fadeOut(1, 1);
      fadeIn(2, 1);
    } else if (imageList.children[2].classList.contains("active")) {
      setState({ isActive1: true, isActive3: false });
      //Image transition
      slideLeft(2, 1, 3);
      slideLeft(0, 1, 0);
      slideLeft(1, 0, 0);
      scale(0, 1);
      //content transition
      fadeOut(2, 1);
      fadeIn(0, 1);
    }
  };

  const prevSlide = () => {
    if (imageList.children[0].classList.contains("active")) {
      setState({ isActive1: false, isActive3: true });
      //Image transition
      slideLeft(2, 0, 3);
      slideLeft(2, 1, 2);
      scale(2, 1);
      slideRight(0, 1);
      slideRight(1, 1);
      //content transtion
      fadeOut(0, 1);
      fadeIn(2, 1);
    } else if (imageList.children[1].classList.contains("active")) {
      setState({ isActive2: false, isActive1: true });
      //Image transition
      slideLeft(0, 0);
      slideRight(0, 1, 0);
      slideRight(1, 1, 0);
      slideRight(2, 1, 2);
      scale(0, 1);
      //content transtion
      fadeOut(1, 1);
      fadeIn(0, 1);
    } else if (imageList.children[2].classList.contains("active")) {
      setState({ isActive2: true, isActive3: false });
      slideLeft(2, 1);
      slideLeft(1, 0, 2);
      slideLeft(1, 1);
      scale(1, 1);
      //content transtion
      fadeOut(2, 1);
      fadeIn(1, 1);
    }
  };
  return (
    <div className="slider__div1">
      <div className="slider__div2">
        <div className="slider__divExt0">
          <div className="slider__div3" ref={el => (imageList = el)}>
            <div id="slider__div4" className={state.isActive1 ? "active" : ""}>
              <img src={testimonials[0].image} alt="" className='slider__img1' />
            </div>
            <div id="slider__div444"  className={state.isActive2 ? "active" : ""}>
              <a href="https://www.linkedin.com/in/pramit-parikh-72017197/" target="_blank" rel="noopener noreferrer"><img src={testimonials[1].image} alt="" className="slider__img1 slider_a_img" /></a>
            </div>
            <div id="slider__div404" className={state.isActive3 ? "active" : ""}>
              <a href="https://www.linkedin.com/in/monil-parikh-2638abbb/" target="_blank" rel="noopener noreferrer"><img src={testimonials[2].image} alt="" className="slider__img1 slider_a_img" /></a>
            </div>
          </div>
          <div className="slider__div5">
            <div className="slider__div6" ref={el => (testimonialList = el)}>
              <div id="slider__div7" className={state.isActive1 ? "active" : ""}>
                <div className="slider__div8">
                  <p>{testimonials[0].quote}</p>
                  <div className="slider__div9">
                    {/* <div className="slider__div10"></div> */}
                    <p>{testimonials[0].name}</p>
                    <p>&nbsp;&nbsp;{testimonials[0].title}</p>
                  </div>
                </div>
              </div>
              <div id="slider__div777" className={state.isActive2 ? "active" : ""}>
              <div className="slider__div8">
                  <p>{testimonials[1].quote}</p>
                  <div className="slider__div9">
                    {/* <div className="slider__div10"></div> */}
                    {/* <p>{testimonials[1].name} <a href="https://www.linkedin.com/in/pramit-parikh-72017197/" target="_blank" rel="noopener noreferrer"><LinkedInIcon /></a></p> */}
                    <p>{testimonials[1].name}</p>
                    <p>&nbsp;&nbsp;{testimonials[1].title}</p>
                  </div>
                </div>
              </div>
              <div id="slider__div7777" className={state.isActive3 ? "active" : ""}>
                <div className="slider__div8">
                  <p>{testimonials[2].quote}</p>
                  <div className="slider__div9">
                    {/* <div className="slider__div10"></div> */}
                    {/* <p>{testimonials[2].name} <a href="https://www.linkedin.com/in/monil-parikh-2638abbb/" target="_blank" rel="noopener noreferrer"><LinkedInIcon /></a></p> */}
                    <p>{testimonials[2].name}</p>
                    <p>&nbsp;&nbsp;{testimonials[2].title}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="slider__div11">
            <div className="slider__div12" onClick={prevSlide}>
                {/* <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle r="31" transform="matrix(-1 0 0 1 32 32)" stroke="#373737" stroke-width="2"></circle>
                    <path d="M42.1816 32.0002H21.818M21.818 32.0002L31.9998 21.8184M21.818 32.0002L31.9998 42.182" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg> */}
                <ArrowBackIcon className="arrowF" />
            </div>
            <div className="slider__div12" onClick={nextSlide}>
              {/* <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="32" cy="32" r="31" stroke="#373737" stroke-width="2"></circle>
                    <path d="M21.8184 32.0002H42.182M42.182 32.0002L32.0002 21.8184M42.182 32.0002L32.0002 42.182" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg> */}
                <ArrowForwardIcon className="arrowF" />
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Slider