import React from 'react'
import airbnb from "../../../assets/Blog/blog15.jpeg"

function Fifteenth() {
  return (
    <div className="first__div1">
        <div className="first__div2">
            <div className="first__div3">
                <img src={airbnb} className="first__img1" alt="" />
            </div>
            <div className="first__div4">
                <p><b>Force Majeure and its impact on landlords and tenants.</b></p>
            </div>
            <div className="first__div5">
                <p>We are witnessing an unprecedented situation, which not only has disrupted all our economic and organisational norms, but also has deeply affected our personal and social relationships.  lockdown and economic activities virtually came to a standstill, tenants have started waiving rents.</p>
                <p>Recently Reliance Retail & PVR Cinemas, India's largest retail & multiplex chain, has decided to inform malls and landlords that they would be invoking 'force majeure' clause in its rental for the entire period of shutdown across the country.</p>
                <p>Delhi High Court recently said in a judgement that “The Covid-19 lockdown cannot be a ground for tenants to seek rent waiver by invoking the ‘force majeure’ — act of God — clause.” Temporary non-use of premises due to the lockdown cannot be construed as rendering the lease void under TPA. The tenant cannot also avoid payment of rent.</p>
                <p>We have to first understand that legally speaking any tenant-landlord/ lessee- lessor relationship is a pure and simple contractual relationship based on the free will of the parties. This relationship is governed by the assurances given by the landlord/lessor on one hand and the tenant/ lessee on the other , which when brought down into writing, forms the Rent Agreement/Lease Deed. </p>
                <p>The fundamental principle would be that if the contract contains a clause providing for some sort of waiver or suspension of rent, only then the tenant could claim the same. However, if the tenant wishes to retain the premises and there is no clause giving any respite to the tenant, the rent or the monthly charges would be payable.</p>
                <p>Many tenants are declining rents, and while forced from landlords for payments, threatening to vacate the property knowing the situation that there won’t be any new tenants for a short period.</p>
                <p>It is understandable that due to the lockdown and no business activities many small businesses may run out of money. However, many large companies are taking advantage of situations like firing employees & declining payments. Large scale companies must be having emergency funds, which are kept to face and survive uncertain situations or natural calamities. </p>
                <p>Rents are also a major source of income for many families. There may be old ladies, aged persons as landlords. For some it is the only source of income. While many landlords invest in properties on loan, rents are used to pay EMI installments. the three-month moratorium period announced by Govt. was to give relief to borrowers during the pandemic, meaning that borrowers would not have to pay the loan EMI instalments during the moratorium period. However, interest is payable on loans during a moratorium period. Meaning more payment of interest. </p>
                <p>There are times like festive seasons when profit of business is very high, at that time landlords are not expecting high rents from tenants. Likewise at the time of business slump tenants should not be taking advantage of the situation to avoid paying rents.</p>
                <p>What we likely give less consideration to, and what has come to the forefront on news and social media, is what we can do or what we should do for others in the face of a crisis that leaves no one untouched. There are of course legal considerations, as businesses parse what newly-introduced and pre-existing laws say about how employers, employees, landlord, tenants and other relationships can either continue or sever their relationships, in the face of often catastrophic loss of income. Those are vital questions for any business to get an answer to, and ones that are hopefully resolved in a manner that causes the least amount of pain for both parties. </p>
                <p>As knowing Rental rates are high consuming a significant portion of the expenditure. Also, as per the government’s directions, business are bound to pay salaries to employees. Landlords can waive 50 % or some % of rents from his tenants to lessen this burden. Also landlords should not force tenants for one or two months to give rent and accept payments in instalments if the tenant is short on money. This will help the tenants to survive the situation, after the Covid-19 scare is over and retail trade resumes, such tenants will stick to such leases & landlords, who have shown such flexibility.</p>
                <p>More than what we do for the people in our immediate orbit, is what we own to both the business community and the community at large. This includes the willingness to bear some burden in a collective struggle; to sacrifice in the short term in the belief that our larger survival depends upon it; and to not view the weak and vulnerable as mere impediments to our goal. We understand that only through collective action can we solve this unique crisis.</p>
                <p>Stay strong through this crisis, stay safe.</p>
            </div>
        </div>
    </div>
  )
}

export default Fifteenth