import React from 'react'
import "../../../styles/First.css"
import airbnb from "../../../assets/Covid 19.jpeg"

function Fourth() {
  return (
    <div className="first__div1">
        <div className="first__div2">
            <div className="first__div3">
                <img src={airbnb} className="first__img1" alt="" />
            </div>
            <div className="first__div4">
                <p><b>Impact of Covid-19 on Indian Hospitality Industry</b></p>
            </div>
            <div className="first__div5">
                <p>The Pandemic is a challenge to both our lives and livelihoods. The crisis is unprecedented and moving quickly, yet still deeply uncertain. In line with the nationwide call to "reduce unnecessary travel", the number of tourists over has decreased sharply. nearly all the hospitality markets around have been affected badly. Hotels across the country were closed for one-three month or so with essentially zero revenues while having to bear with mounting fixed costs.</p>
                <p>The Virus had cost the Indian hospitality industry loses of around 1.55 Billion this year. the hospitality industry is going to suffer a lot and will take a lot of time to revive also. the Country’s domestic hotel that follows a fiscal year regime has gone down a lot in the month of February. in the month of January, the industry was seen doing quite well. but spread od Corona in the month of February led to number of cancellation and the sector show a sudden down. Also, the missed the tourist season summer vacation has added to the cost. </p>
                <p>The major problem with the hospitality industry in India is that the maximum employers working in these industries are from contract bases. The lockdown period led to many hotels shut down and loss of livelihood led to huge reverse migration of workers to native. </p>
                <p>Also, the last few years has seen tremendous supply growth in economy stay category, with small property owners converting their assets into guest houses, Backpackers hostels and Homestays, choosing to either operate such properties themselves as a side gig or leasing their assets to other professional operators. The demand for this was primarily driven by millennial travellers who have been smitten by the travel bug. All this type of services came to a hold for a while.</p>
                <p>However, after the travel restrictions are lifted for most domestic travel in last month. In leisure, we expect that travel to visit friends and relatives will return first, likely by car. Travel restrictions combined with economic uncertainty will likely translate into a higher share of domestic and close-to-home travel. Longer international leisure trips will be slow to return.</p>
                <p>around 88% of the Country’s hotel chains have started operating again and the occupancy of a room have also started to take place slowly. In early May, occupancy rat was less than 15 percent for luxury hotels and around 40 percent for economy. In few cases, the government has also decided the treat the patient in the isolation rooms in the hotels to lessen the burden on the hospitals. So, all these factors might lead to increase in the revenue of the hospitality sector in the near future.</p>
                <p>Looking ahead, we expect economy hotels to have the fastest return to pre-pandemic levels, and luxury and upper upscale hotels to have the slowest. That’s in part because economy hotels are better able to tap segments of demand that remain relatively healthy despite travel restrictions.  Due to lower maintain cost economy hotels can also stay open at lower occupancy rates. Whereas the many luxury hotels, on the other hand, require more than 100 employees to operate and due to high maintenance cost required high level of occupancy compare to economy hotels. </p>
                <p>Hotels face the prospect of a long recovery. Over the coming months and years, properties’ circumstances will vary based on a number of factors, including chain scale, location, and demand profile. There is no one right response for everyone, but some guidelines apply universally. Hotels must care for their employees, staying engaged with them through the pandemic and keeping them safe when they return. They must manage customer expectations, recognize that these will continue to evolve, and prepare to act agilely to address health and safety concerns. And they must revise their commercial strategy for the restart, with an eye toward the next normal. In the long term, travel will return because of an important shift in consumption—an accelerated pivot from buying things to buying experiences. It is expected that 60% of fall in Hotel Industry will recovers slightly in 2021, but it will take 2-3 years to fully recover.</p>
                <p>In the past also, the hotel industry had to deal with different crises and viruses. For example, the SARS virus impacted the industry drastically in 2003. There was a huge decline in hotel bookings & international tourist travel globally. Despite that, the travel industry managed to register immense growth by 2006. The truth is the pandemic is temporary and shall pass. Thus, we should continue to plan for the future, as well as take steps to reduce long-term damage from coronavirus and drive faster recovery. </p>
            </div>
        </div>
    </div>
  )
}

export default Fourth