import React from "react";

import { Link } from "react-router-dom";

import pyramid from "../../assets/Pyramid.png"
import serviceEnding from "../../assets/serviceEnding.jpg"
import helpCenter from "../../assets/homepage help center.jpg"

function Rent() {
  return (
    <div className="rent__div1">
      <div className="rent__div2">
        <div className="about__div3">
          <img
            src="https://images.unsplash.com/photo-1596796679119-7cf1a9e5448b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
            className="buy__img1"
            alt=""
          />
          <div className="buy__div04">
            <p>Residential Services</p>
            <p>
            Delivering Best-in-Class Residential Property Solutions
            </p>
            {/* <div className="buy__div3">
              <p>Search property</p>
              <div className="buy__div4"></div>
            </div> */}
          </div>
        </div>
        <div className="res__div3">
          <div className="res__div4">
            <div className="res__div5">
              <div className="res__div6">
                <p>Residential Property Solutions</p>
                <p>
                We provide our clients with solutions to their transactional obstacles by leveraging our market data and real estate competence, ensuring execution and improving return profiles. 
                </p>
              </div>
              <div className="res__div7">
                <img
                  src={pyramid}
                  className="res__img1"
                  id="commercial_pyramid"
                  alt=""
                />
              </div>
            </div>
            <div className="sub_services">
              <div className="sub_service_div">
              <div className="serviceBox">
                <img src="https://images.unsplash.com/photo-1528402671825-9a525ab8b5b9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80" alt="" />
                <h1>Property Marketing</h1>
                <p>We examine your property from the viewpoint of a buyer to gain the best perspective. Our team creates compelling marketing strategies that employ the latest digital tactics to generate demand and help clients sell or lease their property at the best prices.
                </p>
              </div>
              <div className="serviceBox">
                <img src="https://images.unsplash.com/photo-1573132223210-d65883b944aa?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1794&q=80" alt="" />
                <h1>Exclusive Project Marketing </h1>
                <p>We provide our clients and investors with the opportunity to participate in a project or product at an early stage, which will lead to high-profit margins in the future. You can rely on us for everything—from selling new projects on a mandate basis in the concept stage to handling the complete marketing and sales process. 
                </p>
              </div>
              <div className="serviceBox">
                <img src="https://images.unsplash.com/photo-1478860409698-8707f313ee8b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80" alt="" />
                <h1>Location Selection</h1>
                <p>Count on Parikh Real Estate to provide the broadest range of residential property options. Whether you're looking to buy residential real estate and lease it—we have the best locations for you. Our strategic team recommends the best properties having a remarkable future value while taking into account your budget and needs.
                </p>
              </div>
              </div>
              <div className="sub_service_div">
              <div className="serviceBox">
                <img src="https://images.unsplash.com/photo-1512486046-0d05000458ef?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80" alt="" />
                <h1>Budget Costing Advice</h1>
                <p>Real estate expenses are as varied as properties and their locations. Our team understands every part of establishing and managing a residential real estate space. Hence, our consultations are better informed to enable smart decisions.
                </p>
              </div>
              <div className="serviceBox">
                <img src="https://images.unsplash.com/photo-1599707254554-027aeb4deacd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80" alt="" />
                <h1>Competition Benchmarking</h1>
                <p>Our competitive benchmarking services use real-time data and dig deep into performance discrepancies to highlight areas that need improvement.
                </p>
              </div>
              </div>
            </div>
          </div>
        </div>
        <div className="res__div9">
          <div className="about__div12 residential_div12">
            <div className="about__div13">
              <div className="about__div14">
                <div className="res__div015">
                  <p>
                  Your Ideal Partner For <span>Residential Investment Strategy</span>
                  </p>
                </div>
                <div className="about__div16">
                  <p>
                  Parikh Real Estate is reshaping the real estate industry by developing lucrative opportunities, extraordinary spaces, and sustainable real estate solutions for customers, residents, and communities. We are well-known for our residential real estate advisory services in Ahemdabad and the whole of India, owing to our focus on growth, quality operations, financial stability, premium brand, collaborative culture, and high ethical standards.
                  </p>
                </div>
                <div className="about__div17">
                  <p>
                  Our bespoke residential services support landowners, investors, developers, and people looking to buy real estate in their most important transactions. We create private rental, purchase, and sale plans for every approach using in-depth portfolio risk analysis.
                  </p>
                </div>
                {/* <div className="about__div18">
                  <p>Learn More</p>
                  <div className="about__div19"></div>
                </div> */}
              </div>
              <div className="about__div20">
                <img src={serviceEnding} alt="" className="serviceImg"/>
                {/* <video
                  className="about__img3"
                  playsinline=""
                  autoplay=""
                  loop=""
                  muted=""
                  src=""
                  poster="https://victorstone.co.uk/wp-content/uploads/2022/09/residential-page-prime-property-finding-service-image.jpg"
                ></video> */}
              </div>
            </div>
          </div>
        </div>
        <div className="res__div10">
          <div className="about__div40" style={{marginTop: "5vw"}}>
          <div className="home__div34">
          <div className="home__div35" id="ser__div35">
            <div className="home__div36">
              <img
                src="https://victorstone.co.uk/wp-content/themes/victorstone/src/assets/images/featured-pattern.svg"
                className="home__img7"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="home__div37">
          <div className="home__div38">
          <div className="home__div39">
              <div className="home__div40">
                <div className="home__div41">
                  <Link
                    to="/Our-Services/commercial"
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    <p>Commercial Services</p>
                  </Link>
                  <p>Feasibility Studies</p>
                  <p>Market Dynamics</p>
                  <p>Premises Acquisitions & Disposal</p>
                  <p>Buy v/s Lease Solutions</p>
                  <p>Leasing</p>
                  <p>BTS Solutions</p>
                  <div className="home__div42"></div>
                </div>
                <div className="home__div41">
                  <Link
                      to="/Our-Services/residential"
                      style={{ textDecoration: "none", color: "#fff" }}
                    >
                    <p>Residential Services</p>
                  </Link>
                  <p>Property Marketing</p>
                  <p>Exclusive Project Marketing</p>
                  <p>Location Selection</p>
                  <p>Budget Costing Advice</p>
                  <p>Competition Benchmarking</p>
                  <div className="home__div42"></div>
                </div>
              </div>
              <div className="home__div40">
                <div className="home__div41">
                <Link
                      to="/Our-Services/key-industrial"
                      style={{ textDecoration: "none", color: "#fff" }}
                    >
                  <p>Industrial Services</p>
                  </Link>
                  <p>Land Acquisition & Conversion</p>
                  <p>63 AA/ 65KH </p>
                  <p>Built – To – Suit (B.T.S) Solution</p>
                  <p>Feasibility Studies</p>
                  <p>Buy v/s Lease Solutions</p>
                  <p>Consent to Establish </p>
                  <div className="home__div42"></div>
                </div>
                <div className="home__div41">
                <Link
                      to="/Our-Services/capital-market"
                      style={{ textDecoration: "none", color: "#fff" }}
                    >
                  <p>Capital Markets</p>
                  </Link>
                  <p>Investment Strategy</p>
                  <p>Private Equity</p>
                  <p>Fundraising For Developers</p>
                  <p>Developer Land</p>
                  <p>Joint Venture</p>
                  <p>Joint Development</p>
                  <div className="home__div42"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="home__div43">
            <div className="home__div44">
              <div className="home__div45">
                <p>Need help with something else?</p>
                <Link
                to="/contact"
                style={{ textDecoration: "none", color: "#fff" }}
              >
                <div className="home__div46">
                  <p>Contact Us</p>
                  <svg
                    className="home__svg3"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="16"
                      cy="16"
                      r="15"
                      transform="rotate(-90 16 16)"
                      stroke="white"
                      stroke-opacity="0.3"
                      stroke-width="2"
                    ></circle>
                    <path
                      d="M12 15.8333L20 15.8333M20 15.8333L16 11.6667M20 15.8333L16 20"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>
                </Link>
              </div>
            </div>
            <img
              src={helpCenter}
              className="home__img8"
              alt=""
            />
          </div>
        </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Rent;
