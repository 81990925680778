import React from 'react'
import airbnb from "../../../assets/Blog/blog8.png"

function Eight() {
  return (
    <div className="first__div1">
        <div className="first__div2">
            <div className="first__div3">
                <img src={airbnb} className="first__img1" alt="" />
            </div>
            <div className="first__div4">
                <p><b>Your next site visit, just a few clicks away</b></p>
            </div>
            <div className="first__div5">
                <p>Gone are the days when you could just hop in your car and drive from one to another property showing. Today's buying experience has gone completely virtual. the COVID-19 lockdown has accelerated technology-led home buying in India, making it possible to inspect properties online as well as negotiate and finalise deals. but you'll have to be comfortable with doing it without the in-person visits. Virtual site visits are also becoming a reality and a large chunk of the property selection and purchase process can now be done digitally, where you can check the property by 3D walkthroughs and interactive floor plans.</p>
                <p>If you’re buying a home while the COVID-19 pandemic rages on, you can expect for tech to play a big role. Depending on where you’re buying a house, there’s a chance you won’t even sit down at a closing table. You might sign your documents digitally, have a video chat with a digital notary, and finalize your entire transaction entirely online.  Though not all lenders offer e-closings, many are quickly adopting solutions that enable them.</p>
                <p>A survey found new trends in the real estate market due to COVID-19 such as a big shift towards the adoption of online portals, with 75 per cent of people, for whom offline was the first and only major source of property search earlier, saying that they now prefer opting for online real estate portals to search their dream homes. There has also been a demand for virtual tours or visits wherein 60 percent of home-buyers stated that they would prefer to do virtual tours either to shortlist or to finalise their homes.</p>
                <p>Though the Indian real estate market is reeling under the COVID-19 impact, it can be a very positive time for home buyers as they are at an unprecedented advantage to negotiate good deals on ready-to-move-in options. Homebuyers are also likely to benefit from all-time-low interest rates of 7.15 to 7.8 per cent on home loans.</p>
                <p>Staring at an inventory pile-up, Cost overruns and sales down New Project launches are on hold. Majority of the developers are focusing on the sale of existing properties. For properties already on the market it is possible to advertise for sale, but, again, the government’s guidance to enforce social distancing, developers making exclusive arrangements with prop-tech platforms to push sales at the time of the lockdown. </p>
                <p>And with this Many developers country wide are offering refundable booking amounts, creating cashback schemes, promising gifts on booking to push sales. Like, </p>
                <ul>
                    <li>Pay 1 lakh and book any residential or commercial unit by. The balance booking amount can be paid over the next 100 days. The payment can be made via online and the entire amount will be refunded if the booking is cancelled within 90 days.</li>
                    <li>Others are providing a cashback up to 5% of the property value to all those who book units during the lockdown period.</li>
                    <li>Another charges just Rs 51,000 to book a flat. Also, the first three EMIs after the loan disbursement will be borne by the developer.</li>
                </ul>
                <p>For instance, Shivalik Developers a known builder in Ahmedabad has come up with special plan called “Subvention plan” for a specific project. As per the plan the buyers has to pay just Rs.25,000 as a booking amount, balance booking amount to be paid after the lockdown end and the rest at the time of possession. </p>
                <p>Also with this kind of offers availability of site vicinity details & expected the passion date on website. And sharing other buyers profile with the new expected buyer can be helpful. And For those who were planning to buy a property before this pandemic, it is advised to revisit your budget. Check whether your investments that you were planning to redeem for down payment are intact or if they have suffered a setback. And last but not the least always Check developers status to ensure property delivery in the time of uncertainty. </p>
            </div>
        </div>
    </div>
  )
}

export default Eight