import React from 'react'
import airbnb from "../../../assets/Blog/blog22.jpeg"

function TwentySecond() {
  return (
    <div className="first__div1">
        <div className="first__div2">
            <div className="first__div3">
                <img src={airbnb} className="first__img1" alt="" />
            </div>
            <div className="first__div4">
                <p><b>Pros & Cons of self-redevelopment</b></p>
            </div>
            <div className="first__div5">
                <p>Self-redevelopment looks a little different. It doesn’t mean waiting for a third-party developer to come calling, making an offer, sitting through endless rounds of negotiation and acceding to the additional floors the developer will construct for returns on his investment.</p>
                <p>Self-redevelopment is the process wherein the society members assume the mantle of the redevelopment project. They can avoid delays, ensure the project plan and designs meet their needs and control the allocation of funds. This method of redevelopment not only helps in mitigating risk but also benefits the members by giving them more control over the project. </p>
                <p>But before jumping to the project as a subsequent step, it is recommended that the society gets project feasibility made by calculating the development potential, the detailed project costs, Viable development options  a part-commercial or a  part-residential plan and the possible benefits for members, while getting the documents ready for starting the process of self-redevelopment. </p>
                <p>Bank gives the loan on the mortgage of the society’s land and lien on proposed sale flats. Society or its members do not have to give any collateral security other than these mentioned above. There will be no mortgage on the flats of any existing members. There will be no personal guarantee of any individual member.</p>
                <p>The profit earned by selling excess inventories (whether its commercial or residential)  is usually distributed among the society members. The money from the saleable component will repay the loan amount.  The Society members also can generate secondary source of income by leasing out the extra inventories. The profit also can be used to pay society maintenance charges and then remaining can be distributed among society members so that they don’t have to pay it distinctly. </p>
                <p>One issue with self-redevelopment is that not all the people who are making decisions have got expertise in the field of real-estate. You need to have a fair idea on how to use the funds effectively, how to divide the profits earned and which architect to hire. It is also not easy to take care of all this with one’s full-time job. To run around to do the paperwork, look for plumbing contractors or finding a house to rent temporarily.</p>
                <p>Self-redevelopment puts Society members in control of deciding what they want and how they want it. Process is all done in-house, overseen by the society. It’s easier to do that than before. In the last two years, more banks have started to provide easy loans for self-redevelopment. The residents exercise greater say in how the structure will look and operate and even choose the architect that fits their vision and budget. Also the construction quality can be ensured by members. This is different from the builder-developer who often have different quality standards for rehab and saleable components.</p>
                <p>This do-it-yourself style of redevelopment, despite being the oldest and most beneficial, is considered the most tedious. The society must make themselves fully aware of the processes and create approaches and options safeguarding their interests towards uncertainty in the norms and the project lifecycle, ensuring a smooth journey through the project. The society should be ready to battle the possible challenges of legal documentation, of the liaisoning network, of raising initial funds, etc., and get the right professionals, who can support them through each of these challenges.   </p>
                <p>However, an even more exciting service being offered these days is self-redevelopment funding and management. Firms that offer such services to housing societies are becoming very relevant in cities. Rather than involving a developer to carry out the redevelopment, housing societies can outsource the entire task to expert consultancies to both finance and manage the redevelopment process according to exact needs and specifications.</p>
                <p>Considering the various benefits such services bring to the table, a transparent and consolidated management fee - as opposed to the often-gargantuan cost and time overruns of an unplanned or mercenary approach to redevelopment - is indeed a price worth paying.  The industry looks forward to more and more such specialized players coming to the fore to help increase competitiveness in pricing, and overall efficiency.</p>
            </div>
        </div>
    </div>
  )
}

export default TwentySecond