import React from "react";
import "../../styles/Resendial.css";

import { Link } from "react-router-dom";

import pyramid from "../../assets/Pyramid.png"
import service1 from "../../assets/service-1.jpg"
import service2 from "../../assets/service-2.jpg"
import service3 from "../../assets/service-3.jpg"
import service4 from "../../assets/service-4.jpg"
import serviceEnding from "../../assets/serviceEnding.jpg"
import helpCenter from "../../assets/homepage help center.jpg"

import case2 from "../../assets/case2.jpg"
import case3 from "../../assets/case3.jpg"

function Resendial() {
  return (
    <div className="res__div1">
      <div className="res__div2">
        <div className="about__div3">
          <img
            src="https://643113.smushcdn.com/2786300/wp-content/uploads/2022/06/residential-listing-overall-banner.jpg?lossy=0&strip=0&webp=1"
            className="about__img1"
            alt=""
          />
          <div className="about__div4">
            <p>Our Services</p>
            <p>
            Delivering Value With A Constant Approach.
            </p>
          </div>
        </div>
        <div className="res__div3">
          <div className="res__div4">
            <div className="res__div5">
              <div className="res__div6">
                <p>Discover Our Wide Range Of Services</p>
                <p>
                We are industry leaders in providing an extensive array of specialized services, including appraisal, planning, and property management, as well as financial and investment advice.
                </p>
              </div>
              <div className="res__div7">
                <img
                  src={pyramid}
                  className="res__img1"
                  alt=""
                />
              </div>
            </div>
            <div className="res__div8">
              <div className="res__div030">
                <div className="about__div31">
                  <div className="about__div32">
                    <div>
                      <div className="about__div33">
                        <img
                          src={service1}
                          className="about__img11"
                          alt=""
                        />
                        <div className="about__div34">
                          <p>Commercial Service</p>
                          <span>Commercial Property Consultancy</span>
                          <div className="serviceList">
                            <ul>
                              <li>Feasibility Studies</li>
                              <li>Market Dynamics</li>
                              <li>Premises Acquisitions & Disposal</li>
                              <li>Buy v/s Lease Solutions</li>
                              <li>Leasing</li>
                              <li>BTS Solutions</li>
                            </ul>
                          </div>
                          <a
                  style={{ textDecoration: "none", color: "#fff" }}
                  href="/Our-Services/commercial"
                >
                          <div className="about__div35">
                            <p>Discover</p>
                            <svg
                              className="about__svg1"
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="16"
                                cy="16"
                                r="15"
                                transform="rotate(-90 16 16)"
                                stroke="white"
                                stroke-opacity="0.3"
                                stroke-width="2"
                              ></circle>
                              <path
                                d="M12 15.8333L20 15.8333M20 15.8333L16 11.6667M20 15.8333L16 20"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </svg>
                          </div>
                          </a>
                        </div>
                      </div>
                      <div className="about__div33" style={{marginTop: "5vw"}}>
                        <img
                          src={service2}
                          className="about__img11"
                          alt=""
                        />
                        <div className="about__div34">
                          <p>Residential Service</p>
                          <span>Residential Property Solutions</span>
                          <div className="serviceList">
                            <ul>
                              <li>Property Marketing</li>
                              <li>Exclusive Project Marketing</li>
                              <li>Location Selection</li>
                              <li>Budget Costing Advice</li>
                              <li>Competition Benchmarking</li>
                            </ul>
                          </div>
                          <a
                  style={{ textDecoration: "none", color: "#fff" }}
                  href="/Our-Services/residential"
                >
                          <div className="about__div35">
                            <p>Discover</p>
                            <svg
                              className="about__svg1"
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="16"
                                cy="16"
                                r="15"
                                transform="rotate(-90 16 16)"
                                stroke="white"
                                stroke-opacity="0.3"
                                stroke-width="2"
                              ></circle>
                              <path
                                d="M12 15.8333L20 15.8333M20 15.8333L16 11.6667M20 15.8333L16 20"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </svg>
                          </div>
                          </a>
                        </div>
                      </div>
                      </div>
                    <div>
                      <div className="about__div33">
                        <img
                          src={service3}
                          className="about__img11"
                          alt=""
                        />
                        <div className="about__div34">
                          <p>Industrial Service</p>
                          <span>Industrial And Warehouse Services</span>
                          <div className="serviceList">
                            <ul>
                              <li>Land Acquisition & Conversion</li>
                              <li>63 AA/ 65KH</li>
                              <li>Built – To – Suit (B.T.S) Solution</li>
                              <li>Feasibility Studies</li>
                              <li>Buy v/s Lease Solutions</li>
                              <li>Consent to Establish</li>
                            </ul>
                          </div>
                          <a
                            style={{ textDecoration: "none", color: "#fff" }}
                            href="/Our-Services/key-industrial"
                          >
                          <div className="about__div35">
                            <p>Discover</p>
                            <svg
                              className="about__svg1"
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="16"
                                cy="16"
                                r="15"
                                transform="rotate(-90 16 16)"
                                stroke="white"
                                stroke-opacity="0.3"
                                stroke-width="2"
                              ></circle>
                              <path
                                d="M12 15.8333L20 15.8333M20 15.8333L16 11.6667M20 15.8333L16 20"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </svg>
                          </div>
                          </a>
                        </div>
                      </div>
                      <div className="about__div33" style={{marginTop: "5vw"}}>
                        <img
                          src="https://images.unsplash.com/photo-1628348068343-c6a848d2b6dd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
                          className="about__img11"
                          alt=""
                        />
                        <div className="about__div34">
                          <p>Capital Market</p>
                          <span>Capital Markets Advisory</span>
                          <div className="serviceList">
                            <ul>
                              <li>Investment Strategy</li>
                              <li>Private Equity</li>
                              <li>Fundraising For Developers</li>
                              <li>Developer Land</li>
                              <li>Joint Venture</li>
                              <li>Joint Development</li>
                            </ul>
                          </div>
                          <a
                            style={{ textDecoration: "none", color: "#fff" }}
                            href="/Our-Services/capital-market"
                          >
                            <div className="about__div35">
                              <p>Discover</p>
                              <svg
                                className="about__svg1"
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="16"
                                  cy="16"
                                  r="15"
                                  transform="rotate(-90 16 16)"
                                  stroke="white"
                                  stroke-opacity="0.3"
                                  stroke-width="2"
                                ></circle>
                                <path
                                  d="M12 15.8333L20 15.8333M20 15.8333L16 11.6667M20 15.8333L16 20"
                                  stroke="white"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                              </svg>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="case_study">
            <h1>Case Study</h1>
            <div className="case_studies">
              <div className="case">
                <img src={case2} alt="" id="caseImg1" />
                <div className="case_content">
                  <h2>Godrej Garden City</h2>
                  <p>Structuring  and finalizing a joint development structure for Godrej group with the landlord paving way for Godrej group foray in Ahmedabad. Spreading Over  acres having potential development of 15000 apartments/ Villas/ row houses with high end amenities & commercial space.</p>
                </div>
              </div>
              <div className="case">
                <img src="https://images.unsplash.com/photo-1610802128093-b70685bd8123?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80" alt="" id="caseImg2" />
                <div className="case_content">
                  <h2>Commercial Project (Name Confidential)</h2>
                  <p>Developing a limited-edition housing project in the city from the stage of identifying a suitable land parcel, associating with the right developer willing to develop a niche project, laying out robust marketing strategy for sales and being instrumental in selling over  65% units within prelaunch stage.</p>
                </div>
              </div>
              <div className="case">
                <img src={case3} alt="" id="caseImg3" />
                <div className="case_content">
                  <h2>Industrial Project (Name Confidential)</h2>
                  <p>Helped developer Successfully close BTS transaction with one of the largest wind turbine manufacturers in Gallops industrial Park, Changodar.</p>
                </div>
              </div>
            </div>
        </div>
        <div className="res__div9">
          <div className="about__div12">
            <div className="about__div13">
              <div className="about__div14">
                <div className="res__div015">
                  <p>
                    Futuristic Real Estate<span> Insights and  Solutions</span>
                  </p>
                </div>
                <div className="about__div16">
                  <p>
                  The current business scenario is changing. Your real estate portfolio strategy should be shifting along with it. For your portfolio to be future-ready, we track, measure, and analyze how your space is used. We learn about your business model so that we can forecast your growth trends and take into account everything from capital expenses and operational consequences to time and schedule constraints. 
                  </p>
                </div>
                <div className="about__div17">
                  <p>
                  Parikh Real Estate is a top provider of services and investments in commercial real estate. We develop solutions for clients of every size, in every industry, across every area, and with the help of services, industry insights, and data.
                  </p>
                </div>
                {/* <div className="about__div18">
                  <p>Learn More</p>
                  <div className="about__div19"></div>
                </div> */}
              </div>
              <div className="about__div20">
                <img src={serviceEnding} alt="" className="serviceImg"/>
                {/* <video
                  className="about__img3"
                  playsinline=""
                  autoplay=""
                  loop=""
                  muted=""
                  src=""
                  poster="https://victorstone.co.uk/wp-content/uploads/2022/09/residential-page-prime-property-finding-service-image.jpg"
                ></video> */}
              </div>
            </div>
          </div>
        </div>
        <div className="res__div10">
          <div className="about__div40" style={{marginTop: "5vw"}}>
          <div className="home__div34">
          <div className="home__div35" id="ser__div35">
            <div className="home__div36">
              <img
                src="https://victorstone.co.uk/wp-content/themes/victorstone/src/assets/images/featured-pattern.svg"
                className="home__img7"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="home__div37">
          <div className="home__div38">
          <div className="home__div39">
              <div className="home__div40">
                <div className="home__div41">
                  <Link
                    to="/Our-Services/commercial"
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    <p>Commercial Services</p>
                  </Link>
                  <p>Feasibility Studies</p>
                  <p>Market Dynamics</p>
                  <p>Premises Acquisitions & Disposal</p>
                  <p>Buy v/s Lease Solutions</p>
                  <p>Leasing</p>
                  <p>BTS Solutions</p>
                  <div className="home__div42"></div>
                </div>
                <div className="home__div41">
                  <Link
                      to="/Our-Services/residential"
                      style={{ textDecoration: "none", color: "#fff" }}
                    >
                    <p>Residential Services</p>
                  </Link>
                  <p>Property Marketing</p>
                  <p>Exclusive Project Marketing</p>
                  <p>Location Selection</p>
                  <p>Budget Costing Advice</p>
                  <p>Competition Benchmarking</p>
                  <div className="home__div42"></div>
                </div>
              </div>
              <div className="home__div40">
                <div className="home__div41">
                <Link
                      to="/Our-Services/key-industrial"
                      style={{ textDecoration: "none", color: "#fff" }}
                    >
                  <p>Industrial Services</p>
                  </Link>
                  <p>Land Acquisition & Conversion</p>
                  <p>63 AA/ 65KH </p>
                  <p>Built – To – Suit (B.T.S) Solution</p>
                  <p>Feasibility Studies</p>
                  <p>Buy v/s Lease Solutions</p>
                  <p>Consent to Establish </p>
                  <div className="home__div42"></div>
                </div>
                <div className="home__div41">
                <Link
                      to="/Our-Services/capital-market"
                      style={{ textDecoration: "none", color: "#fff" }}
                    >
                  <p>Capital Markets</p>
                  </Link>
                  <p>Investment Strategy</p>
                  <p>Private Equity</p>
                  <p>Fundraising For Developers</p>
                  <p>Developer Land</p>
                  <p>Joint Venture</p>
                  <p>Joint Development</p>
                  <div className="home__div42"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="home__div43">
            <div className="home__div44">
              <div className="home__div45">
                <p>Need help with something else?</p>
                <Link
                to="/contact"
                style={{ textDecoration: "none", color: "#fff" }}
              >
                <div className="home__div46">
                  <p>Contact Us</p>
                  <svg
                    className="home__svg3"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="16"
                      cy="16"
                      r="15"
                      transform="rotate(-90 16 16)"
                      stroke="white"
                      stroke-opacity="0.3"
                      stroke-width="2"
                    ></circle>
                    <path
                      d="M12 15.8333L20 15.8333M20 15.8333L16 11.6667M20 15.8333L16 20"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>
                </Link>
              </div>
            </div>
            <img
              src={helpCenter}
              className="home__img8"
              alt=""
            />
          </div>
        </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Resendial;
