import React from 'react'

function Fifth() {
  return (
    <div className="first__div1">
        <div className="first__div2">
            <div className="first__div3">
                <img src="https://images.pexels.com/photos/7054415/pexels-photo-7054415.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className="first__img1" alt="" />
            </div>
            <div className="first__div4">
                <p><b>Budget 2020-21 for Real Estate Sector India</b></p>
            </div>
            <div className="first__div5">
                <p>With the economy in midst of a sharp slowdown, the General Budget for 2020-21 was being awaited with high expectations to act as a growth booster. India's real estate sector has been witnessing a prolonged period of weak sales, resulting in a sharp decline in its contribution to the country's GDP. Not just the real estate sector but a large number of other industries indirectly related to the sector have also faced the jolt of low demand. but, the budget fell short of industry expectations, with no major announcement for accelerating growth. </p>
                <p>The major announcement to come out of Budget 2020-21 is the tax relief to individuals. Introduction of the new tax slabs to ensure more disposable income in the hands of the middle class. With the idea that with additional savings, individual investments in housing would increase and it will lead to reviving the consumption cycle in the realty sector and turn over the economy. However, Lowering of income tax rates with the removal of, may not lead to any meaningful boost to consumption. As far as the real estate sector is concerned, the industry was hoping that the Government would come up with measures to boost housing demand. However, the removal of exemptions under the new income tax regime, implying no tax benefit on principal and interest for home loans would be a dampener for the sector. </p>
                <p>Also, In line with the government’s initiative “Housing for All” and affordable housing, the extension of benefit for affordable housing for the developers as well as home buyers by one year is a step in the right direction. the said extensions announced are likely to impact the demand and supply for affordable houses positively. But the demand for other residential segments in real estate would, however, continue to remain sluggish with no major incentives being offered.</p>
                <p>The budget has been disappointing on the much-needed stimulus needed for real estate. more developer and investor-friendly initiatives were hoped for the betterment of the real estate market. Some long time expected aspects that the government missed out into consideration in the budget are granting industry status to the overall real estate sector so that it enable developers to cut capital costs and pass on the benefits to consumers, implementation of single window clearance to make the project implementation faster, lower interest rates of housing loans to boost demand and the abolition of stamp duty or its incorporation under GST to decrease the tax burden from consumers.</p>
                <p>The budget also missed out on making any major announcement for easing liquidity in the real estate sector which is a major worry for most developers. the government spoke about enhancing the partial credit guarantee scheme for NBFCs, which again may not suffice for the ailing real estate sector. There were no demand boosters or one-time restructuring of loans for realtors.</p>
                <p>Also, considering the current growth trends like Co-living taking grounds, Incentive to rental housing market was expected. To meet the urban housing requirements, the government could have considered promoting the development of an institutional rental market by providing incentives. Policies aimed at the growth of rental housing, could have boost the segment and play a big role in filling the housing shortage. However like previous budgets this budget also missed out the rental housing segment.</p>
                <p>On the other hand, this budget has indicates the government’s intentions towards bettering infrastructure. Several schemes were announced and huge funds have been allocated for industry and commerce.  The National Infrastructure Pipeline includes 6,500 projects across the country and finance minister has also announced the allocation of Rs 27,300 crores for industry and commerce in FY 2020-21. Along with this, plans for developing strategic national highways have also been announced, which are expected to bring developmental changes in the real estate sector as well.</p>
                <p>Having said this, budget has been big on motives but small on actions. Budget FY2020-21 will have a neutral impact on the sector this year. So let us wait for the year to unfold in which positive actions are taken by the government to benefit the Real Estate sector as a whole.</p>
            </div>
        </div>
    </div>
  )
}

export default Fifth