import React from 'react'
import airbnb from "../../../assets/Blog/blog18-19.jpeg"

function Eighteenth() {
  return (
    <div className="first__div1">
        <div className="first__div2">
            <div className="first__div3">
                <img src={airbnb} className="first__img1" alt="" />
            </div>
            <div className="first__div4">
                <p><b>How COVID-19 will impact real estate industry in India</b></p>
            </div>
            <div className="first__div5">
                <p>Usually, the festive season are considered to be auspicious occasions for launching new projects or buying new properties, but this year it’s not the same. Even as the Indian economy strives to recover from a slowdown, the Coronavirus outbreak has further hit the possibility of revival given the negative impact on the various sectors of the economy. Stocks have tumbled in recent days as the virus spreads to other countries, the spread of the virus has triggered panic across the world and shaken the confidence of investors. </p>
                <p>Malls & theaters from functioning has been shut down. many companies have directed their employees to work from home. And There is tremendous uncertainty regarding how broadly the virus will spread and what will be its impact on public health, economic & financial growth and real estate markets. The real economic impact will be seen over the next 2-3  months. There will be some economic impact and we have to be ready for that.</p>
                <p>The real estate market is headed for a chill as anxiety rises over the economic fallout of the coronavirus. Coronavirus is everywhere.  A call for social distancing means far fewer people will be opening up their homes.  Its impacts on the property market will be everywhere too. For now it can be said that India has been less affected. However real estate market can be affected as impact on economy which could result in delayed decision making, curtailed capital expenditures and thereby slowing down of portfolio decisions.  There Could be a drop off in new project launches & transactions. As Public Gatherings are getting cancelled, use of digital media for marketing of current on-going projects will increase. There will be negative effects on employment. It will be a short, sharp shock to the economy.If the situation continues many are expected to loose their job, Majority of them are construction labors, Service Providers & Working Class.</p>
                <p>Most of the home buyers belong to the working class category and since many prospect buyers will not be too certain about their jobs, it will impact their buying decisions as they may not be able to decide to take the responsibility of an added liability and the burden of EMIs after buying a new property. For a lot of working class people with wealth tied up in the share market, their wealth has been diminished. So capacity for many people to use that wealth to buy into the housing market has been reduced. it will impact all the housing segments in the real estate industry. </p>
                <p>Production and economic activity will slow because people will likely stay indoors, lowering consumer spending. Default rates in Commercial Real Estate loans would increase, and production and development would decline. A number of companies and Commercial Real Estate investors are trying to plan ahead by taking increased measures to reduce the spread of the virus, especially in properties that house or employ multiple people. MNCs Companies are also planning to prevent the spread of the virus by asking employees to work from home.  </p>
                <p>We expect to see a drop in sales but this will take a month or two to filter through into the actual results. We are seeing buyers move to the sidelines and sellers put some of their listing plans on hold. Basically everything is frozen. But that doesn’t mean the end result will be more affordable homes. Property prices can decline. It is advisable for seller to put on hold. As there will be very less offers. Thought there will still be people who need to sell for any reasons. The turnover will decline but there will still be properties coming into the market.</p>
                <p>The market has been getting more difficult for the investor. investors can benefit somewhat from the decline in rates but that benefit is offset by declining rents. The investors need to be conscious of. If prices come down, investors could be in a better position to buy but that weakness in rents is a real factor – it has been for some time and is unlikely to go away any time soon. As The financial capacity of small and medium-sized business will be harshly affected. Many retail stores & restaurants will be closed as nobody will go, and to cost cutting can lead to reduce in staff. Things will eventually bounce back. Things will go back to normal eventually but there will be some business casualties along the way.</p>
                <p>The government may soon announce measures to help industry limit Coronavirus hit. Unfortunately, only time will tell how the coronavirus will impact the real estate market. There is no doubt the coronavirus will impact the economy and real estate market in some form, but no one truly knows to what extent. the long-term impact remains unknown as the longevity of the crisis is uncertain. </p>
            </div>
        </div>
    </div>
  )
}

export default Eighteenth