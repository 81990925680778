import React from 'react'

function PopUp(props) {
  return(props.trigger) ? (
    <div>
        {/* <div className="close" onClick={()=> props.setTrigger(false)}>X</div> */}
        { props.children }
    </div>
  ) : "";
}

export default PopUp