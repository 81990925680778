import React from 'react'
import airbnb from "../../../assets/Blog/blog12.jpeg"

function Tweleth() {
  return (
    <div className="first__div1">
        <div className="first__div2">
            <div className="first__div3">
                <img src={airbnb} className="first__img1" alt="" />
            </div>
            <div className="first__div4">
                <p><b>Still confused? Ask us away.</b></p>
            </div>
            <div className="first__div5">
                <p>I Have received a lot of queries over a past few weeks. So, in this article I would like to answer few.</p>
                <p><strong>How Many Properties an NRI Can Purchase in India, also what are the payment options for property Purchase? </strong></p>
                <p>The falling rate of the rupee increases the interest among the NRIs to buy residential property. The time to invest in the real estate properties in a post-pandemic world has never been better. The size of NRI investor market is increasing in the affordable and mid-segment housing category. The rules and regulations are a bit complicated compared to resident Indian. Here I will try to explain it in simpler way possible.</p>
                <p>Reserve Bank has granted general permission to foreign citizens of Indian origin, whether resident in India or abroad, to purchase immovable property other than agricultural land/farm house/plantation property, in India. The regulation don’t put any restrictions on the number of commercial/residential properties that can be bought. Therefore, NRI's do not require any permission to acquire any immovable property in India other than agricultural / plantation property or a farm house.</p>
                <p>But citizens of 11 countries—Pakistan, Bangladesh, Sri Lanka, Afghanistan, China, Iran, Nepal, Bhutan, Macau, Hong Kong and Democratic People’s Republic of Korea (DPRK)—cannot acquire or transfer immovable property in India, irrespective of their residential status, without prior permission from RBI.</p>
                <p>The payment for the property purchase can be made only from NRE/NRO/FCNR (B) account or any inward remittance through regular banking channels. However, if you are buying a property from Indian Income or Savings then you can make payment through NRO account. On the other hand, if the property is acquired from foreign income through inward remittance then the payment can be made through NRE or FCNR (B) account. A payment for property transaction in India cannot be made outside India.</p>
                <p>However, NRI can only remit sale yields of 2 house properties from India in a Single name. Also, In case of disputes, NRIs can seek relief from various Indian courts, just like any resident Indian property owner. Civil disputes about the title of a property owned by an NRI can be adjudicated by Indian courts. NRIs can even approach real estate regulatory authorities or consumer fora of any state.</p>
                <p><strong>I want a loan for Property Purchase. I have not filled any income tax return and don’t have any income proof; how can I avail loan?</strong></p>
                <p>Income proof is essential to determine the repayment capacity of the borrower. If you lack a stable source of income, availing a loan can be difficult. Income is an important parameter for deciding the amount of loan that can be sanctioned to the borrower.  If you want to avail a loan without income proof, the procedure is complicated. You may have to avail loans that do not require a lot of proof. You can also opt for a lesser amount of loan to increase the chances of approval. If you have not filed for an income tax return in the current or previous year due to unforeseen circumstances or due to a genuine reason, explain it to the concerned person. The loan officer will take into consideration your previous income and calculate the repayment capacity. </p>
                <p><strong>How does Property Valuation helps in Property Selling? </strong></p>
                <p>Typically, if a person or a real estate agent is asked to judge the value of a piece of property, he would do so based on information of recent sales or purchases of similar properties in that area. Though this may give a fair idea of the property's market value, an official property valuation would carry more weight. Benefit of official property valuation is that it is a useful negotiating tool when selling the property. </p>
                <p>Another Benefit property valuation is if you need to use this piece of property as a security against a loan, the bank's loan approval process would be faster and smoother if the property is certified by an official valuer. Many banks now insist on valuation certificates before issuing loans using properties as security. The value thus certified may also have chances of getting a higher amount of loan sanctioned.</p>
            </div>
        </div>
    </div>
  )
}

export default Tweleth