import React from "react";
import "../../styles/Buy.css";

import { Link } from "react-router-dom";

import pyramid from "../../assets/Pyramid.png"
import serviceEnding from "../../assets/serviceEnding.jpg"
import helpCenter from "../../assets/homepage help center.jpg"

function Capital() {
  return (
    <div className="buy__div1">
      <div className="buy__div2">
        <div className="about__div3">
          <img
            src="https://images.unsplash.com/photo-1596176530529-78163a4f7af2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1227&q=80"
            className="buy__img1"
            alt=""
          />
          <div className="buy__div04">
            <p>Capital Market</p>
            <p>
            Uncover Investment Opportunities With Capital Markets
            </p>
            {/* <div className="buy__div3">
              <p>Search Property</p>
              <div className="buy__div4"></div>
            </div> */}
          </div>
        </div>
        <div className="res__div3">
          <div className="res__div4">
            <div className="res__div5">
              <div className="res__div6">
                <p>Capital Markets Advisory</p>
                <p>
                Our 40+ years of experience, financing expertise, renowned property insight, and top-notch research capabilities enable us to provide our clients with a world of opportunity.  
                </p>
              </div>
              <div className="res__div7">
                <img
                  src={pyramid}
                  className="res__img1"
                  id="commercial_pyramid"
                  alt=""
                />
              </div>
            </div>
            <div className="sub_services">
              <div className="sub_service_div">
              <div className="serviceBox">
                <img src="https://images.pexels.com/photos/5716025/pexels-photo-5716025.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
                <h1>Investment Strategy</h1>
                <p>Our investment strategy specialists have the expertise you need to strengthen your financial portfolio through purchasing, managing, and selling real estate. Parikh Real Estaten stands number one when it comes to helping individual investors achieve their financial and investment goals. 

                </p>
              </div>
              <div className="serviceBox">
                <img src="https://images.pexels.com/photos/12188472/pexels-photo-12188472.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
                <h1>Private Equity</h1>
                <p>Parikh Real Estate works with various investors and Purple Elephant Realty Fund—the first AIF in Gujarat to receive SEBI registration. With us, even a small investor can have the opportunity to invest in a number of projects.


                </p>
              </div>
              <div className="serviceBox">
                <img src="https://images.pexels.com/photos/1181406/pexels-photo-1181406.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
                <h1>Fund Raising For Developers</h1>
                <p>We assist in structuring and securing the customized funding that developers require. We assist our developer clients in obtaining the best kinds of funding through investors. 


                </p>
              </div>
              </div>
              <div className="sub_service_div">
              <div className="serviceBox">
                <img src="https://images.pexels.com/photos/636342/pexels-photo-636342.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
                <h1>Developer Land</h1>
                <p>Parikh Real Estate helps prospective developers in identifying and evaluating cost-effective locations with future development potential. Our experts analyze the master plan thoroughly and help developers at every stage, from searching the appropriate sites to buying or selling.

                </p>
              </div>
              <div className="serviceBox">
                <img src="https://images.pexels.com/photos/3760069/pexels-photo-3760069.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
                <h1>Joint Venture
</h1>
                <p>Our skilled experts help developers/land owners identify different investment opportunities. They help developers initiate business agreements with people of similar interests for mutual growth. We are one of the pioneers in introducing Joint Ventures in Ahmedabad. 

                </p>
              </div>
              <div className="serviceBox">
                <img src="https://images.pexels.com/photos/1402923/pexels-photo-1402923.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
                <h1>Joint Development
 </h1>
                <p>Our years of experience in the real estate sector enables us to match builders/developers with Land/Property owners for Joint Development. Over the years we have forged multiple joint ventures across Gujarat. 
                </p>
              </div>
              </div>
            </div>
          </div>
        </div>
        <div className="res__div9">
          <div className="about__div12">
            <div className="about__div13">
              <div className="about__div14">
                <div className="res__div015">
                  <p>
                  Connecting Capital With <span>Opportunity Through A Full Spectrum Of Services</span>
                  </p>
                </div>
                <div className="about__div16">
                  <p>
                  Our capital markets real estate advisory firm counsels institutional and private real estate owners on executing the most substantial real estate transactions. We are driven by great ideas, profound knowledge, and constant devotion. Our team uses a comprehensive spectrum of investor services to create solutions for challenging real estate issues. 
                  </p>
                </div>
                <div className="about__div17">
                  <p>
                  In addition to strategy development and investment planning, identification and sourcing, independent investment research, negotiation, due diligence, and continuous investment oversight, we bring a wealth of experience to clients.
                  </p>
                </div>
                {/* <div className="about__div18">
                  <p>Learn More</p>
                  <div className="about__div19"></div>
                </div> */}
              </div>
              <div className="about__div20">
                <img src={serviceEnding} alt="" className="serviceImg"/>
                {/* <video
                  className="about__img3"
                  playsinline=""
                  autoplay=""
                  loop=""
                  muted=""
                  src=""
                  poster="https://victorstone.co.uk/wp-content/uploads/2022/09/residential-page-prime-property-finding-service-image.jpg"
                ></video> */}
              </div>
            </div>
          </div>
        </div>
        <div className="res__div10">
          <div className="about__div40" style={{marginTop: "3vw"}}>
          <div className="home__div34">
          <div className="home__div35" id="ser__div35">
            <div className="home__div36">
              <img
                src="https://victorstone.co.uk/wp-content/themes/victorstone/src/assets/images/featured-pattern.svg"
                className="home__img7"
                id="sesrvice_Img"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="home__div37">
          <div className="home__div38">
          <div className="home__div39">
              <div className="home__div40">
                <div className="home__div41">
                  <Link
                    to="/Our-Services/commercial"
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    <p>Commercial Services</p>
                  </Link>
                  <p>Feasibility Studies</p>
                  <p>Market Dynamics</p>
                  <p>Premises Acquisitions & Disposal</p>
                  <p>Buy v/s Lease Solutions</p>
                  <p>Leasing</p>
                  <p>BTS Solutions</p>
                  <div className="home__div42"></div>
                </div>
                <div className="home__div41">
                  <Link
                      to="/Our-Services/residential"
                      style={{ textDecoration: "none", color: "#fff" }}
                    >
                    <p>Residential Services</p>
                  </Link>
                  <p>Property Marketing</p>
                  <p>Exclusive Project Marketing</p>
                  <p>Location Selection</p>
                  <p>Budget Costing Advice</p>
                  <p>Competition Benchmarking</p>
                  <div className="home__div42"></div>
                </div>
              </div>
              <div className="home__div40">
                <div className="home__div41">
                <Link
                      to="/Our-Services/key-industrial"
                      style={{ textDecoration: "none", color: "#fff" }}
                    >
                  <p>Industrial Services</p>
                  </Link>
                  <p>Land Acquisition & Conversion</p>
                  <p>63 AA/ 65KH </p>
                  <p>Built – To – Suit (B.T.S) Solution</p>
                  <p>Feasibility Studies</p>
                  <p>Buy v/s Lease Solutions</p>
                  <p>Consent to Establish </p>
                  <div className="home__div42"></div>
                </div>
                <div className="home__div41">
                <Link
                      to="/Our-Services/capital-market"
                      style={{ textDecoration: "none", color: "#fff" }}
                    >
                  <p>Capital Markets</p>
                  </Link>
                  <p>Investment Strategy</p>
                  <p>Private Equity</p>
                  <p>Fundraising For Developers</p>
                  <p>Developer Land</p>
                  <p>Joint Venture</p>
                  <p>Joint Development</p>
                  <div className="home__div42"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="home__div43">
            <div className="home__div44">
              <div className="home__div45">
                <p>Need help with something else?</p>
                <Link
                to="/contact"
                style={{ textDecoration: "none", color: "#fff" }}
              >
                <div className="home__div46">
                  <p>Contact Us</p>
                  <svg
                    className="home__svg3"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="16"
                      cy="16"
                      r="15"
                      transform="rotate(-90 16 16)"
                      stroke="white"
                      stroke-opacity="0.3"
                      stroke-width="2"
                    ></circle>
                    <path
                      d="M12 15.8333L20 15.8333M20 15.8333L16 11.6667M20 15.8333L16 20"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>
                </Link>
              </div>
            </div>
            <img
              src={helpCenter}
              className="home__img8"
              alt=""
            />
          </div>
        </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Capital;
