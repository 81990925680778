import React from 'react'
import "../../styles/footer.css"
import logo from "../../assets/companyLogo.png"

// Icons
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';

function Footer() {
  return (
    <div className='Footer'>
        <div className="part_1">
          <div className="logo_box">
            <img src={logo} alt="Logo" />
            <div className="social_media">
                <a href="https://www.facebook.com/parikhrealestate" target="_blank" rel="noreferrer">
                <FacebookRoundedIcon />
                </a>
                {/* <a href="http://" target="_blank" rel="noreferrer">
                <TwitterIcon />
                </a> */}
                <a href="https://instagram.com/parikh_real_estate?igshid=YmMyMTA2M2Y=" target="_blank" rel="noreferrer">
                <InstagramIcon />
                </a>
                <a href="https://www.linkedin.com/in/parikh-real-estate-management-84847957/" target="_blank" rel="noreferrer">
                <LinkedInIcon />
                </a>
                {/* <a href="http://" target="_blank" rel="noreferrer">
                <YouTubeIcon />
                </a> */}
            </div>
          </div>
          <div className="foooter_links">
            <div>
              <h1>Company</h1>
              <div>
                <a href="/about">
                <p>About</p>
                </a>
                <a href="/Our-Services">
                <p>Services</p>
                </a>
                <a href="/contact">
                <p>Contact</p>
                </a>
              </div>
            </div>
            <div>
              <h1>Services</h1>
              <div>
                <a href="/Our-Services/commercial">
                <p>Commercial</p>
                </a>
                <a href="/Our-Services/key-industrial">
                <p>Industrial</p>
                </a>
                <a href="/Our-Services/residential">
                <p>Residential</p>
                </a>
                <a href="/Our-Services/capital-market">
                <p>Capital Market</p>
                </a>
              </div>
            </div>
            {/* <div>
              <h1>Resources</h1>
              <div>
                <a href="http://">
                <p>Commercial</p>
                </a>
                <a href="http://">
                <p>Landlords</p>
                </a>
                <a href="http://">
                <p>Reports</p>
                </a>
              </div>
            </div>
            <div>
              <h1>Support</h1>
              <div>
                <a href="http://">
                <p>Contact</p>
                </a>
                <a href="http://">
                <p>Help Center</p>
                </a>
              </div>
            </div> */}
          </div>
        </div>
        <div className="part_2">
          {/* <h1>London</h1> */}
          <p>@Parikh Real Estate Pvt. Ltd. All rights reserved</p>
          <a href="https://pixelideas.site/" target="_blank" rel="noopener noreferrer" style={{textDecoration: "none"}}><p>Conceptualized by Pixel Ideas</p></a>
          {/* <p>The investments referred to on this website are not suitable for all investors and are intended for certain categories of investors only. VSG International does not give financial advice to investors about the suitability of the investments. Investors should seek advice from a person who specialises in advising on illiquid real assets. Unregulated investment opportunities in complex instruments are considered high risk. Therefore, we only work with investors who are sufficiently knowledgeable and experienced in dealing with these types of investments and are classified as professional investors. Investors should ensure that they comply with the laws of their local jurisdiction before investing.</p> */}
        </div>
    </div>
  )
}

export default Footer