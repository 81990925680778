import React, { useEffect, useRef } from "react";
import "../../styles/Hamburger.css";
import gsap from "gsap";

const Hamburger = ({ state }) => {
  let menu = useRef(null);
  let revealMenu = useRef(null);
  let revealMenuBackground = useRef(null);

  useEffect(() => {
    if (state.clicked === false) {
      gsap.to([revealMenu, revealMenuBackground], {
        duration: 0.8,
        height: 0,
        ease: "power3.inOut",
        stagger: {
          amount: 0.07,
        },
      });
      gsap.to(menu, {
        duration: 1,
        css: { display: "none" },
      });
    } else if (
      state.clicked === true ||
      (state.clicked === true && state.initial === null)
    ) {
      gsap.to(menu, {
        duration: 0,
        css: { display: "block" },
      });
      gsap.to([revealMenuBackground, revealMenu], {
        height: "100%",
        duration: 0,
        opacity: 1,
      });
      straggerReveal(revealMenuBackground, revealMenu);
    }
  }, [state]);

  const straggerReveal = (node1, node2) => {
    gsap.from([node1, node2], {
      duration: 0.8,
      height: 0,
      transformOrigin: "right top",
      ease: "power3.inOut",
      stagger: {
        amount: 0.1,
      },
    });
  };

  function home() {
    document.getElementById("ham__div2").style.height = "0%";
    document.getElementById("ham__div2").style.width = "0%";
    document.getElementById("ham__div2").style.top = "0vw";
    document.getElementById("ham__div2").style.left = "0vw";
    document.getElementById("ham__div2").style.right = "0vw";
    document.getElementById("ham__div2").style.bottom = "0vw";
    document.getElementById("ham__div2").style.transition = "0.8s ease";

    document.getElementById("ham__div3").style.height = "0%";
    document.getElementById("ham__div3").style.width = "0%";
    document.getElementById("ham__div3").style.top = "0vw";
    document.getElementById("ham__div3").style.left = "0vw";
    document.getElementById("ham__div3").style.right = "0vw";
    document.getElementById("ham__div3").style.bottom = "0vw";
    document.getElementById("ham__div3").style.transition = "0.8s ease";
  }

  return (
    <div className="ham__div1" ref={(el) => (menu = el)}>
      <div
        className="ham__div2"
        id="ham__div2"
        ref={(el) => (revealMenuBackground = el)}
      ></div>
      <div className="ham__div3" id="ham__div3" ref={(el) => (revealMenu = el)}>
        {/* <div
          className={ham__div4}
          ref={(el) => (animeBackground = el)}
        ></div> */}
        {/* <div
          className={ham__div4}
          ref={(el) => (animeBackgroundh = el)}
        ></div> */}
        <div className="ham__div5">
          <div className="ham__div6">
            <div className="ham__div7">
              <a style={{ textDecoration: "none", color: "#fff" }} href="/">
                <p>Home</p>
              </a>
              <a
                style={{ textDecoration: "none", color: "#fff" }}
                href="/about"
              >
                <p>About Us</p>
              </a>
              <a style={{ textDecoration: "none", color: "#fff" }} href="/blog">
                <p>Blogs</p>
              </a>
              <a style={{ textDecoration: "none", color: "#fff" }} href="/article">
                <p>Articles</p>
              </a>
              <a style={{ textDecoration: "none", color: "#fff" }} href="/Our-Services">
                <p>Services</p>
              </a>
              <div className="nav__div000000">
                <a
                  style={{ textDecoration: "none", color: "#fff" }}
                  href="/Our-Services/commercial"
                >
                  <p>Commercial</p>
                </a>
                <span className="liness">|</span>
                <a
                  style={{ textDecoration: "none", color: "#fff" }}
                  href="/Our-Services/key-industrial"
                >
                  <p>Industrial</p>
                </a>
                <span className="liness">|</span>
                <a
                  style={{ textDecoration: "none", color: "#fff" }}
                  href="/Our-Services/residential"
                >
                  <p>Residential</p>
                </a>
                <span className="liness">|</span>
                <a
                  style={{ textDecoration: "none", color: "#fff" }}
                  href="/Our-Services/capital-market"
                >
                  <p>Capital&nbsp;Market</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hamburger;
