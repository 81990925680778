import React from "react";
import "../../styles/SecondSlider.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Mousewheel, Keyboard, Autoplay } from "swiper";

import founder3 from "../../assets/Hasmukh.jpeg";
import founder2 from "../../assets/Monil.jpeg";
import founder1 from "../../assets/Pramit.jpeg";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

function SecondSlider() {
  return (
    <div className="second__div1">
      <div className="second__div2">
        <Swiper
          cssMode={true}
          navigation={true}
          mousewheel={true}
          keyboard={true}
          loop={true}
          autoplay={{
            delay: 4500,
            disableOnInteraction: false,
          }}
          modules={[Navigation, Autoplay, Keyboard, Mousewheel]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className="second__div3">
              <div className="second__div4">
                <img src={founder3} className="second__img1" alt="" />
              </div>
              <div className="second__div5">
                <div className="second__div6">
                  <p>
                    Starting the company way back in 1981, my mission was to
                    offer holistic real estate advisory services in the most
                    ethical and process-oriented manner. My sons' hard work and
                    commitment have firmly established Parikh Real Estate as one
                    of the leading consultancies in Ahemdabad.
                  </p>
                </div>
                <div className="second__div7">
                  <p>Mr. Hasmukh Parikh</p>
                  <p>Founder and Chairman</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="second__div3">
              <div className="second__div4">
                <img src={founder1} className="second__img1" alt="" />
              </div>
              <div className="second__div5">
                <div className="second__div6">
                  <p>
                    We are passionate about results aligned with our client's
                    success and help them with all their real estate needs.
                    Apart from providing professional guidance and executing
                    several deals, we help our clients identify exit
                    opportunities for excellent financial performance.
                  </p>
                </div>
                <div className="second__div7">
                  <p>Mr. Pramit Parikh</p>
                  <p>Director</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="second__div3">
              <div className="second__div4">
                <img src={founder2} className="second__img1" alt="" />
              </div>
              <div className="second__div5">
                <div className="second__div6">
                  <p>
                    Our extensive offerings extend beyond real estate
                    consulting. As part of our efforts to steer our company
                    toward more prosperous milestones, we also offer
                    development, industrial liaison & transaction, and private
                    equity. Our services help local and international companies
                    find profitable real estate investments based on their
                    business requirements and growth verticals.
                  </p>
                </div>
                <div className="second__div7">
                  <p>Mr. Monil Parikh</p>
                  <p>Director</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}

export default SecondSlider;
