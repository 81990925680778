import React from 'react'
import airbnb from "../../../assets/Blog/blog9.jpeg"

function Ninth() {
  return (
    <div className="first__div1">
        <div className="first__div2">
            <div className="first__div3">
                <img src={airbnb} className="first__img1" alt="" />
            </div>
            <div className="first__div4">
                <p><b>FAQs</b></p>
            </div>
            <div className="first__div5">
                <p><strong>I am planning to buy a 3BHK apartment in West Ahmedabad. I heard there will be drop in pricing. Currently which builders or projects are offering great deals & offers to homebuyers?</strong></p>
                <p>If great deal by you mean discount or reduce in price then no. there has not been any significant upwards or downwards movements in terms of pricing due to the pandemic. Yes the market is stagnate, but the price drop in near future is also not expected.  Almost every builder are offering a lot of offers on real estate projects like minimal booking amount, cashback, and promising gifts upon booking among other offers. Yes now definitely builders are providing many easy payment options to buyers’ convenience to increase sales but no discount or price drop offered seen anywhere in market.</p>
                <p>Therefore, I would suggest you to decide upon a budget in which you want to purchase a 3 BHK apartment in West Ahmedabad, shortlist a few projects as per your requirement and then speak with the builders to get clarity on the offers and then decide which is the most suited for you. </p>
                <p><strong>How many Properties can a NRI own in India also what are the eligibility criteria to avail loan in India?   (From Maulik Patel)</strong></p>
                <p>Just like resident Indians, non-resident Indians, or NRIs, can invest in any number of properties in India and are also eligible to avail of home loans for as many properties as they like. An NRI usually has to pay a higher rate of interest than resident Indians. The tenure for a home loan to an NRI usually ranges between 5 to 20 years - only in select cases can it go up to 30 years for salaried professionals. Most banks determine the loan amount eligibility of NRI borrowers based on their income and credit history. Apart from fulfilling basics like minimum age, qualification and years of employment, one must earn a minimum amount to qualify for a home loan. The mandatory income limit again varies from bank to bank and also differs for the country of residence. For instance, $24,000 (around 18Lac) a year is considered the minimum income level for US-based NRIs. The loan amount can start from a few lakhs and go up to crores, depending on how much is the bank convinced of one’s eligibility. also it is important to have a good credit history and high credit score, as banks will invariably check the intending borrower's credit report - both in the country of residence and India. </p>
                <p>Also to verify the details they will ask for the given documents: Bank statement latest 6 months, Account details,  Residence proof-(both India and abroad), Work permit order, Employer detail, Employment contract, Salary certificate, Power of attorney, Driving license, Passport copy with visa stamp and Degree certificate etc.</p>
                <p><strong>I am first time buyer, how much do I have to pay an agent to help me buy a house? </strong></p>
                <p>Generally home buyers of new project property pay no fees, developer pay fees to agent for bring him a buyer.  Whereas For resale properties Home buyers pay fees to an agent to buy a home. A real estate agent or broker can provide you good and required guidance’s on prices as they have the knowledge of current rate trends in the market and prices of the properties in particular areas. They can help you to negotiate the prices depending upon on supply and demand conditions. And usually charges 2% of total transaction value as brokerage fees for their service, 1% in some special cases.</p>
                <p>As, for most home sales, there are two real estate agents involved in the deal: one that represents the seller and another who represents the buyer. Sellers broke represent seller property and charge a fee to represent them and market the property. Marketing may include advertising expenses such newspaper print ads and digital marketing. The property will also be placed on online real estate portals, where other agents will be able to search and find the home for sale. Agents who represent buyers are compensated by the sellers’ broker for bringing home buyers to the table. When the home is sold, the listing broker splits the listing fee with the buyer’s agent. Thus, buyers don’t pay their agents. While in some cases buyer & seller both pay their representative agents.</p>
            </div>
        </div>
    </div>
  )
}

export default Ninth