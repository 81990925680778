import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import HeroSection from "./HeroSection";
import "../../styles/Home.css";
import { motion } from "framer-motion";
import Marquee from "react-fast-marquee";
import Slider from "./Slider";
import { Link } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import emailjs from '@emailjs/browser'

// Images
import blog1 from "../../assets/Blog/blog-1.jpeg"
import blog2 from "../../assets/Blog/blog-2.jpeg"
import blog3 from "../../assets/Blog/blog-3.jpeg"
import blog4 from "../../assets/Blog/blog-4.jpeg"
import pyramid from "../../assets/Pyramid.png"
import bgBlurBuild from "../../assets/pic-1.png"
import article from "../../assets/Articles.jpeg"
import about from "../../assets/homepage about us.jpg"
import contact from "../../assets/home page contact.jpg"
import helpCenter from "../../assets/homepage help center.jpg"
import groupCompanyOne from "../../assets/Purple Elephant.jpg"
import groupCompanyTwo from "../../assets/Realty Serve.png"
import groupCompanyThree from "../../assets/SKH Housing2.png"

// Client Images
import client1 from "../../assets/client1.png"
import client2 from "../../assets/client2.webp"
import client3 from "../../assets/client3.png"
// import client4 from "../../assets/client4.png"
import client5 from "../../assets/client5.png"
import client6 from "../../assets/client6.png"
import client7 from "../../assets/client7.png"
import PopUp from "../pop-up/PopUp";
import SecondSlider from "./SecondSlider";


function Home() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  const [width, setWidth] = useState(0);
  const carousel = useRef();
  useEffect(() => {
    // setWidth(carousel.current.scrollWidth - carousel.current.offsetWidth);
  }, []);

  const scroll = () => {
    window.scrollTo(0,0);
  }

  const [timedPopUp, setTimedPopup] = useState(false);  
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    setTimeout(()=>{
      setTimedPopup(true);
    }, 8000);
  }, [])
  

  // const [formStatePopUp, setFormStatePopUp] = useState({})

  // const config = {
    // Username: "kazuokaze@yopmail.com",
    // Password: "AC3752822D00E90C0D10603C1B85B3FC19E5",
    // Host: "smtp.elasticemail.com",
    // Port: 2525,
    // SecureToken: "3d3f0b16-5b77-439d-b37f-3c08e7c2e0f7",
    // To : 'them@website.com',
    // From : "you@isp.com",
    // Subject : "This is the subject",
    // Body : "And this is the body"
  // } 3d3f0b16-5b77-439d-b37f-3c08e7c2e0f7

  // const changeHandler = (event) => {
  //   setFormStatePopUp({ ...formStatePopUp, [event.target.name]: event.target.value });
  // }

  const submitHandler = (event) => {
    event.preventDefault()

    const serviceID = 'service_v2wteli';
    const templateID = 'template_o9d4dt3';
    const publicKey = 'u9QbJ-05Mn3GkWCkZ';

    const templateParams = {
      name: name,
      email: email,
      phone: phone,
      message: message
    }

    emailjs.send(serviceID, templateID, templateParams, publicKey)
      .then((response) => {
        console.log('Email sent', response);
        alert('Thank you for getting in touch with us. We will get back to you within 24 hours');
        setName('');
        setEmail('');
        setPhone('');
        setMessage('');
      })
      .catch((error) => {
        console.error('Error Sending Email: ', error)
      })

    // const config = {
    //   SecureToken: "d47dc202-ea8f-4b35-ab54-311296b061a8",
    //   To : 'clevercoder0307@gmail.com',
    //   // To : 'info@pixelideas.site, info@parikhrealestate.in',
    //   From : 'parikhwebsite@gmail.com',
    //   Subject : "This is a pop-up form info....",
    //   Body : `User Email: ${formStatePopUp.email} 
    //   User name: ${formStatePopUp.first_name}
    //   Message: ${formStatePopUp.message} 
    //   Phone Number: ${formStatePopUp.phone}`,
    // }
    // if(window.Email) {
    //   window.Email.send(config).then((message) => alert('Thank you for getting in touch with us. We will get back to you within 24 hours'))
    // }
    // setFormStatePopUp("");
  }
  

  return (
    <div className="home__div1">
      <PopUp trigger={timedPopUp}>
      <div className="PopForm" id="Popform">
          <form action="" onSubmit={submitHandler}>
            <div className="popClose" onClick={()=> setTimedPopup(false)}><CloseIcon /></div>
            <label htmlFor="">
              Name <span>*</span>
            </label>
            <input type="text" required name="name" value={name} onChange={(e) => setName(e.target.value)} />
            <label htmlFor="">
              Email address <span>*</span>
            </label>
            <input type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
            <label htmlFor="">
              Phone 
            </label>
            <input type="text" maxLength="10" name="phone" value={phone} onChange={(e) => setPhone(e.target.value)} />
            <label htmlFor="">
              Message<span>*</span>
            </label>
            <textarea
              id=""
              cols="30"
              rows="10"
              style={{ resize: "none" }}
              required
              name="message"
              value={message} onChange={(e) => setMessage(e.target.value)}
            ></textarea>
            <button type="submit">Submit form</button>
          </form>
        </div>
      </PopUp>
      <HeroSection />
      <div className="home__div2">
        <div className="home__div3">
          <div className="home__div4">
            <div className="home__div5">
              <div className="home__div6">
                <p>Making Your Realty Dreams</p>
                <p>a Reality Since 1981</p>
              </div>
              <div className="home__div7">
                <p>
                Founded on a legacy of integrity and trust, Parikh Real Estate has become the most trusted real estate investment advisory firm in Ahmedabad and now working across the country. We started in 1981 and have evolved to master all areas of real estate and capital markets. Our decades of experience empowers us to deliver a better client experience. We aim to be THE CONTACT for all your real estate and investment services.
                </p>
              </div>
              {/* <div className="home__div8">
                <p>Read More</p>
              </div> */}
            </div>
            <div className="home__div9">
              <div className="home__divext1">
                <div className="home__div10">
                  <div className="home__div11">
                    <div className="home__div12">
                      {/* <img
                        src="https://victorstone.co.uk/wp-content/uploads/2022/05/search-1.svg"
                        className="home__img1"
                        alt=""
                      /> */}
                      <p>Commercial Services</p>
                    </div>
                    <p className="lost">Tailor-Made Real Estate Solutions For Your Commercial Ventures </p>
                  </div>
                  <a
                  style={{ textDecoration: "none", color: "#fff" }}
                  href="/Our-Services/commercial"
                >
                  <div className="home__div13">
                    <svg
                      width="64"
                      height="64"
                      viewBox="0 0 64 64"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="32"
                        cy="32"
                        r="31"
                        stroke="#373737"
                        stroke-width="2"
                      ></circle>
                      <path
                        d="M21.8182 31.9999H42.1818M42.1818 31.9999L32 21.8181M42.1818 31.9999L32 42.1818"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </div>
                  </a>
                </div>
                <div className="home__div14"></div>
              </div>
              <div className="home__divext1">
                <div className="home__div10">
                  <div className="home__div11">
                    <div className="home__div12">
                      {/* <img
                        src="https://victorstone.co.uk/wp-content/uploads/2022/05/file-text.svg"
                        className="home__img1"
                        alt=""
                      /> */}
                      <p>Industrial Services</p>
                    </div>
                    <p className="lost">
                    Professional Financing And Structuring Advisory For Industrial Clients
                    </p>
                  </div>
                  <a
                  style={{ textDecoration: "none", color: "#fff" }}
                  href="/Our-Services/key-industrial"
                >
                  <div className="home__div13">
                    <svg
                      width="64"
                      height="64"
                      viewBox="0 0 64 64"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="32"
                        cy="32"
                        r="31"
                        stroke="#373737"
                        stroke-width="2"
                      ></circle>
                      <path
                        d="M21.8182 31.9999H42.1818M42.1818 31.9999L32 21.8181M42.1818 31.9999L32 42.1818"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </div>
                  </a>
                </div>
                <div className="home__div14"></div>
              </div>
              <div className="home__divext1">
                <div className="home__div10">
                  <div className="home__div11">
                    <div className="home__div12">
                      {/* <img
                        src="https://victorstone.co.uk/wp-content/uploads/2022/05/Icon-21.svg"
                        className="home__img1"
                        alt=""
                      /> */}
                      <p>Residential Services</p>
                    </div>
                    <p className="lost">
                    Clear And Targeted Residential Real Estate Solutions
                    </p>
                  </div>
                  <a
                  style={{ textDecoration: "none", color: "#fff" }}
                  href="/Our-Services/residential"
                >
                  <div className="home__div13">
                    <svg
                      width="64"
                      height="64"
                      viewBox="0 0 64 64"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="32"
                        cy="32"
                        r="31"
                        stroke="#373737"
                        stroke-width="2"
                      ></circle>
                      <path
                        d="M21.8182 31.9999H42.1818M42.1818 31.9999L32 21.8181M42.1818 31.9999L32 42.1818"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </div>
                  </a>
                </div>
                <div className="home__div14"></div>
              </div>
              <div className="home__divext1">
                <div className="home__div10">
                  <div className="home__div11">
                    <div className="home__div12">
                      {/* <img
                        src="https://victorstone.co.uk/wp-content/uploads/2022/05/Icon-21.svg"
                        className="home__img1"
                        alt=""
                      /> */}
                      <p>Capital Markets</p>
                    </div>
                    <p className="lost">
                    Uncover Investment Opportunities And Capital Sources
                    </p>
                  </div>
                  <a
                  style={{ textDecoration: "none", color: "#fff" }}
                  href="/Our-Services/capital-market"
                >
                  <div className="home__div13">
                    <svg
                      width="64"
                      height="64"
                      viewBox="0 0 64 64"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="32"
                        cy="32"
                        r="31"
                        stroke="#373737"
                        stroke-width="2"
                      ></circle>
                      <path
                        d="M21.8182 31.9999H42.1818M42.1818 31.9999L32 21.8181M42.1818 31.9999L32 42.1818"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home__div15">
          <div className="home__div16">
            <img
              src={pyramid}
              className="bg__img1"
              alt=""
            />
            {/* <img
              src="https://victorstone.co.uk/wp-content/themes/victorstone/src/assets/images/light-emblem.svg"
              className="bg__img2"
              alt=""
            /> */}
          </div>
          <div className="home__div17">
            <div className="home__div18">
              <div className="home__div199">
                <img
                  src={article}
                  className="home__img2 home__img1"
                  alt=""
                />
                <a
                style={{ textDecoration: "none", color: "#fff" }}
                href="/article"
              >
                <div className="home__div20">
                  <p>Articles</p>
                  <svg
                    className="home__svg1"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="16"
                      cy="16"
                      r="15"
                      transform="rotate(-90 16 16)"
                      stroke="white"
                      stroke-opacity="0.3"
                      stroke-width="2"
                    ></circle>
                    <path
                      d="M12 15.8333L20 15.8333M20 15.8333L16 11.6667M20 15.8333L16 20"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>
                </a>
              </div>
              <div className="home__div19">
                <img
                  src={about}
                  className="home__img3"
                  alt=""
                />
                <a style={{ textDecoration: "none", color: "#fff" }} href="/about">
                <div className="home__div200">
                  <p>About Us</p>
                  <svg
                    className="home__svg1"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="16"
                      cy="16"
                      r="15"
                      transform="rotate(-90 16 16)"
                      stroke="white"
                      stroke-opacity="0.3"
                      stroke-width="2"
                    ></circle>
                    <path
                      d="M12 15.8333L20 15.8333M20 15.8333L16 11.6667M20 15.8333L16 20"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>
                </a>
              </div>
              <div className="home__div1999">
                <img
                  src={contact}
                  className="home__img2"
                  alt=""
                />
                <a style={{ textDecoration: "none", color: "#fff" }} href="/contact">
                <div className="home__div20">
                  <p>Contact Us</p>
                  <svg
                    className="home__svg1"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="16"
                      cy="16"
                      r="15"
                      transform="rotate(-90 16 16)"
                      stroke="white"
                      stroke-opacity="0.3"
                      stroke-width="2"
                    ></circle>
                    <path
                      d="M12 15.8333L20 15.8333M20 15.8333L16 11.6667M20 15.8333L16 20"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="home__div21">
          <div className="home__div22">
            <img
              src={bgBlurBuild}
              className="home__img4"
              alt=""
            />
            <div className="home__div23">
              <p>
              We believe in transforming transactions into relationships with a customer-centric approach, strong vision, and empowered leadership. As a loyal customer to Parikh Real Estate, you receive trust and transparency at its best. We take pride in our experts who have a strong commitment to excellence, ethics, and teamwork. We take time to understand client requirements and work tirelessly to offer the best deals for your next venture.
              </p>
            </div>
          </div>
        </div>
        <div className="grp_companies">
          <h1>Our Group of Companies</h1>
          <div className="company_flex">
            <div className="companies">
              <a href="https://parikhrealestate.in" target="_blank" rel="noopener noreferrer">
                <div className="img" id="grpImg2">
                  <img src={groupCompanyTwo} alt="" />
                  <svg
                    className="sister_svg"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="16"
                      cy="16"
                      r="15"
                      transform="rotate(-90 16 16)"
                      stroke="#222"
                      stroke-opacity="0.8"
                      stroke-width="1"
                    ></circle>
                    <path
                      d="M12 15.8333L20 15.8333M20 15.8333L16 11.6667M20 15.8333L16 20"
                      stroke="#222"
                      stroke-width="1"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>
              </a>
                {/* <h2 className="name">Realty Serve</h2> */}
                {/* <a href="https://" target="_blank" rel="noopener noreferrer">Visit</a> */}
            </div>
            <div className="companies">
            <a href="https://www.purpleelephantrealty.in/about-us" target="_blank" rel="noopener noreferrer">
              <div className="img" id="grpImg1">
                <img src={groupCompanyOne} alt="" />
                <svg
                    className="sister_svg"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="16"
                      cy="16"
                      r="15"
                      transform="rotate(-90 16 16)"
                      stroke="#222"
                      stroke-opacity="0.8"
                      stroke-width="1"
                    ></circle>
                    <path
                      d="M12 15.8333L20 15.8333M20 15.8333L16 11.6667M20 15.8333L16 20"
                      stroke="#222"
                      stroke-width="1"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
              </div>
            </a>
                {/* <h2 className="name">Purple Elephant Realty</h2> */}
                {/* <a href="https://www.purpleelephantrealty.in/about-us" target="_blank" rel="noopener noreferrer">Visit</a> */}
            </div>
            <div className="companies">
              <a href="https://skhhousing.in/" target="_blank" rel="noopener noreferrer">
                <div className="img" id="grpImg3">
                  <img src={groupCompanyThree} alt="" />
                  <svg
                    className="sister_svg"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="16"
                      cy="16"
                      r="15"
                      transform="rotate(-90 16 16)"
                      stroke="#222"
                      stroke-opacity="0.8"
                      stroke-width="1"
                    ></circle>
                    <path
                      d="M12 15.8333L20 15.8333M20 15.8333L16 11.6667M20 15.8333L16 20"
                      stroke="#222"
                      stroke-width="1"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>
              </a>
                {/* <h2 className="name">SKH Housing</h2> */}
                {/* <a href="https://skhhousing.in/" target="_blank" rel="noopener noreferrer">Visit</a> */}
            </div>
          </div>
        </div>
        <div className="home__div34">
          <div className="home__div35">
            <div className="home__div36">
              <img
                src="https://victorstone.co.uk/wp-content/themes/victorstone/src/assets/images/featured-pattern.svg"
                className="home__img7"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="home__div37">
          <div className="home__div38">
            <div className="home__div39">
              <div className="home__div40">
                <div className="home__div41">
                  <Link
                    to="/Our-Services/commercial"
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    <p>Commercial Services</p>
                  </Link>
                  <p>Feasibility Studies</p>
                  <p>Market Dynamics</p>
                  <p>Premises Acquisitions & Disposal</p>
                  <p>Buy v/s Lease Solutions</p>
                  <p>Leasing</p>
                  <p>BTS Solutions</p>
                  <div className="home__div42"></div>
                </div>
                <div className="home__div41">
                  <Link
                      to="/Our-Services/residential"
                      style={{ textDecoration: "none", color: "#fff" }}
                    >
                    <p>Residential Services</p>
                  </Link>
                  <p>Property Marketing</p>
                  <p>Exclusive Project Marketing</p>
                  <p>Location Selection</p>
                  <p>Budget Costing Advice</p>
                  <p>Competition Benchmarking</p>
                  <div className="home__div42"></div>
                </div>
              </div>
              <div className="home__div40">
                <div className="home__div41">
                <Link
                      to="/Our-Services/key-industrial"
                      style={{ textDecoration: "none", color: "#fff" }}
                    >
                  <p>Industrial Services</p>
                  </Link>
                  <p>Land Acquisition & Conversion</p>
                  <p>63 AA/ 65KH </p>
                  <p>Built – To – Suit (B.T.S) Solution</p>
                  <p>Feasibility Studies</p>
                  <p>Buy v/s Lease Solutions</p>
                  <p>Consent to Establish </p>
                  <div className="home__div42"></div>
                </div>
                <div className="home__div41">
                <Link
                      to="/Our-Services/capital-market"
                      style={{ textDecoration: "none", color: "#fff" }}
                    >
                  <p>Capital Markets</p>
                  </Link>
                  <p>Investment Strategy</p>
                  <p>Private Equity</p>
                  <p>Fundraising For Developers</p>
                  <p>Developer Land</p>
                  <p>Joint Venture</p>
                  <p>Joint Development</p>
                  <div className="home__div42"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="home__div43">
            <div className="home__div44">
              <div className="home__div45">
                <p>Need help with something else?</p>
                <Link
                to="/contact"
                style={{ textDecoration: "none", color: "#fff" }}
              >
                <div className="home__div46">
                  <p>Contact Us</p>
                  <svg
                    className="home__svg3"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="16"
                      cy="16"
                      r="15"
                      transform="rotate(-90 16 16)"
                      stroke="white"
                      stroke-opacity="0.3"
                      stroke-width="2"
                    ></circle>
                    <path
                      d="M12 15.8333L20 15.8333M20 15.8333L16 11.6667M20 15.8333L16 20"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>
                </Link>
              </div>
            </div>
            <img
              src={helpCenter}
              className="home__img8"
              alt=""
            />
          </div>
        </div>
        <div className="home__div47">
          <div className="home__div48">
            <img
              src="https://643113.smushcdn.com/2786300/wp-content/themes/victorstone/src/assets/images/operations-bg.png?lossy=0&strip=0&webp=1"
              className="home__img9"
              alt=""
            />
            <div className="home__div49">
              <div className="home__div50">
                <p>Our clientele</p>
                <div className="home__div51">
                  <Marquee speed={80} gradient={false}>
                    <p> <img src={client1} alt="client1" className="clientImg" /></p>
                    <p> <img src={client2} alt="client2" className="clientImg" /></p>
                    <p> <img src={client3} alt="client3" className="clientImg" /></p>
                    {/* <p> <img src={client4} alt="client4" className="clientImg" /></p> */}
                    <p> <img src={client5} alt="client5" className="clientImg" /></p>
                    <p> <img src={client6} alt="client6" className="clientImg" /></p>
                    <p> <img src={client7} alt="client7" className="clientImg" /></p>
                  </Marquee>
                </div>
              </div>
            </div>
          </div>
          <div className="home__div53">
          <div className="home__div50 slider_title">
                <p>Message from our founder</p>
              </div>
            <div className="home__div52">
              <img
                src={pyramid}
                className="home__img10"
                alt=""
              />
            </div>
            {/* <Slider /> */}
            <SecondSlider />
          </div>
        </div>
        <div className="home__div54">
          <div className="home__div55">
            <div className="home__div56">
              <p>Whats new?</p>
              <a style={{ textDecoration: "none", color: "#fff" }} href="/blog">
                <div className="home__div57">
                  <p>View all</p>
                  <div className="empty"></div>
                </div>
              </a>
            </div>
            <div className="home__div58">
              <div className="home__div59">
                <div className="home__div60">
              
                  <div className="home__div61">
                    <img
                      src={blog1}
                      className="home__img12"
                      alt=""
                    />
                    <div className="home__div62">
                      <div className="home__div63">
                        <p>
                        Concept of Airbnb & Its Adaptation in India
                        </p>
                      </div>
                      <div className="home__div64">
                        <p>
                        The concept of sharing has become widely acceptable weathers its “co-working” “co-living” or traveling. India is one of the fastest growing economies.....
                        </p>
                      </div>
                      <Link onClick={scroll} to="/blog/first" style={{textDecoration: "none", color: "#fff"}}>
                      <div className="home__div65">
                        <p>Read Article</p>
                        <svg
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="16"
                            cy="16"
                            r="15"
                            transform="rotate(-90 16 16)"
                            stroke="white"
                            stroke-opacity="0.3"
                            stroke-width="2"
                          ></circle>
                          <path
                            d="M12 15.8333L20 15.8333M20 15.8333L16 11.6667M20 15.8333L16 20"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </div>
                      </Link>
                    </div>
                  </div>
                  <div className="home__div70"></div>
                  
                  <div className="home__div61">
                    <img
                      src={blog2}
                      className="home__img12"
                      alt=""
                    />
                    <div className="home__div62">
                      <div className="home__div63">
                        <p>
                        Co-working & Co-living Market Pre & Post Lockdown
                        </p>
                      </div>
                      <div className="home__div64">
                        <p>
                        The past few years has seen a rise in the use of shared office spaces, with providers such as WeWork experiencing exponential growth......
                        </p>
                      </div>
                      <Link onClick={scroll}  to="/blog/second" style={{textDecoration: "none", color: "#fff"}}>
                      <div className="home__div65">
                        <p>Read Article</p>
                        <svg
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="16"
                            cy="16"
                            r="15"
                            transform="rotate(-90 16 16)"
                            stroke="white"
                            stroke-opacity="0.3"
                            stroke-width="2"
                          ></circle>
                          <path
                            d="M12 15.8333L20 15.8333M20 15.8333L16 11.6667M20 15.8333L16 20"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </div>
                      </Link>
                    </div>
                  </div>
                  <div className="home__div70"></div>
                  <div className="home__div61">
                    <img
                      src={blog3}
                      className="home__img12"
                      alt=""
                    />
                    <div className="home__div62">
                      <div className="home__div63">
                        <p>
                        Fake listings on online real estate portals
                        </p>
                      </div>
                      <div className="home__div64">
                        <p>
                        We live in a world with remarkable technological innovations. Gone are the days when you had to trek between several apartments and sites to shortlist your desired property.....
                        </p>
                      </div>
                      <Link onClick={scroll}  to="/blog/third" style={{textDecoration: "none", color: "#fff"}}>
                      <div className="home__div65">
                        <p>Read Article</p>
                        <svg
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="16"
                            cy="16"
                            r="15"
                            transform="rotate(-90 16 16)"
                            stroke="white"
                            stroke-opacity="0.3"
                            stroke-width="2"
                          ></circle>
                          <path
                            d="M12 15.8333L20 15.8333M20 15.8333L16 11.6667M20 15.8333L16 20"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </div>
                      </Link>
                    </div>
                  </div>
                  <div className="home__div70"></div>
                  <div className="home__div61">
                    <img
                      src={blog4}
                      className="home__img12"
                      alt=""
                    />
                    <div className="home__div62">
                      <div className="home__div63">
                        <p>
                        Impact of Covid-19 on Indian Hospitality Industry
                        </p>
                      </div>
                      <div className="home__div64">
                        <p>
                        The Pandemic is a challenge to both our lives and livelihoods. The crisis is unprecedented and moving quickly, yet still deeply uncertain......
                        </p>
                      </div>
                      <Link onClick={scroll}  to="/blog/fourth" style={{textDecoration: "none", color: "#fff"}}>
                      <div className="home__div65">
                        <p>Read Article</p>
                        <svg
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="16"
                            cy="16"
                            r="15"
                            transform="rotate(-90 16 16)"
                            stroke="white"
                            stroke-opacity="0.3"
                            stroke-width="2"
                          ></circle>
                          <path
                            d="M12 15.8333L20 15.8333M20 15.8333L16 11.6667M20 15.8333L16 20"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
