import React from 'react'
import airbnb from "../../../assets/Blog/blog16.jpeg"

function Sixteenth() {
  return (
    <div className="first__div1">
        <div className="first__div2">
            <div className="first__div3">
                <img src={airbnb} className="first__img1" alt="" />
            </div>
            <div className="first__div4">
                <p><b>Combating Covid-19</b></p>
            </div>
            <div className="first__div5">
                <p>We have heard restructuring of loans for Airline business or huge brands/companies, while they are in need. But one may have never heard about loan restructuring for real estate sector. Restructuring is a practice that allows banks to modify the terms of the loan when the borrower is facing financial stress.</p>
                <p>UP RERA Chairmen recently said that Real estate regulators will write to the RBI, seeking one-time debt restructuring for the industry to help developers overcome the liquidity issues. As many stalled projects do not qualify for getting last mile funding from the Rs 25,000 crore special window created by the government.</p>
                <p>In recent times, most of the banks have slowed down lending. The lending thinking was predominantly based on the project cashflows of the borrowers and the high sales velocity in the projects hypothecated, supported by a favourable economic environment. The current economic scenario is one wherein there is uncertainty about future sales is slow. in the Indian Real estate market wherein the good projects and developers are also facing liquidity challenges. Banks are under stress due to delayed or non-repayment of debt and there is an increase in customer grievances. </p>
                <p>Banks need surety for repayments of their money. But where is income? and no loan given without income. </p>
                <p>We all know about the overall world economy is collapsed. all nations are grappling with balancing priorities of maintaining reasonable life balance. Many Countries have announced fund packages to revive economy, help their people.</p>
                <p>India has announced a $23bn (£18bn) relief package to help people of India's unorganised, informal industry. Many are unemployed overnight. The Package is a combination of direct cash transfer benefits and food security measures. Also, The government says it will give farmers 2,000 rupees ($30) in April as an advance payment from an $80 annual pay-out to tide over the situation. But the economic fallout of this unprecedented lockdown has been dire. Businesses have closed, unemployment has risen and productivity has fallen. Many Small businesses have fired employees or are actively discussing how many people they need to fire.</p>
                <p>Aviation industry, Automobile Industries, hospitality and tourism industries are in risk. Hotels and restaurant chains across the country are empty and are likely to remain so for several months, sparking worries of large-scale layoffs.</p>
                <p>So, is India's relief package enough? Experts say it's a drop in the ocean compared to bailouts in countries such as the US, China and Singapore. India now also needs a larger stimulus package soon to help businesses weather this extraordinary crisis.</p>
                <p>Also, Economy revive need circulation of money. Without income people will not spend money, and if one not spend money will not come in system, and economy wont revive. What I’m saying is food packages are important, it is basis necessity but financial help is to all types of sector is also required.</p>
                <p>The Instance US Government Passed, The $2 trillion coronavirus rescue package will fundamentally transform the U.S. government by placing thousands of businesses and millions of workers on federally funded life support.</p>
                <p>The government will pay the wages of some workers who remain on their companies’ payrolls. It will sustain other workers who have lost their jobs with checks that are as large as — or even larger than — what they were earning before they were laid off. And it will cushion some of the country’s largest corporations from bankruptcy, with taxpayers taking shares in those companies as collateral.</p>
                <p>The government’s intervention will come in a variety of ways, including direct payments to individuals and businesses, generous loans in which the government agrees to backstop losses and equity stakes in companies. But there are strings attached, such as limits on executive pay and provisions that require companies receiving assistance to maintain employment levels at 90 percent of what they were. So, this will help the circulation of money in system. People will get regular income they will spend it too. This will help reiving economy. </p>
                <p>Let’s hope that government of India will take right steps to revive economy in future.</p>
            </div>
        </div>
    </div>
  )
}

export default Sixteenth